<template>
  <div class="visit">
    <div class="visit-title">
      <div class="title">
        <span>随访登记</span>
      </div>
    </div>
    <div class="visit-tab">
      <div class="visit-tab-item">
        <span>曲线图</span>
      </div>
      <div class="visit-tab-item">
        <span>新增</span>
      </div>
      <div class="visit-tab-item">
        <span>打印</span>
      </div>
      <div class="visit-tab-item">
        <span>导出</span>
      </div>
    </div>
    <el-table :data="tableData" style="width: 100%" max-height="450">
      <el-table-column type="selection" width="55">
      </el-table-column>
      <el-table-column prop="date" label="行号">
      </el-table-column>
      <el-table-column prop="name" label="数据来源">
      </el-table-column>
      <el-table-column prop="name" label="档案编号">
      </el-table-column>
      <el-table-column prop="name" label="姓名">
      </el-table-column>
      <el-table-column prop="name" label="血压（mmHg）">
      </el-table-column>
      <el-table-column prop="name" label="随访方式">
      </el-table-column>
      <el-table-column prop="name" label="管理级别">
      </el-table-column>
      <el-table-column prop="name" label="随访医生">
      </el-table-column>
    </el-table>
    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
      :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
    </el-pagination>
    <div class="visit-btns">
      <el-button size="mini" class="blue">保存</el-button>
      <el-button size="mini" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }]
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.visit {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 30px;
  background-color: #081C38;
  box-sizing: border-box;

  .visit-title {
    display: flex;
    align-items: center;
    height: 58px;

    .title {
      width: 130px;
      height: 38px;
      line-height: 38px;
      text-align: center;
      background-color: #01EEFD;
      color: #fff;
      font-size: 22px;
    }
  }

  .visit-tab {
    display: flex;
    align-items: center;
    height: 80px;
    border-top: 1px solid #01EEFD;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .visit-tab-item {
      width: 90px;
      height: 38px;
      line-height: 38px;
      margin-left: 30px;
      text-align: center;
      background-color: #04B4D0;
      color: #fff;
      font-size: 16px;
      cursor: pointer;

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .el-table {
    margin-top: 20px;
    background-color: transparent;
    outline: 4px #073F73 solid;
    color: #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  .operate {
    display: flex;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  /deep/.el-table th.el-table__cell {
    background-color: #073F73;
  }

  /deep/.el-table thead {
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border: none;
  }

  /deep/.el-table td.el-table__cell {
    border-color: #01EEFD;
  }

  /deep/.el-table__cell {
    text-align: center;
  }

  /deep/.el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, .4);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  /deep/.el-table__body {
    width: 100% !important;
  }

  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-table th.gutter {
    display: none;
    width: 0;
  }

  /deep/.el-table__fixed-right-patch {
    background-color: transparent;
    border: none;
  }

  /deep/.el-table__fixed-right::before,
  /deep/.el-table__fixed::before {
    background-color: transparent;
  }

  /deep/.el-table__body tr.hover-row>td.el-table__cell {
    background-color: transparent;
  }

  .el-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }

  /deep/.el-pagination__total,
  /deep/.el-pagination__jump {
    color: #01EEFD;

    .el-input__inner {
      color: #fff;
      background-color: transparent;
    }
  }

  /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #01EEFD;
  }

  .visit-btns {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 80px;

    .el-button {
      margin-left: 50px;
      background-color: #33465C;
      border: none;
      border-radius: 0;
      color: #fff;
    }

    .blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }
}
</style>
