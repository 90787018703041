<template>
  <div class="son">
    <div class="son-head">
      <div class="son-title">
        <span>档案详情</span>
      </div>
      <el-button @click="go">返回</el-button>
    </div>
    <div class="son-tab">
      <div v-for="(item, index) in tabs" :key="index" :class="{ 'son-active': index === tabIndex }" @click="tabClick(index)" class="son-item">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="son-box">
      <Tab1 v-if="tabIndex === 0"></Tab1>
      <Tab2 v-else-if="tabIndex === 1"></Tab2>
    </div>
  </div>
</template>

<script>
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
export default {
  components: {
    Tab1,
    Tab2
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['健康档案', '签约信息'])
    }
  },
  methods: {
    go () {
      this.$router.go(-1)
    },
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;

    .son-title {
      display: flex;
      align-items: center;
      font-size: 18px;

      &::before {
        display: block;
        content: '';
        width: 6px;
        height: 20px;
        margin-right: 10px;
        background-color: rgba(0, 0, 255, 1);
        border-radius: 8px;
      }
    }
  }

  .son-tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: 48px;
    background-color: #f9fbfd;

    .son-item {
      width: 128px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      color: #1877F2;
      font-size: 14px;
      cursor: pointer;
    }

    .son-item:first-child {
      margin-right: 20px;
    }

    .son-active {
      background-color: #1877F2;
      color: #fff;
    }
  }

  .son-box {
    width: 100%;
    height: calc(100% - 108px);
    margin-top: 10px;
  }
}
</style>
