<template>
  <div class="write">
    <div class="content-box-search">
      <div class="left">
        <el-select v-model="value" placeholder="权限归属">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value" placeholder="权限类型">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value" placeholder="医护姓名">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-select v-model="value" placeholder="状态">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <el-button size="small" class="blue">搜索</el-button>
        <el-button size="small" class="gray">重置</el-button>
      </div>
      <div class="right">
        <div class="add" @click="openAddDoctor">
          <span class="el-icon-plus"></span>
        </div>
      </div>
    </div>
    <el-table :data="tableData" height="350" :header-cell-style="{ background: '#024276' }">
      <el-table-column prop="name" label="用户名">
      </el-table-column>
      <el-table-column prop="date" label="姓名">
      </el-table-column>
      <el-table-column prop="date" label="组织">
      </el-table-column>
      <el-table-column prop="type" label="职业">
      </el-table-column>
      <el-table-column prop="type1" label="职称">
      </el-table-column>
      <el-table-column prop="type2" label="创建时间">
      </el-table-column>
      <el-table-column prop="type2" label="状态">
      </el-table-column>
      <el-table-column label="操作" width="140">
        <template>
          <!-- slot-scope="scope" -->
          <div class="flex">
            <span>重置密码</span>
            <span @click="openAddDoctor">编辑</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <!-- 添加医护 -->
    <AddDoctor v-if="showAddDoctor" @close="closeAddDoctor" @closePopup="closePopup"></AddDoctor>
    <DoctorRunPopup v-if="showDoctorRunPopup" @close="closeDoctorRunPopup"></DoctorRunPopup>
  </div>
</template>

<script>
import AddDoctor from './addDoctor.vue'
import DoctorRunPopup from './doctorRunPopup.vue'
export default {
  components: {
    AddDoctor,
    DoctorRunPopup
  },
  data () {
    return {
      tableData: [{
        name: '111'
      }],
      value: '',
      options: [{
        value: '',
        label: ''
      }],
      showAddDoctor: false,
      showDoctorRunPopup: false
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openAddDoctor () {
      this.showAddDoctor = true
    },
    closeAddDoctor () {
      this.showAddDoctor = false
    },
    openDoctorRunPopup () {
      this.showDoctorRunPopup = true
    },
    closeDoctorRunPopup () {
      this.showDoctorRunPopup = false
    },
    closePopup (index) {
      this.$emit('closePopup', index)
      this.closeAddDoctor()
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  position: relative;
  width: 100%;
  height: 100%;
  border-top: 1px solid #01EEFD;
  box-sizing: border-box;

  .content-box-search {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: .25rem 0;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .left {
      display: flex;
      align-items: center;

      .search-input {
        width: 3.25rem;
      }

      .el-select,
      .el-input {
        width: 2.5rem;
        margin-left: .125rem;
      }

      .date {
        margin-left: .125rem;
      }

      .blue,
      .gray {
        margin-left: .5rem;
        background-color: #04B4D0;
        color: #fff;
        border: none;
        border-radius: 0;
      }

      .gray {
        margin-right: .125rem;
        background-color: #284867;
      }
    }

    .right {
      display: flex;
      align-items: center;

      .add {
        width: .375rem;
        height: .375rem;
        line-height: .375rem;
        text-align: center;
        border-radius: 50%;
        background-color: #04B4D0;
        color: #fff;
        font-size: .225rem;
        font-weight: 600;
        cursor: pointer;
      }

      .blue {
        margin-right: .5rem;
        background-color: #04B4D0;
        color: #fff;
        border: none;
        border-radius: 0;
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0rem;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    margin-top: .125rem;
    background-color: #092B50;
    border: .05rem #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: .2125rem;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: .025rem solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: .0125rem solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: .0125rem solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: .0125rem solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  ::v-deep .el-table__empty-text {
    color: #01EEFD;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: .5rem;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: .1875rem;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }
}
</style>
