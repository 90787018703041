<template>
  <div class="add">
    <div class="add-box">
      <div class="add-head">
        <div class="add-title">
          <span>新增转诊</span>
        </div>
        <div class="add-close">
          <span class="el-icon-close"></span>
        </div>
      </div>
      <div class="add-content">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px">
          <div class="add-content-title">
            <span>患者信息</span>
          </div>
          <div class="add-content-title">
            <span>从我的病人种选择</span>
            <el-button type="text">选择患者</el-button>
          </div>
          <el-form-item label="姓名" prop="name">
            <el-input v-model="ruleForm.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="xb">
            <el-select v-model="ruleForm.xb" placeholder="请选择">
              <el-option label="男" value="1" key="1"></el-option>
              <el-option label="女" value="2" key="2"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="年龄" prop="age">
            <el-input v-model="ruleForm.age" maxlength="3" placeholder="请输入"></el-input>
          </el-form-item>
          <div class="add-content-title">
            <span>转诊信息</span>
          </div>
          <el-form-item label="年龄" prop="age">
            <el-input v-model="ruleForm.age" maxlength="3" placeholder="请输入"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="add-button">
        <el-button>取消</el-button>
        <el-button type="primary">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      ruleForm: {
        name: '',
        xb: '',
        age: ''
      },
      rules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[\u4E00-\u9FA5]{2,5}$/.test(value))) {
                callback(new Error('请输入汉字'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ],
        xb: [],
        age: [
          {
            validator: (rule, value, callback) => {
              if (!(/^((1[0-5])|[1-9])?\d$/.test(value))) {
                callback(new Error('请输入正确年龄'))
              } else {
                callback()
              }
            },
            trigger: 'blur'
          }
        ]
      }
    }
  }
}
</script>

<style lang="less" scoped>
  .add {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 88;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);

    .add-box {
      width: 60%;
      height: 70%;
      padding-left: 20px;
      padding-right: 20px;
      padding-bottom: 20px;
      background-color: #fff;
      border-color: 1px solid rgba(242, 242, 242, 1);
      border-radius: 4px;
      box-sizing: border-box;

      .add-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 59px;
        border-bottom: 1px solid #d1d1d1;

        .add-title {
          font-size: 16px;
        }

        .add-close {
          color: #d1d1d1;
          font-size: 24px;
          cursor: pointer;
        }
      }

      .add-content {
        overflow: hidden scroll;
        width: 100%;
        height: calc(100% - 130px);
        margin-top: 10px;

        &::-webkit-scrollbar {
          width: 0;
        }

        .el-form {
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          width: 100%;
          height: 100%;
        }

        .add-content-title {
          width: 100%;
          margin-top: 5px;
          margin-bottom: 5px;
          font-size: 15px;

          .el-button {
            margin-left: 20px;
          }
        }

        .el-form-item {
          width: calc(33.3% - 10px);
          margin-left: 5px;
          margin-right: 5px;
        }
      }

      .add-button {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: 60px;
        padding-right: 20px;
        box-sizing: border-box;
      }
    }
  }
</style>
