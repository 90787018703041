<template>
  <div class="detailed">
    <div class="detailed-title">
      <span>详细信息</span>
    </div>
    <el-button @click="close" class="detailed-close">返回</el-button>
    <div class="detailed-box">
      <div class="detailed-left">
        <div class="detailed-position">
          <span>I级</span>
        </div>
        <div class="detailed-info">
          <div class="detailed-info-line">
            <el-image src="https://files.axshare.com/gsc/RBUY6U/0d/db/3c/0ddb3c286c8144039541bd12133cfd43/images/%E5%B7%A5%E4%BD%9C%E5%8F%B0/u380.png"></el-image>
            <div class="detailed-news">
              <div class="detailed-news-top">
                <span class="name">张三</span>
                <span class="age">56岁</span>
                <span class="sex">男</span>
              </div>
              <div class="detailed-news-label">
                <span>高血压</span>
              </div>
            </div>
          </div>
          <div class="detailed-info-line">
            <span>身份证号：5130******0900</span>
          </div>
          <div class="detailed-info-line">
            <span>电话：13011**111</span>
          </div>
        </div>
        <div class="detailed-health">
          <div class="detailed-health-title">
            <span>健康信息</span>
          </div>
          <div class="detailed-health-line">
            <div class="detailed-health-item">
              <span class="gray">身高</span>
              <span>-cm</span>
            </div>
            <div class="detailed-health-item">
              <span class="gray">体重</span>
              <span>-kg</span>
            </div>
          </div>
          <div class="detailed-health-line">
            <div class="detailed-health-item">
              <span class="gray">血型</span>
              <span>-型</span>
            </div>
            <div class="detailed-health-item">
              <span class="gray">RH阴性</span>
              <span>否</span>
            </div>
          </div>
          <div class="detailed-health-line">
            <div class="detailed-health-item">
              <span class="gray">过敏史</span>
              <span>无</span>
            </div>
            <div class="detailed-health-item">
              <span class="gray">既往史</span>
              <span>无</span>
            </div>
          </div>
          <div class="detailed-health-line">
            <div class="detailed-health-item">
              <span class="gray">就诊史</span>
              <span>无</span>
            </div>
            <div class="detailed-health-item">
              <span class="gray">家族病史</span>
              <span>无</span>
            </div>
          </div>
        </div>
        <div class="detailed-sign">
          <div class="detailed-sign-title">
            <span>签约情况</span>
          </div>
          <div class="detailed-sign-item">
            <span class="gray">服务包</span>
            <span>慢性病护理包</span>
          </div>
          <div class="detailed-sign-item">
            <span class="gray">生效日期</span>
            <span>2024/10/09</span>
          </div>
          <div class="detailed-sign-item">
            <span class="gray">到期日期</span>
            <span>2024/10/09</span>
          </div>
        </div>
      </div>
      <div class="detailed-right">
        <div class="detailed-tab">
          <div v-for="(item, index) in tabs" :key="index" :class="{ 'detailed-tab-active': index === tabIndex }" @click="tabClick(index)" class="detailed-tab-item">
            <span>{{ item }}</span>
          </div>
        </div>
        <div class="detailed-content">
          <Tab1 v-if="tabIndex === 0"></Tab1>
          <Tab2 v-else-if="tabIndex === 1"></Tab2>
          <Tab3 v-else-if="tabIndex === 2"></Tab3>
           <!-- <router-view></router-view> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tab1 from './tab1/tab1.vue'
import Tab2 from './tab2/tab2.vue'
import Tab3 from './tab3/tab3.vue'
export default {
  components: {
    Tab1,
    Tab2,
    Tab3
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['总览', '健康监测', '管理方案', '随访管理', '健康评估', '签名信息', '分级管理', '健康宣教'])
    }
  },
  created () {
    console.log(this.$route)
    this.init()
  },
  beforeDestroy () {
    sessionStorage.removeItem('detailedInfoSonIndex')
  },
  methods: {
    init () {
      const index = sessionStorage.getItem('detailedInfoIndex')
      console.log(index)
      if (index === null) return
      this.tabIndex = parseInt(index)
    },
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
      sessionStorage.setItem('detailedInfoIndex', index)
      // this.$router.push({
      //   path: '/intelligentmonitoring/aichronicdiseaseassistantsystem/detailedinfo/tab' + (index + 1)
      // })
    },
    close () {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="less" scoped>
.detailed {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .detailed-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 18px;
    &::before {
      display: block;
      content: '';
      width: 6px;
      height: 20px;
      margin-right: 20px;
      background-color: rgba(0, 0, 255, 1);
      border-radius: 8px;
    }
  }

  .detailed-close {
    position: absolute;
    top: 10px;
    right: 20px;
  }

  .detailed-box {
    display: flex;
    width: 100%;
    height: calc(100% - 70px);

    .detailed-left {
      position: relative;
      width: 245px;
      height: 100%;
      padding-left: 10px;
      padding-right: 10px;
      border-radius: 8px;
      background-color: #fff;
      border: 1px solid rgba(242, 242, 242, 1);
      box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
      box-sizing: border-box;

      .detailed-position {
        position: absolute;
        top: 5px;
        right: 10px;
        padding: 5px 15px;
        background-color: rgba(61, 212, 167, 1);
        border-radius: 24px;
        color: #fff;
        font-size: 10px;
      }

      .detailed-info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: 180px;
        border-bottom: 1px solid #f2f7fb;
        box-sizing: border-box;

        .detailed-info-line {
          display: flex;
          align-items: center;
          font-size: 13px;

          .el-image {
            width: 84px;
            height: 84px;
            border-radius: 50%;
          }

          .detailed-news {
            display: flex;
            flex-direction: column;
            width: calc(100% - 94px);
            margin-left: 10px;

            .detailed-news-top {
              display: flex;
              align-items: center;
            }

            .detailed-news-label {
              max-width: 50px;
              text-align: center;
              margin-top: 5px;
              padding: 1px 10px;
              border-radius: 10px;
              background-color: rgba(250, 116, 107, 0.098);
              color: #FA746B;
            }

            .name {
              font-size: 18px;
            }

            .age {
              margin-left: 5px;
              margin-right: 5px;
              color: #CCCCCC;
              font-size: 14px;
            }

            .sex {
              color: #CCCCCC;
              font-size: 14px;
            }
          }
        }
      }

      .detailed-health {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: 240px;
        border-bottom: 1px solid #f2f7fb;
        box-sizing: border-box;

        .detailed-health-title {
          font-size: 17px;
        }

        .detailed-health-line {
          display: flex;
          flex-direction: row;
          align-items: center;
          width: 100%;

          .detailed-health-item {
            width: 50%;
            font-size: 13px;

            .gray {
              margin-right: 5px;
              color: #999999;
            }
          }
        }
      }

      .detailed-sign {
        display: flex;
        flex-direction: column;

        .detailed-sign-title {
          font-size: 17px;
        }

        .detailed-sign-item {
          width: 100%;
          margin-top: 20px;
          font-size: 13px;

          .gray {
            margin-right: 10px;
            color: #999999;
          }
        }
      }
    }

    .detailed-right {
      width: calc(100% - 250px);
      height: 100%;
      margin-left: 5px;

      .detailed-tab {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 10px;
        padding-right: 10px;
        border-bottom: 1px solid #f2f7fb;
        box-sizing: border-box;

        .detailed-tab-item {
          width: 80px;
          height: 47px;
          line-height: 47px;
          margin-left: 20px;
          border-bottom: 2px solid #fff;
          box-sizing: border-box;
          text-align: center;
          font-size: 14px;
          cursor: pointer;
        }

        .detailed-tab-active {
          border-color: rgba(0, 0, 255, 1);
          color: rgba(0, 0, 255, 1);
          font-weight: 600;
        }
      }

      .detailed-content {
        position: relative;
        width: 100%;
        height: calc(100% - 60px);
        margin-top: 10px;
        // background-color: red;
      }
    }
  }
}
</style>
