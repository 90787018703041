<template>
  <div class="update">
    <div class="sub-left">
      <div class="info-title">
        <div class="info-top"></div>
        <div class="info-bottom">
          <div class="info-bottom-text">
            <span>高血压管理</span>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="icon"></div>
        <div class="info-user">
          <img src="../../img/emr/user.png" class="img">
          <div class="info">
            <span>沈医生</span>
            <span>50岁</span>
          </div>
        </div>
        <div class="list-title list-title1">
          <span>基本信息</span>
        </div>
        <div class="list-cell">
          <span>专项登记</span>
        </div>
        <div class="list-title">
          <span>随访记录</span>
        </div>
        <div class="list-cell" @click="openVisit">
          <span :class="{ 'list-active': showVisit }">随访登记</span>
        </div>
        <div class="list-cell" @click="openEstimate">
          <span :class="{ 'list-active': showEstimate }">分层评估</span>
        </div>
        <div class="list-title">
          <span>暂不管理记录</span>
        </div>
        <div class="list-cell">
          <span>暂不管理记录</span>
        </div>
        <div class="list-title">
          <span>体检记录</span>
        </div>
        <div class="list-cell">
          <span>体检记录</span>
        </div>
      </div>
    </div>
    <div class="sub-right">
      <div class="sub-scroll">
        <div class="sub-scroll-title">
          <span>基本信息</span>
          <span class="size">档案编号:12388888</span>
        </div>
        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>新增报告</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>姓名</span>
              </div>
              <el-input v-model="info.name" class="input"></el-input>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>性别</span>
              </div>
              <el-select v-model="info.sex" placeholder="" class="select">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item1">
              <div class="key">
                <span>出生日期</span>
              </div>
              <el-date-picker v-model="info.csrq" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>

            <div class="sub-scroll-list-item">
              <div class="key">
                <span>身份证号</span>
              </div>
              <el-input class="input1" v-model="info.sfzh"></el-input>
            </div>
            <div class="sub-scroll-list-item">
              <div class="key">
                <span>联系电话</span>
              </div>
              <el-input class="input1" v-model="info.lxdh"></el-input>
            </div>
            <div class="sub-scroll-list-item sub-scroll-list-item1">
              <div class="key">
                <span>居住类型</span>
              </div>
              <el-radio-group v-model="info.jzlx">
                <el-radio label="0">常住</el-radio>
                <el-radio label="1">流动</el-radio>
                <el-radio label="2">流动（流入）</el-radio>
                <el-radio label="3">流动（流出）</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item1">
              <div class="key">
                <span>家庭住址</span>
              </div>
              <el-input class="input2" v-model="info.jtzz"></el-input>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item1">
              <div class="key1">
                <span>管理所在行政区划</span>
              </div>
              <el-input class="input2" v-model="info.glszxzqh"></el-input>
            </div>
          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>生活方式情况</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key1">
                <span>已采取生活方式治疗措施</span>
              </div>
              <el-radio-group v-model="info.zlcs" class="radio-width">
                <el-radio label="0">无</el-radio>
                <el-radio label="1">有</el-radio>
              </el-radio-group>
              <el-checkbox-group v-model="info.zlcs1">
                <el-checkbox label="0">减少食盐摄入</el-checkbox>
                <el-checkbox label="1">减少膳食脂肪</el-checkbox>
                <el-checkbox label="2">规律运动</el-checkbox>
                <el-checkbox label="3">减少吸烟量或戒烟</el-checkbox>
                <el-checkbox label="4">控制体重</el-checkbox>
                <el-checkbox label="5">心理调整</el-checkbox>
              </el-checkbox-group>
            </div>

            <div class="sub-scroll-list-item">
              <div class="key">
                <span>吸烟情况</span>
              </div>
              <el-radio-group v-model="info.xyqk">
                <el-radio label="0">吸烟</el-radio>
                <el-radio label="1">戒烟</el-radio>
                <el-radio label="2">不吸</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item">
              <div class="key">
                <span>饮酒情况</span>
              </div>
              <el-radio-group v-model="info.yjqk">
                <el-radio label="0">经常</el-radio>
                <el-radio label="1">偶尔</el-radio>
                <el-radio label="2">不饮</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item">
              <div class="key">
                <span>运动情况</span>
              </div>
              <el-radio-group v-model="info.ydqk">
                <el-radio label="0">规律</el-radio>
                <el-radio label="1">偶尔</el-radio>
                <el-radio label="2">不锻炼</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key1">
                <span>主要运动方式</span>
              </div>
              <el-checkbox-group v-model="info.ydfs">
                <el-checkbox label="1">散步</el-checkbox>
                <el-checkbox label="2">做操或气功</el-checkbox>
                <el-checkbox label="3">太极拳</el-checkbox>
                <el-checkbox label="4">跑步</el-checkbox>
                <el-checkbox label="5">跳舞</el-checkbox>
                <el-checkbox label="6">球类</el-checkbox>
                <el-checkbox label="7">其他</el-checkbox>
                <el-input class="input2" v-show="info.ydfs.indexOf('7') > -1" v-model="info.ydfsDes"></el-input>
              </el-checkbox-group>
            </div>

            <div class="sub-scroll-list-item">
              <div class="key1">
                <span>每周运动次数</span>
              </div>
              <el-input v-model="info.mzydcs" class="input"></el-input>
              <div class="unit">
                <span>次</span>
              </div>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item4">
              <div class="key1">
                <span>每周运动时间</span>
              </div>
              <el-input v-model="info.mzydsj" class="input"></el-input>
              <div class="unit">
                <span>分钟</span>
              </div>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key">
                <span>盐摄入量</span>
              </div>
              <el-radio-group v-model="info.ysrl">
                <el-radio label="0">轻</el-radio>
                <el-radio label="1">中</el-radio>
                <el-radio label="2">重</el-radio>
              </el-radio-group>
              <el-input v-model="info.ysrlk" class="input"></el-input>
              <div class="unit">
                <span>克/天</span>
              </div>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key">
                <span>高脂饮食</span>
              </div>
              <el-radio-group v-model="info.gzys">
                <el-radio label="0">否</el-radio>
                <el-radio label="1">是</el-radio>
                <el-radio label="2">不详</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key">
                <span>心理调整</span>
              </div>
              <el-radio-group v-model="info.xltz">
                <el-radio label="0">良好</el-radio>
                <el-radio label="1">一般</el-radio>
                <el-radio label="2">重</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item5">
              <div class="key">
                <span>遵医行为</span>
              </div>
              <el-radio-group v-model="info.zyxw">
                <el-radio label="0">良好</el-radio>
                <el-radio label="1">一般</el-radio>
                <el-radio label="2">重</el-radio>
              </el-radio-group>
            </div>

          </div>
        </div>

        <div class="sub-scroll-list">
          <div class="sub-scroll-list-title">
            <span>生活方式情况</span>
          </div>
          <div class="sub-scroll-list-box">
            <div class="sub-scroll-list-item">
              <div class="key1">
                <span>确诊时间</span>
              </div>
              <el-date-picker v-model="info.qzsj" type="date" placeholder="选择日期">
              </el-date-picker>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item4">
              <div class="key1">
                <span>确诊医疗机构</span>
              </div>
              <el-input v-model="info.qzyljg" class="input1"></el-input>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item2">
              <div class="key1">
                <span>医疗机构类型</span>
              </div>
              <el-radio-group v-model="info.yljglx">
                <el-radio label="0">省级医院</el-radio>
                <el-radio label="1">市级医院</el-radio>
                <el-radio label="2">县级医院</el-radio>
                <el-radio label="3">社区卫生服务中心/乡镇卫生院</el-radio>
                <el-radio label="4">社区卫生服务站</el-radio>
                <el-radio label="5">不详</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item1">
              <div class="key">
                <span>发现方式</span>
              </div>
              <el-radio-group v-model="info.fxfs">
                <el-radio label="0">主动就诊</el-radio>
                <el-radio label="1">机会性筛查</el-radio>
                <el-radio label="2">体检</el-radio>
                <el-radio label="3">其他</el-radio>
              </el-radio-group>
            </div>

            <div class="sub-scroll-list-item sub-scroll-list-item1">
              <div class="key">
                <span>高血压类型</span>
              </div>
              <el-radio-group v-model="info.gxylx">
                <el-radio label="0">原发性</el-radio>
                <el-radio label="1">继发性偶尔</el-radio>
                <el-radio label="2">不详</el-radio>
              </el-radio-group>
            </div>

          </div>
        </div>
      </div>
      <div class="sub-btns">
        <el-button size="mini" class="blue">保存</el-button>
        <el-button size="mini" @click="close">返回</el-button>
      </div>
      <!-- 随访登记 -->
      <Visit v-if="showVisit" @close="closeVisit"></Visit>
      <!-- 分层评估 -->
      <Estimate v-if="showEstimate" @close="closeEstimate"></Estimate>
    </div>
  </div>
</template>

<script>
import Visit from './visit.vue'
import Estimate from './estimate.vue'
export default {
  components: {
    Visit,
    Estimate
  },
  data () {
    return {
      radio: '1',
      options: [{
        value: '1',
        label: '男'
      }, {
        value: '2',
        label: '女'
      }],
      value: '',
      checkList: [''],
      showVisit: false,
      showEstimate: false,
      info: {
        name: '',
        sex: '',
        csrq: '',
        sfzh: '',
        lxdh: '',
        jzlx: '',
        jtzz: '',
        glszxzqh: '',
        zlcs: '',
        zlcs1: [],
        xyqk: '',
        yjqk: '',
        ydqk: '',
        ydfs: [],
        ydfsDes: '',
        mzydcs: '',
        mzydsj: '',
        ysrl: '',
        ysrlk: '',
        gzys: '',
        xltz: '',
        zyxw: '',
        qzsj: '',
        qzyljg: '',
        yljglx: '',
        fxfs: '',
        gxylx: ''
      }
    }
  },
  computed: {
    ydfs () {
      return this.info.ydfs
    }
  },
  watch: {
    ydfs (val) {
      if (val.indexOf('7') === -1) {
        this.info.ydfsDes = ''
      }
    }
  },
  methods: {
    openVisit () {
      if (this.showVisit) {
        this.showVisit = false
        return
      }
      this.showVisit = true
      this.showEstimate = false
    },
    closeVisit () {
      this.showVisit = false
    },
    openEstimate () {
      if (this.showEstimate) {
        this.showEstimate = false
        return
      }
      this.showEstimate = true
      this.showVisit = false
    },
    closeEstimate () {
      this.showEstimate = false
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.update {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 888;
  width: calc(100% - 120px);
  height: 100%;
  background-color: #071224;
  box-sizing: border-box;

  .sub-left {
    width: 270px;
    height: 100%;
    margin: 0 10px;
    padding: 0 10px;
    background-color: #081C38;
    box-sizing: border-box;

    .info-title {
      display: flex;
      flex-direction: column;

      .info-top {
        position: relative;
        width: 87px;
        height: 6px;
        transform: skew(30deg);
        background-color: #03BCD7;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -6px;
          width: 10px;
          height: 6px;
          background-color: #081C38;
          transform: skew(-50deg);
        }
      }

      .info-bottom {
        overflow: hidden;
        position: relative;
        width: 252px;
        height: 30px;
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        transform: skew(30deg);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -30px;
          width: 20px;
          height: 30px;
          background-color: #081C38;
          transform: skew(-55deg);
        }

        .info-bottom-text {
          height: 30px;
          line-height: 30px;
          padding-left: 20px;
          box-sizing: border-box;
          transform: skew(-30deg);
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .info-box {
      overflow: hidden;
      position: relative;
      width: 100%;
      height: 752px;
      margin-top: 8px;
      padding: 0 4px;
      padding-top: 33px;
      background-color: #1D4070;
      border-radius: 6px;
      box-sizing: border-box;

      .icon {
        position: absolute;
        top: 0;
        left: 7px;
        z-index: 88;
        width: 60px;
        height: 2px;
        background-color: #44D7B6;
        border-radius: 2px;
      }

      .info-user {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 10px;
        box-sizing: border-box;

        .img {
          width: 50px;
          height: 50px;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 10px;
          color: #01EEFD;
          font-size: 16px;
        }
      }

      .list-title {
        width: 100%;
        height: 30px;
        line-height: 30px;
        padding-left: 4px;
        background-color: rgba(1, 238, 253, .17);
        border-radius: 8px;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 16px;
        font-weight: bold;
      }

      .list-title1 {
        margin-top: 20px;
      }

      .list-cell {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 60px;
        text-align: center;
        color: #01EEFD;
        font-size: 14px;
        cursor: pointer;
      }

      .list-active {
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 6px);
        height: 28px;
        border-left: 3px solid #01EEFD;
        color: #01EEFD;
        font-size: 16px;
        font-weight: bold;
        box-sizing: border-box;
      }
    }
  }

  .sub-right {
    position: relative;
    width: calc(100% - 290px);
    height: 100%;
    padding: 10px;
    background-color: #081C38;
    box-sizing: border-box;

    .sub-scroll {
      width: 100%;
      height: calc(100% - 80px);

      .sub-scroll-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 48px;
        line-height: 48px;
        padding: 0 18px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;
        color: #01EEFD;
        font-size: 20px;
        font-weight: bold;

        .size {
          font-size: 18px;
          font-weight: 400;
        }
      }

      .sub-scroll-list {
        display: flex;
        flex-direction: column;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .sub-scroll-list-title {
          height: 45px;
          line-height: 45px;
          padding-left: 10px;
          box-sizing: border-box;
          color: #01EEFD;
          font-size: 18px;
        }

        .sub-scroll-list-box {
          display: flex;
          flex-wrap: wrap;
          padding: 0 20px;
          box-sizing: border-box;

          .sub-scroll-list-item {
            display: flex;
            align-items: center;
            width: 30%;
            margin-bottom: 18px;

            .key {
              width: 85px;
              margin-right: 20px;
              white-space: nowrap;
              text-align-last: justify;
              color: #01EEFD;
              font-size: 18px;
            }

            .key1 {
              margin-right: 10px;
              white-space: nowrap;
              color: #01EEFD;
              font-size: 18px;
            }

            .input {
              width: 100px;
            }

            .input1 {
              width: 160px;
            }

            .input2 {
              width: 300px;
            }

            .select {
              width: 100px;
            }

            .radio-width {
              width: 150px;
            }

            .unit {
              margin-left: 10px;
              color: #fff;
              font-size: 18px;
              white-space: nowrap;
            }
          }

          .sub-scroll-list-item1 {
            width: 40%;
          }

          .sub-scroll-list-item2 {
            width: 100%;
          }

          .sub-scroll-list-item3 {
            width: 33%;
          }

          .sub-scroll-list-item4 {
            width: 70%;
          }

          .sub-scroll-list-item5 {
            width: 50%;
          }
        }
      }
    }

    .sub-btns {
      display: flex;
      align-items: center;
      height: 80px;

      .el-button {
        margin-left: 50px;
        background-color: #33465C;
        border: none;
        border-radius: 0;
        color: #fff;
      }

      .blue {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio-group {
    // width: calc(100% - 80px);
  }

  /deep/.el-radio__label, /deep/.el-checkbox__label {
    font-size: 18px;
  }

  /deep/.el-radio__inner, /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .el-radio, .el-checkbox {
    margin-right: 20px;
    color: #fff;
    font-size: 18px;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label, /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #01EEFD;
    border-color: #01EEFD;
  }
}
</style>
