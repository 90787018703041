<template>
  <div class="pages">
    <div class="page-input">
      <el-input placeholder="搜索" suffix-icon="el-icon-search"></el-input>
    </div>
    <div class="content">
      <div class="content-left">
        <div class="tab">
          <span class="item" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)"
            :class="{ span: tabIndex === index }">{{ item }}</span>
        </div>
        <div class="scroll" v-if="tabIndex != 2">
          <div class="item" :class="{ item1: scrollIndex === -1 }" @click="scrollClick(-1)">
            <!-- <img class="user" src="../../img/emr/user.png" alt="头像"> -->
            <div class="user"></div>
            <div class="info">
              <span class="span">门诊概览</span>
              <span class="span">快速获取门诊通知，就诊情况分析</span>
            </div>
          </div>
          <div class="item" v-for="(item, index) in 2" :key="index" :class="{ item1: scrollIndex === index }"
            @click="scrollClick(index)">
            <img class="user" src="../../img/emr/user.png" alt="头像">
            <div class="info">
              <!-- <el-button size="mini">T-4床</el-button> -->
              <span class="span span-width" :class="{ span1: scrollIndex === index }">李明明</span>
              <span class="span span-width" :class="{ span1: scrollIndex === index }">上午:08:25</span>
              <span class="span span-width" :class="{ span2: scrollIndex === index }">44岁</span>
              <span class="span span-width" :class="{ span2: scrollIndex === index }">来科就诊3次</span>
            </div>
          </div>
        </div>
        <div class="scroll" v-else>
          <div class="item" :class="{ item1: scrollIndex === -1 }" @click="scrollClick(-1)">
            <img class="user" src="../../img/emr/user.png" alt="头像">
            <div class="info">
              <span class="span">门诊概览</span>
              <span class="span">快速获取门诊通知，就诊情况分析</span>
            </div>
          </div>
          <div class="item" v-for="(item, index) in 2" :key="index" :class="{ item1: scrollIndex === index }"
            @click="scrollClick(index)">
            <img class="user" src="../../img/emr/user.png" alt="头像">
            <div class="info">
              <el-button size="mini">T-4床</el-button>
              <span class="span" :class="{ span1: scrollIndex === index }">李明明</span>
              <span class="span" :class="{ span1: scrollIndex === index }">02-27</span>
              <span class="span" :class="{ span1: scrollIndex === index }">ll护理</span>
              <span class="span" :class="{ span2: scrollIndex === index }">44岁</span>
              <span class="span" :class="{ span2: scrollIndex === index }">李明明</span>
            </div>
          </div>
        </div>
      </div>
      <div class="content-center" v-if="scrollIndex === -1">
        <div class="head"></div>
        <div class="box">
          <el-row :gutter="10">
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <div class="title">
                  <span>您好，叶谓之医师</span>
                  <span class="span">常健无忧提醒您，工作之余，要保证身体健康奥…</span>
                </div>
                <div class="tip">
                  <span>住院安全提示</span>
                  <span class="span">定制提醒</span>
                </div>
                <div class="list">
                  <div class="cell" v-for="(item, index) in 4" :key="index">
                    <span class="icon"></span>
                    <span class="text">您所处地区为疫情[高风险]地区，为了您和身边亲朋好友的健康，请做好疫情
                      防护工作。</span>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple">
                <div class="title title1 border-none">
                  <span>住院统计分析</span>
                  <span class="blue">更多统计</span>
                </div>
                <div class="crad-srcroll">
                  <div class="card-box">
                    <div class="card-item" v-for="(item, index) in 9" :key="index">
                      <span>住院人次数</span>
                      <span>2392</span>
                      <div class="bottom">
                        <span>今日住院：34 </span>
                        <span> 较昨日：4
                          <span class="red" v-if="index === 0">↓</span>
                          <span class="green" v-else-if="index !== 0">↑</span>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <div class="title title1">
                  <span>今日待办</span>
                  <span class="blue size">
                    <i class="el-icon-edit
          "></i>
                  </span>
                </div>
                <div class="list list1">
                  <el-radio-group v-model="radio">
                    <el-radio label="3" v-for="(item, index) in 10" :key="index">
                      <div class="wrap">
                        <span class="time">消息时间</span>
                        <span>会诊邀请[09:22]</span>
                        <span class="yellow">呼吸内科(李兰玉主任)</span>
                        <span>邀请您对</span>
                        <span class="blue">(患者-张*肖)</span>
                        <span>进行会诊。</span>
                      </div>
                    </el-radio>
                  </el-radio-group>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <div class="title title1">
                  <span>住院患者预警</span>
                </div>
                <div class="crad-srcroll crad-srcroll1">
                  <div class="item-scroll" v-for="(item, index) in 10" :key="index">
                    <div>
                      <span>危急值预警</span>
                    </div>
                    <div class="item-bottom">
                      <span><span class="blue">[T-10床 李晓华 男]</span> 患者，检验项目 [血常规] 中，出现<span class="red">[白
                          细胞 ↑600]</span>，请知晓请知晓请知晓请知晓请知晓请知晓</span>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="grid-content bg-purple">
                <div class="title title1">
                  <span><span class="blue">院内公告咨询</span>/医学图书馆</span>
                </div>
                <div class="crad-srcroll crad-srcroll1">
                  <div class="cell-news" v-for="(item, index) in 10" :key="index">
                    <div class="cell-img">
                      <span>最新消息</span>
                    </div>
                    <div class="cell-info">
                      <span>关于院内疫情防控工作报告</span>
                      <div class="cell-info-bottom">
                        <span class="width">关于院内疫情防控工作报告</span>
                        <span class="see">查看详情</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 待诊 -->
      <div class="content-center" v-else-if="scrollIndex !== -1 && tabIndex === 0">
        <VisitRecord></VisitRecord>
      </div>
      <!-- 珍中 -->
      <div class="content-center" v-else-if="scrollIndex !== -1 && tabIndex === 1">
        <Info1></Info1>
      </div>
      <!-- 已诊 -->
      <div class="content-center" v-else-if="scrollIndex !== -1 && tabIndex === 2">
        <Info></Info>
      </div>
    </div>
  </div>
</template>

<script>
// 就诊记录
import VisitRecord from './visitRecord.vue'
import Info1 from './info1.vue'
import Info from './info.vue'
export default {
  components: {
    VisitRecord,
    Info1,
    Info
  },
  data () {
    return {
      tabs: Object.freeze(['待诊', '诊中', '已诊']),
      tabIndex: 0,
      scrolls: [],
      scrollIndex: 0,
      listIndex: 0,
      cellIndex: -1,
      cellChildIndex: -1,
      cellChildList: Object.freeze(['入院病历', '病程记录', '出院记录', '手术记录', '其他记录']),
      showPopover: false,
      dialogImageUrl: '',
      dialogVisible: false,
      imageUrl: '../../img/emr/emr2.png',
      showInfo: false,
      showMedicalRecord: false,
      showCourseDisease: false,
      radio: null
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    scrollClick (index) {
      this.scrollIndex = index
    },
    selectCell (index) {
      this.cellChildIndex = -1
      this.cellIndex = index
      this.showInfo = true
    },
    cellChildClick (index) {
      this.showInfo = false
      this.cellChildIndex = index
    },
    openPopover () {
      this.showPopover = !this.showPopover
    },
    openMedicalRecord () {
      this.showMedicalRecord = true
      this.showPopover = false
    },
    closeMedicalRecord () {
      this.showMedicalRecord = false
    },
    openCourseDisease () {
      this.showCourseDisease = true
    },
    closeCourseDisease () {
      this.showCourseDisease = false
    },
    handleRemove (file, fileList) {
      // console.log(file, fileList)
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    }
  }
}
</script>

<style lang="less" scoped>
.pages {
  width: 100%;
  height: 100%;
  padding: 12px;
  box-sizing: border-box;

  .page-input {

    .el-input {
      width: 246px;
    }

    ::v-deep.el-input__inner {
      // background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border-radius: 6px;
      border: 1px solid #1F72D3;
      color: #fff;
    }
  }

  .content {
    display: flex;
    width: 100%;
    height: calc(100% - 30px);
    padding-top: 15px;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 11px;
    background-color: #fff;
    box-sizing: border-box;

    .content-left {
      width: 304px;
      height: 100%;

      .tab {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 45px;
        border-bottom: 1px solid #A1A6BB;
        box-sizing: border-box;
        color: #333333;
        font-size: 16px;

        .item {
          width: 33%;
          text-align: center;
          cursor: pointer;
        }

        .span {
          color: #1269B7;
          font-size: 18px;
          font-weight: bold;
        }
      }

      .scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 46px);
        border-left: 1px solid #A1A6BB;
        box-sizing: border-box;

        &::-webkit-scrollbar {
          width: 0;
        }

        .item {
          display: flex;
          align-items: center;
          justify-content: space-around;
          width: 100%;
          height: 80px;
          margin-top: 10px;
          padding: 10px 0;
          background-color: #939393;
          // box-sizing: border-box;
          cursor: pointer;

          .user {
            width: 62px;
            height: 62px;
            margin-left: 5px;
            background-color: #FF9200;
            border-radius: 50%;
          }

          .info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            width: calc(100% - 80px);
            margin-right: 5px;

            .el-button {
              margin-left: 15px;
              background-color: #FF9200;
              border: none;
              border-radius: 17px;
              color: #fff;
              font-size: 16px;
            }

            .span {
              margin-left: 15px;
              color: #fff;
              font-size: 14px;
            }

            .span1 {
              // color: #333333;
              color: #fff;
            }

            .span2 {
              // color: #A1A6BB;
              color: #fff;
            }

            .span-width {
              width: 50%;
              height: 30px;
              line-height: 30px;
              margin-left: 0;
              text-align: center;
            }
          }
        }

        .item1 {
          background-color: #1269B7;
        }
      }
    }

    .content-center {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: calc(100% - 304px);
      height: 100%;
      // padding: 4px 45px;
      border-left: 1px solid #A1A6BB;
      box-sizing: border-box;

      .head {
        width: 100%;
        height: 45px;
        border-bottom: 1px solid #A1A6BB;
        box-sizing: border-box;
      }

      .box {
        width: calc(100% - 20px);
        height: calc(100% - 60px);
        padding: 10px;
        box-sizing: border-box;
        background-color: #E3E3E3;

        .el-row {
          height: 50%;
          margin-bottom: 10px;

          &:last-child {
            margin-bottom: 0;
          }
        }

        .el-col {
          height: 50%;
          border-radius: 8px;
        }

        .bg-purple-dark {
          background: #99a9bf;
        }

        .bg-purple {
          background: #fff;
        }

        .bg-purple-light {
          background: #e5e9f2;
        }

        .grid-content {
          display: flex;
          flex-direction: column;
          height: 350px;
          padding: 20px;
          border-radius: 8px;
          box-sizing: border-box;

          .title {
            display: flex;
            flex-direction: column;
            padding-bottom: 10px;
            border-bottom: 1px solid #A1A6BB;
            color: #333333;
            font-size: 18px;

            .span {
              color: #A1A6BB;
              font-size: 14px;
            }
          }

          .title1 {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .blue {
              color: #1269B7;
              font-size: 16px;
              cursor: pointer;
            }

            .size {
              font-size: 20px;
              cursor: pointer;
            }
          }

          .border-none {
            border: none;
          }

          .tip {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 6px 0;
            color: #333333;
            font-size: 16px;

            .span {
              color: #1269B7;
              font-size: 14px;
              cursor: pointer;
            }
          }

          .list {
            overflow-y: scroll;
            height: calc(100% - 90px);

            &::-webkit-scrollbar {
              width: 0;
            }

            .cell {
              display: flex;
              align-items: center;
              width: 100%;
              margin-bottom: 20px;
              color: #333333;
              font-size: 14px;

              .icon {
                width: 10px;
                height: 10px;
                margin-right: 8px;
                background-color: #1269B7;
              }

              .text {
                width: calc(100% - 20px);
              }
            }

            .el-radio-group {
              display: flex;
              flex-direction: column;

              .el-radio {
                display: flex;
                align-items: center;
                margin-top: 10px;
              }

              .wrap {
                white-space: pre-wrap;
                color: #333333;
                font-size: 14px;

                >span {
                  line-height: 1.2;
                }

                .time {
                  margin-right: 11px;
                  color: #A1A6BB;
                  font-size: 12px;
                }

                .yellow {
                  margin-left: 11px;
                  color: #FF9200;
                  font-size: 14px;
                }

                .blue {
                  color: #1269B7;
                  font-size: 14px;
                }
              }
            }
          }

          .list1 {
            height: calc(100% - 40px);
          }

          .crad-srcroll {
            overflow-y: scroll;
            width: 100%;
            height: calc(100% - 30px);

            &::-webkit-scrollbar {
              width: 0;
            }
          }

          .crad-srcroll1 {
            height: calc(100% - 40px);
            margin-top: 10px;

            .item-scroll {
              width: calc(100% - 24px);
              margin-bottom: 10px;
              padding: 10px;
              border: 1px solid #A1A6BB;
              border-radius: 10px;
              color: #333333;
              font-size: 12px;

              .blue {
                color: #1269B7;
              }

              .red {
                color: #FF0000;
              }

              .item-bottom {
                overflow: hidden;
                -webkit-line-clamp: 2;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }
            }

            .cell-news {
              display: flex;
              align-items: center;
              margin-bottom: 10px;

              .cell-img {
                width: 96px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: linear-gradient(-90deg, #1269B7 0%, #09355C 100%);
                border-radius: 6px;
                color: #FFFFFF;
                font-size: 16px;
              }

              .cell-info {
                display: flex;
                flex-direction: column;
                width: calc(100% - 106px);
                margin-left: 10px;
                color: #333333;
                font-size: 16px;

                .cell-info-bottom {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  width: 100%;
                  color: #A1A6BB;
                  font-size: 14px;

                  .width {
                    width: 180px;
                    white-space: pre-wrap;
                  }

                  .see {
                    white-space: nowrap;
                    color: #1269B7;
                    cursor: pointer;
                  }
                }
              }
            }
          }

          .card-box {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            height: 100%;

            .card-item {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: 240px;
              margin-bottom: 10px;
              padding: 10px;
              border: 1px solid #A1A6BB;
              border-radius: 10px;
              color: #333;
              font-size: 12px;

              .bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #A1A6BB;

                .red {
                  color: #FF0000;
                }

                .green {
                  color: #08B536;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
