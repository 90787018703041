var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report"},[_c('div',{staticClass:"report-close",on:{"click":_vm.close}},[_c('span',{staticClass:"icon el-icon-close"})]),_vm._m(0),_c('div',{staticClass:"report-bottom"},[_c('div',{staticClass:"report-left"},[_vm._m(1),_c('div',{staticClass:"list"},[_c('div',{staticClass:"head"},[_vm._m(2),_vm._m(3),_vm._m(4),_c('el-select',{attrs:{"placeholder":"请选择","size":"small"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_vm._m(5)])]),_vm._m(6)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-top"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v("收住院")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"info"},[_c('div',{staticClass:"info-left"},[_c('img',{staticClass:"info-img",attrs:{"src":require("../../img/emr/user.png")}})]),_c('div',{staticClass:"info-right"},[_c('span',[_vm._v("王丽霞")]),_c('span',{staticClass:"sex"},[_vm._v("女")]),_c('span',[_vm._v("44岁3月")]),_c('span',[_vm._v("202111606032")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select active"},[_c('span',[_vm._v("今日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select"},[_c('span',[_vm._v("近7日")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"select"},[_c('span',[_vm._v("近3月")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"scroll"},[_c('div',{staticClass:"item"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"round round-active"}),_c('div',{staticClass:"card card-active"},[_c('div',{staticClass:"card-top"},[_c('span',[_vm._v("新型冠状病毒核酸检测")])]),_c('div',{staticClass:"card-bottom"},[_c('span',[_vm._v("2021-05-22 08:55 ")]),_c('span',{staticClass:"status"},[_vm._v("未出报告")])])])]),_c('div',{staticClass:"item"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"round"}),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-top"},[_c('span',[_vm._v("新型冠状病毒核酸检测")])]),_c('div',{staticClass:"card-bottom"},[_c('span',[_vm._v("2021-05-22 08:55 ")]),_c('span',{staticClass:"status"},[_vm._v("未出报告")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report-right"},[_c('div',{staticClass:"news"},[_c('span',[_vm._v("检验项目：新型冠状病毒核酸检测")]),_c('span',[_vm._v("申请科室：五官科门诊")]),_c('span',[_vm._v("医生：叶谓之")]),_c('span',[_vm._v("送检时间：2021-05-22 08:55")])]),_c('div',{staticClass:"news"},[_c('span',[_vm._v("样本号：15")]),_c('span',[_vm._v("样本类型：鼻咽拭子")]),_c('span',[_vm._v("检验：宋叶月")]),_c('span',[_vm._v("审核：王爱护")]),_c('span',[_vm._v("报告时间：2021-05-22 08:55")])]),_c('div',{staticClass:"table"},[_c('div',{staticClass:"table-head"},[_c('span',{staticClass:"table-flex table-flex2"},[_vm._v("项目名称")]),_c('span',{staticClass:"table-flex"},[_vm._v("英文标识")]),_c('span',{staticClass:"table-flex"},[_vm._v("结果")]),_c('span',{staticClass:"table-flex"},[_vm._v("报警")]),_c('span',{staticClass:"table-flex"},[_vm._v("参考范围")]),_c('span',{staticClass:"table-flex"},[_vm._v("单位")])]),_c('div',{staticClass:"table-scroll"},[_c('div',{staticClass:"table-tr"},[_c('span',{staticClass:"table-flex table-flex2"},[_vm._v("新型冠状病毒2019-nCoV核算检测")]),_c('span',{staticClass:"table-flex"},[_vm._v("2019-nCo")]),_c('span',{staticClass:"table-flex"},[_vm._v("阴性(-)")]),_c('span',{staticClass:"table-flex"}),_c('span',{staticClass:"table-flex"},[_vm._v("阴性(-)")]),_c('span',{staticClass:"table-flex"})])])])])
}]

export { render, staticRenderFns }