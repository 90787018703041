<template>
  <div class="pupop">
    <div class="pupop-title">
      <span>诊疗录音速记</span>
    </div>
    <div class="pupop-close" @click="close">
      <span class="el-icon-close"></span>
    </div>
    <div class="pupop-scroll">
      <div class="list">
        <span>请保持科室效果相对安静，否则录音效果会降低</span>
      </div>
      <div class="list">
        <div class="icon"></div>
      </div>
      <div class="list">
        <span class="blue">[医生]</span>
        <span>你怎么不舒服？</span>
      </div>
      <div class="list">
        <span class="red">[患者]</span>
        <span>最近天气太热，可能是中暑了！</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.pupop {
  position: absolute;
  top: 24px;
  right: 110px;
  width: 294px;
  height: 504px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: -3px 2px 6px 1px rgba(0, 0, 0, 0.1608);
  border-radius: 6px;
  box-sizing: border-box;

  .pupop-title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-bottom: 7px;
    padding-left: 4px;
    border-bottom: 1px solid #A1A6BB;
    box-sizing: border-box;
    color: #666666;
    font-size: 16px;
    box-sizing: border-box;
  }

  .pupop-close {
    position: absolute;
    top: 14px;
    right: 14px;
    color: #707070;
    font-size: 20px;
    cursor: pointer;
  }

  .pupop-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 80px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .list {
      width: 100%;
      margin-bottom: 10px;
      color: #999999;
      font-size: 12px;

      .icon {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #1269B7;
      }

      .blue {
        margin-right: 8px;
        color: #1269B7;
      }

      .red {
        margin-right: 8px;
        color: #FF0000;
      }
    }
  }
}
</style>
