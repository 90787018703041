<template>
  <div class="sun">
    <div class="sun-box">
      <div class="sun-content">
        <div class="sun-title">
          <span>基本信息</span>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>居民编号</span>
          </div>
          <div class="sun-value">
            <span>20240908900</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>姓名</span>
          </div>
          <div class="sun-value">
            <span>张三</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>头像</span>
          </div>
          <div class="sun-value">
            <el-image src="https://files.axshare.com/gsc/RBUY6U/0d/db/3c/0ddb3c286c8144039541bd12133cfd43/images/%E6%A1%A3%E6%A1%88%E8%AF%A6%E6%83%85/u963.png"></el-image>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>身份证号</span>
          </div>
          <div class="sun-value">
            <span>1304561989***90543</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>手机号码</span>
          </div>
          <div class="sun-value">
            <span>13064308***</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>现居地</span>
          </div>
          <div class="sun-value">
            <span>朝阳区XXX路XX号12楼3单元392</span>
          </div>
        </div>
        <div class="sun-item"></div>
        <div class="sun-item">
          <div class="sun-key">
            <span>行政区划</span>
          </div>
          <div class="sun-value">
            <span>东风乡</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>居民标签</span>
          </div>
          <div class="sun-value">
            <el-tag type="danger">高血压</el-tag>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>性别</span>
          </div>
          <div class="sun-value">
            <span>女</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>出生日期</span>
          </div>
          <div class="sun-value">
            <span>1970-09-08</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>籍贯</span>
          </div>
          <div class="sun-value">
            <span>北京</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>民族</span>
          </div>
          <div class="sun-value">
            <span>汉族</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>文化程度</span>
          </div>
          <div class="sun-value">
            <span>本科</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>婚姻状况</span>
          </div>
          <div class="sun-value">
            <span>已婚</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>职业</span>
          </div>
          <div class="sun-value">
            <span>公务员</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>工作单位</span>
          </div>
          <div class="sun-value">
            <span>无</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>紧急联系人</span>
          </div>
          <div class="sun-value">
            <span>无</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>联系人电话</span>
          </div>
          <div class="sun-value">
            <span>无</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>户籍类型</span>
          </div>
          <div class="sun-value">
            <span>城镇</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>费用类型</span>
          </div>
          <div class="sun-value">
            <span>自费</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>定点医疗单位</span>
          </div>
          <div class="sun-value">
            <span>六里屯社区医院</span>
          </div>
        </div>
        <div class="sun-title">
          <span>健康信息</span>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>身高</span>
          </div>
          <div class="sun-value">
            <span>169cm</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>体重</span>
          </div>
          <div class="sun-value">
            <span>49kg</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>血型</span>
          </div>
          <div class="sun-value">
            <span>A型</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>否</span>
          </div>
          <div class="sun-value">
            <span>A型</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>过敏史</span>
          </div>
          <div class="sun-value">
            <span>无</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>既往史</span>
          </div>
          <div class="sun-value">
            <span>无</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>就诊史</span>
          </div>
          <div class="sun-value">
            <span>无</span>
          </div>
        </div>
        <div class="sun-item">
          <div class="sun-key">
            <span>家族病史</span>
          </div>
          <div class="sun-value">
            <span>无</span>
          </div>
        </div>
      </div>
    </div>
    <div class="sun-bottom">
      <el-button type="primary" @click="update">编辑档案信息</el-button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    update () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/addresidentfiles'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .sun {
    width: 100%;
    height: 100%;

    .sun-box {
      overflow: hidden scroll;
      display: flex;
      width: 100%;
      height: calc(100% - 50px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .sun-content {
        display: flex;
        flex-wrap: wrap;
        align-content: start;
        width: 100%;

        .sun-title {
          width: 100%;
          margin-top: 20px;
          font-size: 16px;
        }

        .sun-item {
          display: flex;
          align-items: center;
          width: calc(25% - 10px);
          margin-top: 20px;

          .sun-key {
            width: 100px;
            text-align: right;
            color: #999999;
            font-size: 14px;
          }

          .sun-value {
            width: calc(100% - 120px);
            margin-left: 10px;
            margin-right: 10px;
            word-break: break-all;
          }
        }
      }
    }

    .sun-bottom {
      width: 100%;
      height: 50px;
    }
  }
</style>
