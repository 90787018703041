<template>
  <div class="son">
    <div class="son-head">
      <div class="son-title">
        <span>签约详情</span>
      </div>
      <el-button @click="go">返回</el-button>
    </div>
    <div class="son-box">
      <div class="son-label">
        <span>基本信息</span>
      </div>
      <div class="son-content">
        <div class="son-item">
          <div class="son-key">
            <span>姓名</span>
          </div>
          <div class="son-value">
            <span>张三</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>身份证号</span>
          </div>
          <div class="son-value">
            <span>130456198908090543</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>性别</span>
          </div>
          <div class="son-value">
            <span>女</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>年龄</span>
          </div>
          <div class="son-value">
            <span>26</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>联系电话</span>
          </div>
          <div class="son-value">
            <span>13064308600</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>现居地</span>
          </div>
          <div class="son-value">
            <span>朝阳区XXX路XXX号</span>
          </div>
        </div>
        <div class="son-item"></div>
        <div class="son-item">
          <div class="son-key">
            <span>居民标签</span>
          </div>
          <div class="son-value">
            <el-tag type="danger">高血压</el-tag>
            <el-tag type="success">冠心病</el-tag>
          </div>
        </div>
      </div>
      <div class="son-label">
        <span>签约信息</span>
      </div>
      <div class="son-content">
        <div class="son-item">
          <div class="son-key">
            <span>签约编号</span>
          </div>
          <div class="son-value">
            <span>20240009091</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>签约状态</span>
          </div>
          <div class="son-value">
            <span>已过期</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>签约机构</span>
          </div>
          <div class="son-value">
            <span>六里屯区卫生服务站</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>签约团队</span>
          </div>
          <div class="son-value">
            <span>李医生团队</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>签约医生</span>
          </div>
          <div class="son-value">
            <span>李医生</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>签约周期</span>
          </div>
          <div class="son-value">
            <span>1年</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>服务包</span>
          </div>
          <div class="son-value">
            <span>慢性病护理包</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>签约类型</span>
          </div>
          <div class="son-value">
            <span>首次签约</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>申请时间</span>
          </div>
          <div class="son-value">
            <span>2024/09/09 10:09:09</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>生效日期</span>
          </div>
          <div class="son-value">
            <span>2024/09/09</span>
          </div>
        </div>
        <div class="son-item">
          <div class="son-key">
            <span>签约备注</span>
          </div>
          <div class="son-value">
            <span>无</span>
          </div>
        </div>
      </div>
      <div class="son-label">
        <span>绑定设备</span>
      </div>
      <el-table :data="tableData" border stripe style="width: 100%">
        <el-table-column prop="name" label="设备名称"></el-table-column>
        <el-table-column prop="date" label="设备SN号"></el-table-column>
        <el-table-column prop="date" label="绑定时间"></el-table-column>
        <el-table-column prop="date" label="状态"></el-table-column>
        <el-table-column label="操作">
          <el-button type="text">解绑</el-button>
        </el-table-column>
      </el-table>
      <el-button type="primary" @click="add">续约</el-button>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      tableData: [
        {},
        {}
      ]
    }
  },
  methods: {
    go () {
      this.$router.go(-1)
    },
    add () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/addsign'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f4f8fb;
    box-sizing: border-box;

    .son-title {
      display: flex;
      align-items: center;
      font-size: 18px;

      &::before {
        display: block;
        content: '';
        width: 6px;
        height: 20px;
        margin-right: 10px;
        background-color: rgba(0, 0, 255, 1);
        border-radius: 8px;
      }
    }
  }

  .son-box {
    overflow: hidden scroll;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-label {
      width: 100%;
      height: 50px;
      line-height: 50px;
      font-size: 16px;
    }

    .son-content {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      padding-bottom: 20px;
      border-bottom: 1px solid #f4f8fb;
      box-sizing: border-box;

      .son-item {
        display: flex;
        align-items: center;
        width: calc(25% - 10px);
        margin-top: 20px;

        .son-key {
          width: 100px;
          text-align: right;
          color: #999999;
          font-size: 14px;
        }

        .son-value {
          width: calc(100% - 120px);
          margin-left: 10px;
          margin-right: 10px;
          word-break: break-all;

          .el-tag {
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }
  }
}
</style>
