<template>
  <div class="sub-page">
    <div class="sub-left">
      <div class="info-title">
        <div class="info-top"></div>
        <div class="info-bottom">
          <div class="info-bottom-text">
            <span>医生信息</span>
          </div>
        </div>
      </div>
      <div class="info-box">
        <div class="icon"></div>
        <div class="info-user">
          <img src="../../img/emr/user.png" class="img">
          <div class="info">
            <span>测试</span>
            <span>-岁</span>
          </div>
        </div>
        <div class="info-date">
          <div class="info-date-top">
            <div class="before"></div>
            <div class="date">
              <span>-月</span>
            </div>
          </div>
          <div class="info-date-bottom">
            <div class="before"></div>
            <div class="date">
              <span>-日</span>
            </div>
          </div>
        </div>
        <div class="info-news">
          <div class="info-line">
            <span>信息:-条处理</span>
          </div>
          <div class="info-line">
            <span>提醒:-条未处理</span>
          </div>
          <div class="info-line">
            <span>公告：暂无公告</span>
          </div>
        </div>
      </div>
    </div>
    <div class="sub-right">
      <div class="sub-scroll">
        <div class="sub-table">
          <div class="sub-table-item">
            <div class="sub-table-title">
              <span>我的随访</span>
              <span>-</span>
            </div>
            <div class="sub-table-tab">
              <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex1 === 0 }" @click="tabClick1(0)">
                <span>近期随访</span>
              </div>
              <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex1 === 1 }" @click="tabClick1(1)">
                <span>过期随访</span>
              </div>
            </div>
            <el-table :data="showList1" height="65%">
              <el-table-column prop="yyrq" label="预约日期">
              </el-table-column>
              <el-table-column prop="name" label="姓名">
              </el-table-column>
              <el-table-column prop="phone" label="电话">
              </el-table-column>
              <el-table-column prop="ywtxlx" label="业务提醒类型">
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange1" @current-change="handleCurrentChange1"
              :current-page="query1.pageNum" background :page-size="query1.pageSize"
              layout="total, prev, pager, next, jumper" :total="query1.total">
            </el-pagination>
          </div>

          <div class="sub-table-item">
            <div class="sub-table-title">
              <span>筛查</span>
              <span>-</span>
            </div>
            <div class="sub-table-tab">
              <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex2 === 0 }" @click="tabClick2(0)">
                <span>纳入管理</span>
              </div>
              <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex2 === 1 }" @click="tabClick2(1)">
                <span>辖区管理筛查</span>
              </div>
              <div class="more">
                <span>更多</span>
              </div>
            </div>
            <el-table :data="showList2" height="65%">
              <el-table-column prop="name" label="姓名">
              </el-table-column>
              <el-table-column prop="xb" label="性别">
              </el-table-column>
              <el-table-column prop="age" label="年龄">
              </el-table-column>
              <el-table-column prop="phone" label="电话">
              </el-table-column>
              <el-table-column prop="gllx" label="管理类型">
              </el-table-column>
              <el-table-column label="操作">
                <template>
                  <div class="visit">
                    <span>随访</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination @size-change="handleSizeChange2" @current-change="handleCurrentChange2" :current-page="query2.pageNum"
              background :page-sizes="[100, 200, 300, 400]" :page-size="query2.pageSize" layout="total, prev, pager, next, jumper"
              :total="query2.total">
            </el-pagination>
          </div>

          <div class="sub-table-item">
            <div class="sub-table-title">
              <span>高血压概况</span>
              <span>-</span>
            </div>
            <div class="sub-table-tab">
              <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex3 === 0 }" @click="tabClick3(0)">
                <span>管理中</span>
              </div>
              <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex3 === 1 }" @click="tabClick3(1)">
                <span>区域总览</span>
              </div>
            </div>
            <div class="table">
              <div class="table-tr">
                <div class="table-td">
                  <span class="key">高血压累计专项建档数：</span>
                  <span>{{ obj2.gxyljzxjds || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">暂不管理数：</span>
                  <span>{{ obj2.zbgls || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">本月随访预约人：</span>
                  <span>{{ obj2.bysfyyr || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">本月实际随访：</span>
                  <span>{{ obj2.bysjsf || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">低危人数：</span>
                  <span>{{ obj2.dwrs || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">中危人数：</span>
                  <span>{{ obj2.zwrs || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">高危人数：</span>
                  <span>{{ obj2.gwrs || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">极高危人数：</span>
                  <span>{{ obj2.jgwrs || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">危险分层不详人数：</span>
                  <span>{{ obj2.yjgxyrs || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">一级高血压人数：</span>
                  <span>{{ obj2.yjgxyrs || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">二级高血压人数：</span>
                  <span>{{ obj2.ejgxyrs || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">三级高血压人数：</span>
                  <span>{{ obj2.sjgxyrs || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">血压分级不详人数：</span>
                  <span>{{ obj2.xyfjbxrs || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">一级管理人数：</span>
                  <span>{{ obj2.yjglrs || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">二级管理人数：</span>
                  <span>{{ obj2.ejglrs || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">三级管理人数：</span>
                  <span>{{ obj2.sjglrs || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">分级管理未评估人数：</span>
                  <span>{{ obj2.fjglwpgrs || '-' }}人</span>
                </div>
              </div>

            </div>
          </div>

          <div class="sub-table-item">
            <div class="sub-table-title">
              <span>糖尿病概况</span>
              <span>-</span>
            </div>
            <div class="sub-table-tab">
              <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex4 === 0 }" @click="tabClick4(0)">
                <span>管理中</span>
              </div>
              <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex4 === 1 }" @click="tabClick4(1)">
                <span>区域总览</span>
              </div>
            </div>
            <div class="table">
              <div class="table-tr">
                <div class="table-td">
                  <span class="key">糖尿病累计专项建档数：</span>
                  <span>{{ obj3.tnbljzxjds || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">暂不管理数：</span>
                  <span>{{ obj3.zbgls || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">本月随访预约人：</span>
                  <span>{{ obj3.bysfyyr || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">本月实际随访：</span>
                  <span>{{ obj3.bysjsf || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">强化管理人数：</span>
                  <span>{{ obj3.qhglrs || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key">常规管理人数：</span>
                  <span>{{ obj3.cgglrs || '-' }}人</span>
                </div>
              </div>

              <div class="table-tr">
                <div class="table-td">
                  <span class="key">分级管理未评估人数：</span>
                  <span>{{ obj3.fjglwpgrs || '-' }}人</span>
                </div>
                <div class="table-td">
                  <span class="key"></span>
                  <span></span>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      <div class="sub-btns">
        <el-button size="mini" class="blue">保存</el-button>
        <el-button size="mini">返回</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      tableData: [{
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '2',
        name: '2',
        address: '2'
      }, {
        date: '3',
        name: '3',
        address: '3'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '4',
        address: '1'
      }, {
        date: '1',
        name: '5',
        address: '1'
      }, {
        date: '1',
        name: '6',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }],
      tabIndex1: 0,
      list1: [],
      showList1: [],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      obj1: {},
      tabIndex2: 0,
      type2: '01',
      list2: [],
      showList2: [],
      // 分页数据
      query2: {
        pageNum: 1,
        pageSize: 3,
        total: 0
      },
      tabIndex3: 0,
      obj2: {},
      type3: '01',
      tabIndex4: 0,
      obj3: {},
      type4: '01'
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getList1()
    this.getList2()
    this.getObj2()
    this.getObj3()
  },
  methods: {
    tabClick1 (index) {
      if (index === this.tabIndex1) return
      this.tabIndex1 = index
      this.getList1()
    },
    // 我的随访
    async getList1 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97505631
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start, // 分页起始
          length: '10', // 分页长度
          ssjgbh: this.loginInfo.jgbh, // 所属机构编号
          xzjdmcid: '', // 乡镇社区id
          cwhmcid: '', // 村街道id
          fwrq: '' // 服务日期
        }
        const { data: res } = await this.$http.get('/ykdjqsfs', { params: obj })
        console.log('我的随访', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list1 = res.data
        // this.list1 = this.tableData
        this.query1.total = this.list1.length
        this.getShowList1()
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 显示
    getShowList1 () {
      this.showList1 = []
      this.showList1 = this.list1.slice((this.query1.pageNum - 1) * this.query1.pageSize, this.query1.pageNum * this.query1.pageSize)
    },
    // 我的随访根据参数获取单条记录
    async getObj1 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-87374439
      try {
        const obj = {
          ssjgbh: this.loginInfo.jgbh,
          sfid: '',
          sfzh: '',
          type: '',
          fwrq: ''
        }
        const { data: res } = await this.$http.get('/zhyyapi/ykdjqsfcha/find', { params: obj })
        console.log('我的随访根据参数获取单条记录', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.obj1 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick2 (index) {
      if (index === this.tabIndex2) return
      this.tabIndex2 = index
      const arr = ['01', '02']
      this.type2 = arr[index]
      this.getList2()
    },
    // 筛查
    async getList2 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97558754
      try {
        const start = this.query1.pageNum === 1 ? '0' : (this.query1.pageNum - 1) * 10 + ''
        const obj = {
          start: start, // 分页起始
          length: '10', // 分页长度
          ssjgbh: this.loginInfo.jgbh, // 所属机构编号
          xzjdmcid: '', // 乡镇社区id
          cwhmcid: '', // 村街道id
          fwrq: '', // 服务日期
          type: this.type2 // 01 纳入管理 02 档案筛查
        }
        const { data: res } = await this.$http.get('/ykdnrgls', { params: obj })
        console.log('筛查', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list2 = res.data
        // this.list2 = this.tableData
        this.query2.total = this.list2.length
        this.getShowList2()
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 显示
    getShowList2 () {
      this.showList2 = []
      this.showList2 = this.list2.slice((this.query2.pageNum - 1) * this.query2.pageSize, this.query2.pageNum * this.query2.pageSize)
    },
    handleSizeChange1 (val) {
      // console.log(`每页 ${val} 条`)
      this.query1.pageSize = val
    },
    handleCurrentChange1 (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getShowList1()
    },
    handleSizeChange2 (val) {
      // console.log(`每页 ${val} 条`)
      this.query2.pageSize = val
    },
    handleCurrentChange2 (val) {
      // console.log(`当前页: ${val}`)
      this.query2.pageNum = val
      this.getShowList2()
    },
    tabClick3 (index) {
      if (index === this.tabIndex3) return
      this.tabIndex3 = index
      const arr = ['01', '02']
      this.type3 = arr[index]
      this.getObj2()
    },
    // 高血压概括
    async getObj2 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-87387980
      try {
        const obj = {
          ssjgbh: this.loginInfo.jgbh, // 所属机构编号
          xzjdmcid: '', // 乡镇社区id
          cwhmcid: '', // 村街道id
          type: this.type3 // 01 概况02 区域总览
        }
        const { data: res } = await this.$http.post('/zhyyapi/ykdnrglcha/find', obj)
        console.log('高血压概括', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.obj2 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    tabClick4 (index) {
      if (index === this.tabIndex4) return
      this.tabIndex4 = index
      const arr = ['01', '02']
      this.type4 = arr[index]
      this.getObj3()
    },
    // 糖尿病概括
    async getObj3 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-90185727
      try {
        const obj = {
          ssjgbh: this.loginInfo.jgbh, // 所属机构编号
          xzjdmcid: '', // 乡镇社区id
          cwhmcid: '', // 村街道id
          type: this.type4 // 01 概况02 区域总览
        }
        const { data: res } = await this.$http.post('/zhyyapi/ykdtnbgkcha/find', obj)
        console.log('糖尿病概括', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.obj3 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.sub-page {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #071224;

  .sub-left {
    width: 200px;
    height: 100%;
    padding: 0 10px;
    background-color: #081C38;
    box-sizing: border-box;

    .info-title {
      display: flex;
      flex-direction: column;

      .info-top {
        position: relative;
        width: 87px;
        height: 6px;
        transform: skew(30deg);
        background-color: #03BCD7;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -6px;
          width: 10px;
          height: 6px;
          background-color: #081C38;
          transform: skew(-50deg);
        }
      }

      .info-bottom {
        overflow: hidden;
        position: relative;
        width: 150px;
        height: 30px;
        background: linear-gradient(97deg, #0177CD 0%, #024073 100%);
        transform: skew(30deg);

        &::after {
          content: '';
          position: absolute;
          top: 0;
          left: -30px;
          width: 20px;
          height: 30px;
          background-color: #081C38;
          transform: skew(-55deg);
        }

        .info-bottom-text {
          height: 30px;
          line-height: 30px;
          padding-left: 20px;
          box-sizing: border-box;
          transform: skew(-30deg);
          color: #fff;
          font-size: 20px;
          font-weight: bold;
        }
      }
    }

    .info-box {
      overflow: hidden;
      position: relative;
      width: 180px;
      height: 752px;
      margin-top: 8px;
      padding-top: 33px;
      background-color: #1D4070;
      border-radius: 6px;
      box-sizing: border-box;

      .icon {
        position: absolute;
        top: 0;
        left: 7px;
        z-index: 88;
        width: 60px;
        height: 2px;
        background-color: #44D7B6;
        border-radius: 2px;
      }

      .info-user {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 10px;
        box-sizing: border-box;

        .img {
          width: 50px;
          height: 50px;
        }

        .info {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          margin-left: 10px;
          color: #01EEFD;
          font-size: 16px;
        }
      }

      .info-date {
        width: 150px;
        height: 108px;
        margin-top: 30px;
        margin-left: 20px;

        .info-date-top {
          position: relative;
          width: 100%;
          height: 54px;
          background-color: #036BA2;
          transform: skew(5deg);

          .before {
            position: absolute;
            top: 0;
            right: -10px;
            z-index: 888;
            width: 20px;
            height: 54px;
            background-color: #1D4070;
            transform: skew(-10deg);
          }

          .date {
            width: 100%;
            height: 54px;
            line-height: 54px;
            text-align: center;
            color: #fff;
            font-size: 18px;
            transform: skew(-5deg);
          }
        }

        .info-date-bottom {
          width: 100%;
          height: 54px;
          background-color: #02467C;
          transform: skew(-5deg);

          .before {
            position: absolute;
            top: 0;
            right: -10px;
            z-index: 888;
            width: 20px;
            height: 54px;
            background-color: #1D4070;
            transform: skew(10deg);
          }

          .date {
            width: 100%;
            height: 54px;
            line-height: 54px;
            text-align: center;
            color: #fff;
            font-size: 18px;
            transform: skew(5deg);
          }
        }
      }

      .info-news {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        width: 151px;
        height: 128px;
        margin: 30px auto 0;
        padding-left: 3px;
        background-color: rgba(1, 238, 253, .17);
        border-radius: 8px;
        box-sizing: border-box;

        .info-line {
          color: #01EEFD;
          font-size: 14px;
        }
      }
    }
  }

  .sub-right {
    width: calc(100% - 190px);
    height: 100%;
    margin-left: 10px;
    padding: 10px;
    background-color: #081C38;
    box-sizing: border-box;

    .sub-scroll {
      overflow-y: scroll;
      width: 100%;
      height: calc(100% - 50px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .sub-table {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .sub-table-item {
          width: 50%;
          height: 684px;
          padding: 16px 20px;
          padding-right: 30px;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;

          &:first-child {
            border-right: 1px solid #01EEFD;
          }

          &:nth-child(3) {
            border-bottom: none;
            border-right: 1px solid #01EEFD;
          }

          &:nth-child(4) {
            border-bottom: none;
          }

          .sub-table-title {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 38px;
            line-height: 38px;
            padding: 0 20px;
            background-color: #01EEFD;
            box-sizing: border-box;
            color: #fff;
            font-size: 22px;
          }

          .sub-table-tab {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 80px;
            margin-top: 10px;
            border-top: 1px solid #01EEFD;
            border-bottom: 1px solid #01EEFD;

            .sub-tab-item {
              height: 40px;
              line-height: 40px;
              padding: 0 12px;
              background-color: #334253;
              color: #fff;
              font-size: 14px;
              cursor: pointer;

              &:first-child {
                margin-right: 20px;
              }
            }

            .sub-tab-active {
              background: linear-gradient(102deg, #0172C6 0%, #02467C 100%);
            }

            .more {
              position: absolute;
              top: 50%;
              right: 73px;
              transform: translateY(-50%);
              height: 30px;
              padding: 0 6px;
              border-bottom: 1px solid #01EEFD;
              color: #01EEFD;
              font-size: 17px;
            }
          }

          .el-table {
            margin-top: 20px;
            background-color: transparent;
            border: 4px #073F73 solid;
            color: #01EEFD;
          }

          .el-table::before {
            height: 0;
          }

          /deep/.el-table th.el-table__cell {
            background-color: #073F73;
          }

          /deep/.el-table thead {
            color: #01EEFD;
          }

          /deep/.el-table tr {
            background-color: #081C38;
          }

          /deep/.el-table th.el-table__cell.is-leaf {
            border: none;
          }

          /deep/.el-table td.el-table__cell {
            border-color: #01EEFD;
          }

          /deep/.el-table__cell {
            text-align: center;
          }

          /deep/.el-table__body-wrapper {
            &::-webkit-scrollbar {
              width: 0;
            }
          }

          /deep/.el-table__body {
            width: 100% !important;
          }

          /deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
            background-color: transparent;
          }

          /deep/.el-table colgroup col[name='gutter'] {
            display: none;
            width: 0;
          }

          /deep/ .el-table th.gutter {
            display: none;
            width: 0;
          }

          .el-pagination {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 20px;
          }

          /deep/.el-pagination__total,
          /deep/.el-pagination__jump {
            color: #01EEFD;

            .el-input__inner {
              color: #fff;
              background-color: transparent;
            }
          }

          /deep/.el-pagination.is-background .el-pager li:not(.disabled).active {
            background-color: #01EEFD;
          }

          .visit {
            width: 50px;
            margin: 0 auto;
            border-bottom: 1px solid #01EEFD;
            cursor: pointer;
          }

          .table {
            width: 100%;
            height: 450px;
            margin-top: 20px;
            border: 4px solid #073F73;
            box-sizing: border-box;

            .table-tr {
              display: flex;
              width: 100%;
              height: 45px;
              padding: 0 20px;
              border-bottom: 1px solid #01EEFD;
              box-sizing: border-box;

              .table-td {
                display: flex;
                align-items: center;
                width: 50%;
                height: 45px;
                color: #01EEFD;
                font-size: 16px;

                .key {
                  width: 210px;
                  white-space: nowrap;
                }
              }
            }
          }
        }
      }
    }

    .sub-btns {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      padding-left: 79px;
      box-sizing: border-box;

      .el-button {
        background-color: #33465C;
        color: #fff;
        border: none;
        border-radius: 0;
      }

      .blue {
        margin-right: 50px;
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }

}
</style>
