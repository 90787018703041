<template>
  <div class="children">
    <div class="children-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="children-right">
      <div class="children-title">
        <span>肺结核患者随访服务记录表</span>
      </div>
      <div class="code">编号：</div>
      <!-- <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input> -->
      <div class="children-tab">
        <span class="children-item children-active">肺结核患者随访服务记录表</span>
      </div>
      <div class="children-info">
        <div class="item">
          <span>姓名：程霞</span>
          <span class="el-icon-circle-plus white white1" @click="add"></span>
          <span class="el-icon-edit white white1"></span>
          <span class="el-icon-delete-solid white white1" @click="reduce"></span>
        </div>
        <div class="item">
          <span class="el-icon-caret-left white blue" @click="left"></span>
          <span class="el-icon-caret-right white" @click="right"></span>
        </div>
        <el-date-picker size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <div class="item">
          <span class="key">是否完整</span>
          <el-select size="small" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="key">二次随访</span>
          <el-select size="small" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="children-scroll">
        <div class="children-table border-bottom-none">
          <div class="table-left table-item1">
            <div class="table-item table-center1">
              <span>随访时间</span>
            </div>

            <div class="table-item table-center1">
              <span>治疗月序</span>
            </div>

            <div class="table-item table-center1">
              <span>督导人员</span>
            </div>

            <div class="table-item table-center1">
              <span>随访方式</span>
            </div>

            <div class="table-item table-item-height">
              <div class="table-item-left">
                <span>症状及体征</span>
              </div>
              <div class="table-item-right">
                <el-checkbox-group v-model="checks">
                  <el-checkbox label="0" class="margin-bottom">没有症状</el-checkbox>
                  <el-checkbox label="1" class="margin-bottom">咳嗽咳痰</el-checkbox>
                  <el-checkbox label="2" class="margin-bottom">低热盗汗</el-checkbox>
                  <el-checkbox label="3" class="margin-bottom">咯血或血痰</el-checkbox>
                  <el-checkbox label="4" class="margin-bottom">胸痛消瘦</el-checkbox>
                  <el-checkbox label="5" class="margin-bottom">恶心纳差</el-checkbox>
                  <el-checkbox label="6" class="margin-bottom">头痛失眠</el-checkbox>
                  <el-checkbox label="8" class="margin-bottom">视物模糊</el-checkbox>
                  <el-checkbox label="9" class="margin-bottom">皮肤瘙痒、皮疹</el-checkbox>
                  <el-checkbox label="10" class="margin-bottom">耳鸣、听力下降</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>

            <div class="table-item table-item-height table-item-height5">
              <div class="table-item-left">
                <span>生活方式指导</span>
              </div>
              <div class="table-item-right">
                <div class="table-item table-center1 border-right-none">
                  <span>吸烟</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>饮酒</span>
                </div>
              </div>
            </div>

            <div class="table-item table-item-height table-item-height3">
              <div class="table-item-left">
                <span>用药</span>
              </div>
              <div class="table-item-right">
                <div class="table-item table-center1 border-right-none">
                  <span>化疗方案</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>用法</span>
                </div>
                <div class="table-item table-center1 border-right-none table-item-height6">
                  <span>药品剂型</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>漏服药次数</span>
                </div>
              </div>
            </div>

            <div class="table-item table-center1">
              <span>药物不良反应</span>
            </div>

            <div class="table-item table-center1">
              <span>并发症或合并症</span>
            </div>

            <div class="table-item table-item-height table-item-height6">
              <div class="table-item-left">
                <span>转诊</span>
              </div>
              <div class="table-item-right">
                <div class="table-item table-center1 border-right-none">
                  <span>科别</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>原因</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>2周内随访，随访结果</span>
                </div>
              </div>
            </div>

            <div class="table-item table-center1">
              <span>处理意见</span>
            </div>

            <div class="table-item table-center1">
              <span>下次随访时间</span>
            </div>

            <div class="table-item table-center1 border-bottom-none">
              <span>随访医生签名</span>
            </div>

          </div>

          <!-- 滚动区域 -->
          <div class="table-right table-right1">
            <div class="table-right-box" ref="scrollRef">
              <div class="pointer" ref="pointerRef" :class="{ select: index === checkIndex }" v-for="(item, index) in list" :key="index" @click="select(index)">
                <div class="table-item table-center">
                  <span>年 月 日</span>
                </div>
                <div class="table-item table-center">
                  <span>第 月</span>
                </div>
                <div class="table-item table-center">
                  <el-radio-group v-model="sex" class="radio-width">
                    <el-radio label="0">医生</el-radio>
                    <el-radio label="1">家属</el-radio>
                    <el-radio label="2">自服药</el-radio>
                    <el-radio label="2">其他</el-radio>
                  </el-radio-group>
                </div>
                <div class="table-item table-center">
                  <el-radio-group v-model="sex">
                    <el-radio label="0">门诊</el-radio>
                    <el-radio label="1">家庭</el-radio>
                    <el-radio label="2">电话</el-radio>
                  </el-radio-group>
                </div>
                <div class="table-item table-item-height table-item-column">
                  <div class="table-item-height1">
                    <el-checkbox-group v-model="checks">
                      <el-checkbox label="0" class="margin-bottom">没有症状</el-checkbox>
                      <el-checkbox label="1" class="margin-bottom">咳嗽咳痰</el-checkbox>
                      <el-checkbox label="2" class="margin-bottom">低热盗汗</el-checkbox>
                      <el-checkbox label="3" class="margin-bottom">咯血或血痰</el-checkbox>
                      <el-checkbox label="4" class="margin-bottom">胸痛消瘦</el-checkbox>
                      <el-checkbox label="5" class="margin-bottom">恶心纳差</el-checkbox>
                      <el-checkbox label="6" class="margin-bottom">头痛失眠</el-checkbox>
                      <el-checkbox label="8" class="margin-bottom">视物模糊</el-checkbox>
                      <el-checkbox label="9" class="margin-bottom">皮肤瘙痒、皮疹</el-checkbox>
                      <el-checkbox label="10" class="margin-bottom">耳鸣、听力下降</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="table-item table-center table-item-height5 table-item-column">
                  <div class="table-item table-width table-center">
                    <span> 支/天</span>
                  </div>
                  <div class="table-item table-width table-center border-bottom-none">
                    <span> 两/天</span>
                  </div>
                </div>
                <div class="table-item table-item-height3 table-item-column border-bottom-none">
                  <div class="table-item table-width"></div>
                  <div class="table-item table-width table-center">
                    <el-radio-group v-model="sex">
                      <el-radio label="0" class="margin-top">每日</el-radio>
                      <el-radio label="1" class="margin-top">间歇</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="table-item table-width table-item-height6">
                    <el-checkbox-group v-model="checks" class="table-item-column group">
                      <el-checkbox label="0" class="margin-top">固定剂量复合制剂</el-checkbox>
                      <el-checkbox label="1" class="margin-top">散装药</el-checkbox>
                      <el-checkbox label="2" class="margin-top">板式组合药</el-checkbox>
                      <el-checkbox label="3" class="margin-top">注射剂</el-checkbox>
                    </el-checkbox-group>
                  </div>
                  <div class="table-item table-width table-center">
                    <span>次</span>
                  </div>
                </div>

                <div class="table-item table-center">
                  <el-radio-group v-model="sex">
                    <el-radio label="0">无</el-radio>
                    <el-radio label="1">有
                      <el-input></el-input>
                    </el-radio>
                  </el-radio-group>
                </div>

                <div class="table-item table-center">
                  <el-radio-group v-model="sex">
                    <el-radio label="0">无</el-radio>
                    <el-radio label="1">有
                      <el-input></el-input>
                    </el-radio>
                  </el-radio-group>
                </div>

                <div class="table-item table-item-height6 table-item-column border-bottom-none">
                  <div class="table-item table-width table-center">
                    <span>原因：</span>
                  </div>
                  <div class="table-item table-width table-center">
                    <span>机构及科室：</span>
                  </div>
                  <div class="table-item table-width table-center"></div>
                </div>

                <div class="table-item table-center">
                  <span></span>
                </div>

                <div class="table-item table-center">
                  <span></span>
                </div>

                <div class="table-item table-center border-bottom-none">
                  <span></span>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="children-table border-bottom-none">
          <div class="table-left table-item1">
            <div class="table-item table-center1 border-bottom-none">
              <span>停止治疗及原因</span>
            </div>
          </div>
          <div class="table-right table-right1">
            <div class="table-item1 table-center ">
              <span>出现停止治疗时间 年 月 日</span>
            </div>
            <div class="table-item1 table-center">
              <span>停止治疗原因：</span>
              <el-radio-group v-model="sex" class="group">
                <el-radio label="0">完成疗程</el-radio>
                <el-radio label="1">死亡</el-radio>
                <el-radio label="2">丢失</el-radio>
                <el-radio label="3">转入耐多药治疗</el-radio>
              </el-radio-group>
            </div>
          </div>
        </div>

        <div class="children-table">
          <div class="table-left table-item1">
            <div class="table-item table-item-height5 table-center1 border-bottom-none">
              <span>全程管理情况</span>
            </div>
          </div>
          <div class="table-right table-right1 table-item-column">
            <div class="table-item table-center1">
              <span>应访视患者_____次，实际访视____次；</span>
              <span>患者在疗程中，应服药____次，实际服药___次，服药率___%</span>
            </div>
            <div class="table-item border-bottom-none table-center1">
              <span>评估医生签名：</span>
            </div>
          </div>
        </div>
      </div>

      <div class="btns">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button class="blue" size="mini" @click="close">确定</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
export default {
  components: {
    UserBasicInfo
  },
  data () {
    return {
      tabIndex: 1,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      checkIndex: -1,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      checkList: [],
      list: ['1', '2', '3', '4'],
      pageNumber: 1,
      leftScrollWidth: 0
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    select (index) {
      if (index === this.checkIndex) {
        this.checkIndex = -1
      } else {
        this.checkIndex = index
      }
    },
    add () {
      this.list.push('1')
      console.log(this.list)
    },
    reduce () {
      this.list = this.list.filter(item => item !== this.checkIndex + 1 + '')
      this.checkIndex = -1
    },
    left () {
      console.log(this.list.length - this.pageNumber)
      if (this.pageNumber < 2) return console.log('无法向左滚动')
      this.pageNumber--
      console.log(this.pageNumber)
      this.$refs.scrollRef.style.cssText = 'transform: translateX(-' + this.$refs.pointerRef[0].clientWidth * (this.pageNumber - 1) + 'px);'
    },
    right () {
      if (this.list.length - this.pageNumber <= 3) return console.log('无法向右滚动')
      this.$refs.scrollRef.style.cssText = 'transform: translateX(-' + this.$refs.pointerRef[0].clientWidth * this.pageNumber + 'px);'
      this.pageNumber++
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.children {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #071224;

  .children-left {
    width: 270px;
    height: 100%;
    background-color: #081C38;
  }

  .children-right {
    position: relative;
    width: calc(100% - 290px);
    height: 100%;
    padding: 10px 20px;
    margin-left: 20px;
    box-sizing: border-box;
    background-color: #081C38;

    .code {
      position: absolute;
      top: 34px;
      right: 100px;
      color: #01EEFD;
      font-size: 22px;
    }

    .children-tab {
      display: flex;
      align-items: center;
      height: 38px;
      line-height: 38px;
      text-align: center;
      color: #fff;
      font-size: 22px;

      .children-item {
        height: 38px;
        line-height: 38px;
        margin-right: 20px;
        padding: 0 10px;
        background-color: #D5D5D5;
        text-align: center;
        color: #fff;
        font-size: 22px;
        cursor: pointer;
      }

      .children-active {
        background-color: #08C1CD;
      }
    }
  }

  .children-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 220px);

    &::-webkit-scrollbar {
      width: 0;
    }

  }

  .postion {
    position: absolute;
    top: 20px;
    right: 16px;
    width: 246px;
    background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
    border: 1px solid #1F72D3;
    border-radius: 6px;

    /deep/.el-input__inner {
      background-color: transparent;
      color: #fff !important;
      font-size: 18px;
      border: none;

    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54rpx;
    text-align: center;
    color: #01EEFD;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;

    .item {
      margin-right: 20px;
      color: #01EEFD;
      font-size: 22px;

      .key {
        margin: 0 10px;
      }

      .white {
        color: #fff;
        cursor: pointer;
      }

      .white1 {
        margin-left: 20px;
      }

      .blue {
        color: #01EEFD;
      }
    }
  }

  .children-table {
    display: flex;
    width: 100%;
    border: 1px solid #01EEFD;
    box-sizing: border-box;
  }

  .children-table1 {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;

    .position {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 251px;
      left: 1px;
      width: 67px;
      height: 198px;
      background-color: #081C38;
      border-right: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 14px;
      letter-spacing: 4px;
      writing-mode: vertical-lr;
    }

    .children-tr {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      box-sizing: border-box;
    }

    .children-tr1 {
      height: 106px;
    }

    .children-tr2 {
      height: 200px;
    }

    .children-tr3 {
      height: 160px;
    }

    .children-tr4 {
      height: 200px;
    }

    .children-tr5 {
      height: 250px;
    }

    .children-tr6 {
      height: 650px;
    }

    .children-tr7 {
      height: 150px;
    }

    .children-tr8 {
      height: 100px;
    }

    .children-tr9 {
      height: 500px;
    }

    .children-td {
      display: flex;
      align-items: center;
      height: 100%;
      border-right: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 14px;

      >span {
        margin-left: 14px;
      }

      .margin-left-none {
        margin-left: 0;
      }

      .color {
        white-space: nowrap;
        color: #fff;
      }
    }

    .children-td-left {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 46px;
      height: 100%;
      color: #01EEFD;
      font-size: 14px;
      padding: 4px;
      letter-spacing: 4px;
      box-sizing: border-box;
    }

    .children-td-text {
      writing-mode: vertical-lr;
    }

    .children-td-text1 {
      // display: flex;
      // flex-direction: column;
      // flex-wrap: wrap;
      // align-items: center;
      // justify-content: center;
      padding-left: 4px;
      box-sizing: border-box;
    }

    .children-td-right {
      width: calc(100% - 46px);
      height: 100%;
    }

    .children-td-list {
      display: flex;
      align-items: center;
      width: 100%;
      height: 50px;
      color: #01EEFD;
      font-size: 14px;
    }

    .border-left {
      border-left: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .border-bottom {
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .border-right-none {

      border-right: none !important;
    }

    .text-align {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .children-td-list-center {

      justify-content: center;
    }

    .children-td-column {
      flex-direction: column;
    }

    .children-td-title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      padding-left: 14px;
      box-sizing: border-box;
    }

    .checkbox {
      width: 100%;
      height: calc(100% - 50px);
      padding-left: 8px;
      box-sizing: border-box;
    }

    .margin-left {
      margin-left: 14px;
    }

    .margin-bottom {
      margin-bottom: 10px;
    }

    .children-flex1 {
      flex: 1;
    }

    .children-flex2 {
      flex: 2;
    }

    .children-flex3 {
      flex: 3;
    }

    .children-flex4 {
      flex: 4;
    }

    .children-flex5 {
      flex: 5;
    }

    .children-flex6 {
      flex: 6;
    }

    .children-flex7 {
      flex: 7;
    }

    .children-flex8 {
      flex: 8;
    }

    .children-flex10 {
      flex: 10;
    }

    .children-flex12 {
      flex: 12;
    }

    .children-text-center {
      padding-left: 0;
      justify-content: center;

      >span {
        margin-left: 0;
      }
    }

    .children-text-right {
      justify-content: flex-end;

      >span {
        width: calc(100% - 67px);
        text-align: center;
      }
    }

    .children-wrap {
      flex-wrap: wrap;
    }

    .input {
      width: 30px;
    }

    .input1 {
      width: 80px;
    }

    .label {
      width: calc(100% - 14px);
      margin-left: 10px;
    }

    /deep/.el-input__inner {
      height: 30px;
      line-height: 30px;
      padding: 0;
      margin: 0;
      background-color: transparent;
      border: none;
      border-radius: 0;
      border-bottom: 1px solid #01EEFD;
      color: #01EEFD;
      font-size: 14px;
    }

    .el-radio,
    .el-checkbox {
      color: #fff;
    }

    /deep/.el-radio__inner,
    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    /deep/.el-radio__input.is-checked .el-radio__inner,
    /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
      background-color: #01EEFD;
      border-color: #01EEFD;
    }

    /deep/.el-radio__input.is-checked+.el-radio__label,
    /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
      color: #01EEFD;
    }

    .el-checkbox-group {
      // padding-left: 20px;
    }

  }

  .children-border {
    border: 1px solid #01EEFD;
    border-bottom: none;
    box-sizing: border-box;
  }

  .children-border-bottom {
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;
  }

  .children-border-right {
    border-right: 1px solid #01EEFD;
    box-sizing: border-box;
  }

  .children-border-right-none {
    border-right: none !important;
  }

  .children-border-bottom-none {
    border-bottom: none !important;
  }

  .children-border-three {
    border-right: 3px solid transparent !important;
  }

  .btns {
    display: flex;
    align-items: center;
    height: 80px;

    .el-button {
      border: none;
      border-radius: 0;
      background-color: #33465C;
      color: #fff;
    }

    .blue {
      margin-left: 30px;
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .table-left {
    display: flex;
    flex-direction: column;
    width: 250px;
  }

  .table-right {
    overflow: hidden;
    width: 1200px;
    .table-right-box {
      transition: all 0.3s;
    }

    .pointer {
      width: 300px;
      display: inline-block;
      cursor: pointer;
    }
  }

  .table-right1 {
    white-space: nowrap;
  }

  .table-item {
    height: 50px;
    border-right: 1px solid #01EEFD;
    border-bottom: 1px solid #01EEFD;
    color: #01EEFD;
    font-size: 14px;
    box-sizing: border-box;

    .radio-width {
      display: flex;
      flex-wrap: wrap;
      width: 90%;

      .el-checkbox {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    /deep/.el-input__inner {
      width: 100px;
      height: 20px;
      border-radius: 0;
      background-color: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      color: #fff;
      font-size: 14px;
    }

    .table-item-left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 100%;
      writing-mode: vertical-lr;
      border-right: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .table-item-right {
      width: calc(100% - 45px);
      height: 100%;

      .el-checkbox-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-left: 10px;
      }
    }
  }

  .table-item-height {
    display: flex;
    height: 320px;
  }

  .table-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-center1 {
    display: flex;
    align-items: center;
    padding-left: 14px;
    box-sizing: border-box;
  }

  .table-item1 {
    flex: 1;
    color: #01EEFD;
    font-size: 14px;
  }

  .pointer {
    cursor: pointer;
  }

  .select {
    border: 1px solid #fff;
  }

  .table-item5 {
    flex: 5;
  }

  .table-width {
    width: 100%;
    border-right: none;
  }

  .table-item-height1 {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    .el-checkbox-group {
      display: flex;
      flex-direction: column;
      margin-left: 10px;
    }
  }

  .table-item-height2 {
    width: 100%;
    height: 250px;
  }

  .table-item-height3 {
    width: 100%;
    height: 300px;
  }

  .table-item-height4 {
    width: 100%;
    height: 200px;
  }

  .table-item-height5 {
    height: 100px;
  }

  .table-item-height6 {
    height: 150px;
  }

  .table-item-column {
    display: flex;
    flex-direction: column;
  }

  .table-item-row {
    display: flex;
    flex-direction: row;
  }

  .color {
    color: #fff;
  }

  .border-right-none {
    border-right: none !important;
  }

  .border-bottom-none {
    border-bottom: none !important;
  }

  .group {
    margin-left: 17px;
  }

  .margin-top {
    margin-top: 10px;
  }

  .margin-bottom {
    margin-bottom: 10px;
  }

  .el-radio,
  .el-checkbox {
    color: #fff;
  }

  /deep/.el-radio__inner,
  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner,
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label,
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #01EEFD;
  }

}
</style>
