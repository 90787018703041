<template>
  <div class="report">
    <div class="report-close" @click="close">
      <span class="icon el-icon-close"></span>
    </div>
    <div class="report-top">
      <div class="title">
        <span>门诊手术预约</span>
      </div>
    </div>
    <div class="report-bottom">
      <div class="report-left">
        <div class="info">
          <div class="info-left">
            <img src="../../img/emr/user.png" class="info-img">
          </div>
          <div class="info-right">
            <span>王丽霞</span>
            <span class="sex">女</span>
            <span>44岁3月</span>
            <span>202111606032</span>
          </div>
        </div>
        <div class="list">
          <div class="head">
            <div class="select active">
              <span>今日</span>
            </div>
            <div class="select">
              <span>近7日</span>
            </div>
            <div class="select">
              <span>近3月</span>
            </div>
            <el-select v-model="value" placeholder="请选择" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="scroll">
            <div class="item" @click="selectTab(0)">
              <div class="line"></div>
              <div class="round" :class="{ 'round-active': selectIndex === 0 }"></div>
              <div class="card" :class="{ 'card-active': selectIndex === 0 }">
                <div class="card-top">
                  <span>新型冠状病毒核酸检测 </span>
                </div>
                <div class="card-bottom">
                  <span>2021-05-22 08:55 </span>
                  <span class="status">已出报告</span>
                </div>
              </div>
            </div>
            <div class="item" @click="selectTab(1)">
              <div class="line"></div>
              <div class="round" :class="{ 'round-active': selectIndex === 1 }"></div>
              <div class="card" :class="{ 'card-active': selectIndex === 1 }">
                <div class="card-top">
                  <span>新型冠状病毒核酸检测 </span>
                </div>
                <div class="card-bottom">
                  <span>2021-05-22 08:55 </span>
                  <span class="status yellow">未出报告</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-right">
        <div class="report-scroll">
          <div class="report-scroll-title">
            <span>患者健康信息</span>
          </div>
          <div class="report-scroll-list report-scroll-list1">
            <el-select v-model="value" placeholder="有无药物过敏史" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="是否使用抗生素" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="术中是否冰冻" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="是否单病种" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="report-scroll-title">
            <span>病历摘要</span>
          </div>
          <div class="report-scroll-list">
            <div class="report-scroll-list-left">
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>HBsAg</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>抗-HBs</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>HBsAg</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>抗-HBe</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>抗-HBc</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>HIV</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>梅毒</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>丙肝</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
              <div class="report-scroll-list-item">
                <div class="key">
                  <span>特殊感染</span>
                </div>
                <el-input class="input-width"></el-input>
              </div>
            </div>
            <div class="report-scroll-list-right">
              <div class="btn">
                <span>新增检项目</span>
              </div>
              <div class="label label1">
                <div class="label-text">
                  <span>ABC血型鉴定+RH血型鉴定</span>
                </div>
                <span class="delect el-icon-delete-solid"></span>
              </div>
              <div class="label2">
                <div class="label-text">
                  <span>ABC血型鉴定+RH血型鉴定</span>
                </div>
                <div class="label-text">
                  <span>【已出报告】-无异常</span>
                  <span class="label-blue">查看报告</span>
                </div>
              </div>
            </div>
          </div>
          <div class="report-scroll-title">
            <span>申请内容</span>
          </div>
          <div class="report-scroll-list report-scroll-list2">
            <div class="select-width">
              <div class="select">
                <div class="select-item">
                  <span>择期</span>
                </div>
                <div class="select-item">
                  <span>限期</span>
                </div>
                <div class="select-item">
                  <span>紧急</span>
                </div>
              </div>
            </div>
            <el-select class="select-width" v-model="value" placeholder="选择手术执行科室" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select class="select-width" v-model="value" placeholder="拟施时间" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select class="select-width" v-model="value" placeholder="通知时间" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-select class="select-width" v-model="value" placeholder="选择性别" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select class="select-width" v-model="value" placeholder="选择切口类型" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select class="select-width" v-model="value" placeholder="非预期时间" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select class="select-width" v-model="value" placeholder="日间手术" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>

            <el-select class="select-width" v-model="value" placeholder="感染手术" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select class="select-width" v-model="value" placeholder="手术等级" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <div class="select-width1"></div>
          </div>
          <div class="report-scroll-list report-scroll-list3">
            <div class="report-scroll-item">
              <div class="report-scroll-key">
                <span>术前诊断</span>
              </div>
              <el-input type="textarea" placeholder="">
              </el-input>
            </div>
            <div class="report-scroll-item">
              <div class="report-scroll-key">
                <span>拟行手术</span>
              </div>
              <el-input type="textarea" placeholder="">
              </el-input>
            </div>
            <div class="report-scroll-item">
              <div class="report-scroll-key">
                <span>手术部位</span>
              </div>
              <el-input type="textarea" placeholder="">
              </el-input>
            </div>
            <div class="report-scroll-item">
              <div class="report-scroll-key">
                <span>拟行麻醉</span>
              </div>
              <el-input type="textarea" placeholder="">
              </el-input>
            </div>
          </div>
        </div>
        <div class="report-btns">
          <el-button size="small" @click="close">取消</el-button>
          <el-button size="small">打印</el-button>
          <el-button size="small" class="blue">提交手术申请</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectIndex: 0,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      radio: '',
      imageUrl: ''
    }
  },
  methods: {
    selectTab (index) {
      this.selectIndex = index
    },
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.report {
  position: absolute;
  top: 24px;
  right: 110px;
  z-index: 88;
  width: 1200px;
  height: 740px;
  padding: 14px 20px;
  background-color: #fff;
  box-shadow: -3px 2px 6px 1px rgba(0, 0, 0, 0.1608);
  border-radius: 6px;
  box-sizing: border-box;

  .report-close {
    position: absolute;
    top: 8px;
    right: 14px;
    z-index: 888;
    color: #707070;
    font-size: 26px;
    cursor: pointer;
  }

  .report-top {
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    border-bottom: 1px solid #A1A6BB;
    box-sizing: border-box;

    .title {
      color: #666666;
      font-size: 14px;
    }
  }

  .report-bottom {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 30px);

    .report-left {
      width: 264px;
      height: 100%;
      border-right: 1px solid #A1A6BB;
      box-sizing: border-box;

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 90px;
        margin: 10px 0;
        padding: 9px 15px;
        background-color: #81ACD3;
        box-sizing: border-box;

        .info-left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 69px;
          height: 69px;
          border-radius: 50%;
          background-color: #fff;

          .info-img {
            width: 60px;
            height: 60px;
          }
        }

        .info-right {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: calc(100% - 75px);
          color: #333333;
          font-size: 16px;

          .sex {
            color: #FF00F7;
          }
        }
      }

      .list {
        width: 100%;
        height: calc(100% - 110px);
        border-top: 1px solid #A1A6BB;
        box-sizing: border-box;

        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 50px;
          padding-right: 16px;
          box-sizing: border-box;

          .select {
            white-space: nowrap;
            color: #3E3E3E;
            font-size: 14px;
            cursor: pointer;
          }

          .active {
            color: #1269B7;
          }

          .el-select {
            width: 100px;
          }
        }

        .scroll {
          width: calc(100% - 8px);
          height: calc(100% - 50px);

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 70px;
            margin-bottom: 10px;

            .line {
              width: 15px;
              height: 1px;
              background-color: #A1A6BB;
            }

            .round {
              width: 14px;
              height: 14px;
              background-color: #666666;
              border-radius: 50%;
            }

            .round-active {
              background-color: #1269B7;
            }

            .card {
              display: flex;
              flex-direction: column;
              justify-content: space-around;
              width: calc(100% - 35px);
              height: 100%;
              margin-left: 4px;
              padding: 7px 5px;
              background-color: #D9D9D9;
              border-radius: 6px;
              box-sizing: border-box;
              cursor: pointer;

              .card-top {
                color: #333333;
                font-size: 14px;
              }

              .card-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #666666;
                font-size: 12px;

                .status {
                  // color: #FF9200;
                }

                .yellow {
                  color: #FF9200;
                }
              }
            }

            .card-active {
              background-color: #79B3E7;

              .card-top {
                color: #1269B7;
              }

              .card-bottom {
                .status {
                  color: #1269B7;
                }
              }
            }
          }
        }
      }
    }

    .report-right {
      width: calc(100% - 264px);
      height: 100%;
      padding: 10px;
      box-sizing: border-box;

      .report-scroll {
        overflow-y: scroll;
        width: 100%;
        height: calc(100% - 50px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .report-scroll-title {
          height: 40px;
          line-height: 40px;
          color: #3E3E3E;
          font-size: 14px;
        }

        .report-scroll-list {
          display: flex;
          justify-content: space-between;
          width: 100%;
          margin-bottom: 10px;

          .report-scroll-item {
            display: flex;
            width: calc(50% - 17px);
            margin-bottom: 10px;

            .report-scroll-key {
              white-space: nowrap;
              color: #3E3E3E;
              font-size: 14px;
            }

            /deep/.el-textarea {
              // width: calc(100% - 100px);
            }

            /deep/.el-textarea__inner {
              resize: none;
            }
          }

          /deep/.el-radio__label {
            font-size: 14px;
          }

          .key {
            width: 70px;
            color: #3E3E3E;
            font-size: 14px;
          }

          .select1 {
            width: calc(100% - 80px);

            /deep/.el-input__inner {
              height: 60px;
            }
          }

          .value {
            width: calc(100% - 80px);
            height: 120px;

            /deep/.avatar-uploader .el-upload {
              border: 1px solid #D8DAE3;
              border-radius: 6px;
              cursor: pointer;
              position: relative;
              overflow: hidden;
            }

            .avatar-uploader .el-upload:hover {
              border-color: #409EFF;
            }

            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 120px;
              height: 120;
              line-height: 120px;
              text-align: center;
            }

            .avatar {
              width: 178px;
              height: 178px;
              display: block;
            }
          }

          .report-scroll-list-left {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            width: 60%;

            .report-scroll-list-item {
              display: flex;
              align-items: center;
              width: 50%;
              margin-bottom: 10px;

              .key {
                margin-right: 8px;
                text-align: right;
              }

              .input-width {
                width: 100px;
              }
            }
          }

          .report-scroll-list-right {
            display: flex;
            flex-direction: column;
            width: 40%;

            .btn {
              width: 100px;
              height: 30px;
              line-height: 30px;
              padding: 0 16px;
              text-align: center;
              background-color: #9DC4FF;
              border: 1px solid #7BB4E7;
              border-radius: 6px;
              color: #2772E2;
              font-size: 14px;
              cursor: pointer;
            }

            .label {
              width: 227px;
              height: 30px;
              line-height: 30px;
              margin-top: 10px;
              padding: 0 16px;
              background-color: #D9D9D9;
              border-radius: 6px;
            }

            .label1 {
              display: flex;
              align-items: center;
              justify-content: space-between;

              .label-text {
                color: #3E3E3E;
                font-size: 14px;
              }

              .delect {
                color: #FF0101;
                font-size: 20px;
                cursor: pointer;
              }
            }

            .label2 {
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-wrap: wrap;
              width: 227px;
              margin-top: 10px;
              padding: 5px 16px;
              background-color: #D9D9D9;
              border-radius: 6px;

              .label-text {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 30px;
                line-height: 30px;
                color: #3E3E3E;
                font-size: 14px;

                .label-blue {
                  height: 26px;
                  color: #1269B7;
                  font-size: 12px;
                  border-bottom: 1px solid #1269B7;
                  box-sizing: border-box;
                  cursor: pointer;
                }
              }
            }
          }
        }

        .report-scroll-list1 {
          width: 80%;
          flex-wrap: wrap;
          align-items: center;

          .el-select {
            margin-bottom: 10px;
          }
        }

        .report-scroll-list2 {
          width: 100%;
          flex-wrap: wrap;
          align-items: center;

          .el-select {
            margin-bottom: 10px;
          }

          .select-width {
            width: 23%;
          }

          .select-width1 {
            width: 48.5%;
          }

          .select {
            display: flex;
            align-items: center;
            width: 132px;
            height: 26px;
            margin-bottom: 10px;
            border: 1px solid #79B3E7;
            border-right: none;
            box-sizing: border-box;

            .select-item {
              width: 44px;
              height: 26px;
              line-height: 26px;
              text-align: center;
              border-right: 1px solid #79B3E7;
              box-sizing: border-box;
              color: #79B3E7;
              font-size: 14px;
            }
          }
        }

        .report-scroll-list3 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          flex-wrap: wrap;
        }

        .report-scroll-text {
          margin-bottom: 10px;
          color: #3E3E3E;
          font-size: 14px;
        }
      }

      .report-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 50px;

        .el-button {
          min-width: 100px;
          margin-left: 38px;
          border: 1px solid #1269B7;
          color: #1269B7;
        }

        .blue {
          background-color: #1269B7;
          color: #fff;
        }
      }
    }
  }
}
</style>
