<template>
  <div class="tab">
    <div class="son-search">
      <div class="son-search-item">
        <div class="son-search-key">
          <span>患者姓名</span>
        </div>
        <el-input v-model="params.hzxm" placeholder="请输入姓名"></el-input>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>转诊日期</span>
        </div>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>转入机构</span>
        </div>
        <el-select v-model="params.zrjg" placeholder="请选择">
          <el-option v-for="item in zrjgs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <el-button type="primary" plain>查询</el-button>
        <el-button type="primary" @click="add">新增</el-button>
        <el-button type="primary" @click="add">批量转诊</el-button>
      </div>
    </div>
    <div class="son-box">
      <el-table :data="tableData" border stripe height="100%">
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column prop="name" label="性别">
          <template slot-scope="scope">
            <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="身份证号" width="200px">
        </el-table-column>
        <el-table-column label="转出信息">
          <el-table-column prop="address" label="管理机构">
          </el-table-column>
          <el-table-column prop="address" label="管理医生">
          </el-table-column>
          <el-table-column prop="address" label="转诊原因">
          </el-table-column>
          <el-table-column prop="address" label="转出人">
          </el-table-column>
          <el-table-column prop="address" label="转时间">
          </el-table-column>
        </el-table-column>
        <el-table-column label="转入信息">
          <el-table-column prop="address" label="管理机构">
          </el-table-column>
          <el-table-column prop="address" label="接收人">
          </el-table-column>
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="see(scope)">查看档案</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="son-pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400" hide-on-single-page>
      </el-pagination>
    </div>
    <Add v-if="false"></Add>
  </div>
</template>

<script>
import Add from '../add.vue'
export default {
  components: {
    Add
  },
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      params: {
        date: '',
        hzxm: '',
        zrjg: ''
      },
      zrjgs: [],
      tableData: [{}],
      currentPage: 1,
      showAdd: true
    }
  },
  methods: {
    see () {
      // this.$router.push({
      //   path: '/intelligentmonitoring/highriskpopulationscreening/details'
      // })
    },
    add () {
      this.showAdd = true
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 110px;

    .son-search-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
      padding-right: 10px;
      box-sizing: border-box;

      .son-search-key {
        width: 80px;
        white-space: nowrap;
        color: #999999;
        font-size: 16px;
      }

      .el-button {
        margin-left: 10px;
      }
    }
  }

  .son-box {
    width: 100%;
    height: calc(100% - 190px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .del {
      color: red;
    }

    .green {
      color: #28D094;
    }

    .gray {
      color: #999999;
    }

    .blue {
      color: #0000FF;
    }
  }

  .son-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    /deep/.el-pager li {
      margin-left: 5px;
      margin-right: 5px;
      background-color: rgba(242, 247, 251, 1);
    }

    /deep/.el-pager li.active {
      background-color: rgba(0, 15, 255, 1);
      color: #fff;
    }
  }
}
</style>
