<template>
  <div class="update">
    <div class="update-box">
      <div class="update-head">
        <span class="update-title">血压记录</span>
        <span @click="close" class="update-close el-icon-close"></span>
      </div>
      <div class="update-content">
        <div class="update-line">
          <div class="update-key">
            <span>测量时间</span>
          </div>
          <div class="update-value">
            <el-date-picker v-model="date"  type="datetime" placeholder="选择日期时间"></el-date-picker>
          </div>
        </div>
        <div class="update-line">
          <div class="update-key">
            <span>收缩压</span>
          </div>
          <div class="update-value">
            <el-input-number :step=".1" v-model="value" controls-position="right" placeholder="请输入"></el-input-number>
          </div>
          <div class="update-unit">
            <span>mmHg</span>
          </div>
        </div>
        <div class="update-line">
          <div class="update-key">
            <span>舒张压</span>
          </div>
          <div class="update-value">
            <el-input-number :step=".1" v-model="value1" controls-position="right" placeholder="请输入"></el-input-number>
          </div>
          <div class="update-unit">
            <span>mmHg</span>
          </div>
        </div>
      </div>
      <div class="update-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary">确定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      date: '',
      value: '',
      value1: ''
    }
  },
  methods: {
    close () {
      this.$parent.showUpdate = false
    }
  }
}
</script>

<style lang="less" scoped>
  .update {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .1);

    .update-box {
      overflow: hidden;
      width: 50%;
      height: 50%;
      padding-left: 40px;
      padding-right: 40px;
      padding-bottom: 20px;
      background-color: #fff;
      border: 1px solid rgba(242, 242, 242, 1);
      border-radius: 4px;
      box-sizing: border-box;

      .update-head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 60px;

        .update-title {
          font-size: 16px;
        }

        .update-close {
          font-size: 20px;
          cursor: pointer;
        }
      }

      .update-content {
        overflow: hidden scroll;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: 100%;
        height: calc(100% - 100px);

        &::-webkit-scrollbar {
          width: 0;
        }

        .update-line {
          display: flex;
          align-items: center;
          width: 100%;

          .update-key {
            width: 100px;
            color: #999999;
            font-size: 14px;
          }

          .update-value {
            width: 240px;

            /deep/.el-date-editor {
              width: 100%;
            }

            /deep/.el-input-number {
              width: 100%;
            }
          }

          .update-unit {
            width: 80px;
            text-align: center;
            color: #999999;
            font-size: 14px;
          }
        }
      }

      .update-footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 40px;
      }
    }
  }
</style>
