<template>
  <div class="sub-page">
    <div class="sub-top">
      <div class="sub-label sub-label1">
        <div class="info">
          <span>总用户数</span>
          <span>{{ info.zyhrs || '-' }}人</span>
        </div>
      </div>

      <div class="sub-label sub-label1">
        <div class="info">
          <span>总检测数</span>
          <span>{{ info.zjcrs || '-' }}人</span>
        </div>
      </div>

      <div class="sub-label sub-label1">
        <div class="info">
          <span>今日检测数</span>
          <span>{{ info.jrjcrs || '-' }}人</span>
        </div>
      </div>

      <div class="sub-label sub-label2">
        <div class="info">
          <span>血糖数量</span>
          <span>{{ info.xtjcsl || '-' }}人</span>
        </div>
      </div>

      <div class="sub-label sub-label3">
        <div class="info">
          <span>血压数量</span>
          <span>{{ info.xyjcsl || '-' }}人</span>
        </div>
      </div>

      <div class="sub-label sub-label3">
        <div class="info">
          <span>血压/血压数量</span>
          <span>-人</span>
        </div>
      </div>

      <div class="sub-label sub-label4">
        <div class="info">
          <span>家签数量</span>
          <span>-人</span>
        </div>
      </div>
    </div>
    <div class="sub-bottom">
      <div class="sub-item">
        <div class="sub-select">
          <div class="sub-select-box">
            <div class="sub-select-title">
              <img src="../../../../assets/img/znjc/organ.png" class="icon">
              <span>机构信息</span>
            </div>
            <div class="sub-select-info">
              <!-- placeholder="古城莹镇卫生院" -->
              <el-select v-model="info.jgbh1" @change="change">
                <el-option v-for="item in jglist" :key="item.jgbh" :label="item.jddw" :value="item.id">
                </el-option>
              </el-select>
              <!-- placeholder="下属机构：14家" -->
              <el-select v-model="info.jgbh2" @change="change2">
                <el-option v-for="item in xsjglist" :key="item.jgbh" :label="item.jddw" :value="item.id">
                </el-option>
              </el-select>
              <!-- placeholder="机构排名" -->
              <el-select v-model="info.jgbh3" @change="change3">
                <el-option v-for="item in jgpm" :key="item.jgbh" :label="item.jddw" :value="item.id">
                </el-option>
              </el-select>
            </div>
          </div>
        </div>
        <div class="sub-title">
          <div class="sub-title-box">
            <span>实时监测</span>
            <span>-</span>
          </div>
        </div>
        <el-table :data="list1" height="50%">
          <el-table-column prop="jcsj" label="时间">
          </el-table-column>
          <el-table-column prop="name" label="姓名">
          </el-table-column>
          <el-table-column prop="ssjgmc" label="所属机构">
          </el-table-column>
          <el-table-column prop="jcxm" label="项目">
          </el-table-column>
          <el-table-column prop="jcjg" label="监测结果">
          </el-table-column>
          <el-table-column prop="glys" label="管理医生">
          </el-table-column>
        </el-table>
      </div>

      <div class="sub-item">
        <div class="sub-title sub-title1">
          <div class="sub-title-box">
            <span>任务</span>
            <span>-</span>
          </div>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex === 0 }" @click="tabClick(0)">
            <span>任务提醒</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex === 1 }" @click="tabClick(1)">
            <span>任务进站推送</span>
          </div>
        </div>
        <el-table :data="list2" height="71%">
          <el-table-column prop="rwlx" label="任务类型">
          </el-table-column>
          <el-table-column prop="rwsj" label="任务时间">
          </el-table-column>
          <el-table-column prop="rwnr" label="任务内容">
          </el-table-column>
          <el-table-column prop="rwzrys" label="任务责任人">
          </el-table-column>
          <el-table-column prop="czbf" label="处置办法">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template>
              <div class="operate">
                <span>查看</span>
                <span>处置</span>
                <span>删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="sub-item">
        <div class="sub-title sub-title1">
          <div class="sub-title-box">
            <span>工作提醒</span>
            <span>-</span>
          </div>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex1 === 0 }" @click="tabClick1(0)">
            <span>随访预警</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex1 === 1 }" @click="tabClick1(1)">
            <span>血压预警</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex1 === 2 }" @click="tabClick1(2)">
            <span>血糖预警</span>
          </div>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item" :class="{ 'sub-tab-active': 0 === tabIndex2 }" @click="tabClick2(0)">
            <span>7天提醒</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': 1 === tabIndex2 }" @click="tabClick2(1)">
            <span>30天提醒</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': 2 === tabIndex2 }" @click="tabClick2(2)">
            <span>随访总任务</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': 3 === tabIndex2 }" @click="tabClick2(3)">
            <span>本季度待随访</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': 4 === tabIndex2 }" @click="tabClick2(4)">
            <span>应访未防</span>
          </div>
        </div>
        <el-table :data="list3" height="54%">
          <el-table-column prop="jhsfrq" label="计划随访日期">
          </el-table-column>
          <el-table-column prop="sfr" label="随访人">
          </el-table-column>
          <el-table-column prop="zdrqbq" label="重点人群标签">
          </el-table-column>
          <el-table-column prop="brdh" label="本人电话">
          </el-table-column>
          <el-table-column prop="jzdh" label="家人电话">
          </el-table-column>
          <el-table-column prop="sfyw" label="随访业务">
          </el-table-column>
          <el-table-column prop="glys" label="管理医生">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <div>
                <span>随访</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="sub-item">
        <div class="sub-title sub-title1">
          <div class="sub-title-box">
            <span>慢病管理</span>
            <span>-</span>
          </div>
        </div>
        <div class="sub-tab">
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex3 === 0 }" @click="tabClick3(0)">
            <span>血压监测</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex3 === 1 }" @click="tabClick3(1)">
            <span>血糖监测</span>
          </div>
          <div class="sub-tab-item" :class="{ 'sub-tab-active': tabIndex3 === 2 }" @click="tabClick3(2)">
            <span>BMI监测</span>
          </div>
        </div>
        <div class="sub-charts">
          <div class="sub-charts-left">
            <div class="sub-charts-item">
              <div class="sub-charts-info">
                <span>-</span>
                <span>-</span>
                <span class="size">-同步：-% ↑</span>
              </div>
              <div class="echarts" id="echarts8"></div>
            </div>
            <div class="sub-charts-item">
              <div class="sub-charts-info">
                <span>-</span>
                <span>-</span>
                <span class="size">-同步：-% ↑</span>
              </div>
              <div class="echarts" id="echarts9"></div>
            </div>
            <div class="sub-charts-item">
              <div class="sub-charts-info">
                <span>-</span>
                <span>-</span>
                <span class="size">-同步：-% ↑</span>
              </div>
              <div class="echarts" id="echarts10"></div>
            </div>
          </div>
          <div class="sub-charts-right" id="echarts5"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data () {
    return {
      tableData: [{
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }, {
        date: '1',
        name: '1',
        address: '1'
      }],
      options: [{
        value: '1',
        label: '111'
      }],
      value: '',
      value1: '',
      value2: '',
      value3: '',
      myChart5: null,
      myChart8: null,
      myChart9: null,
      myChart10: null,
      tableHeight1: 265,
      tableHeight2: 360,
      tableHeight3: 320,
      tabIndex: 0,
      tabIndex1: 0,
      tabIndex2: 0,
      tabIndex3: 0,
      jglist: [],
      xsjglist: [],
      jgpm: [],
      info: {
        jgbh1: '',
        jgbh2: '',
        jgbh3: '',
        jddw: ''
      },
      list1: [],
      obj1: {},
      list2: [],
      list3: [],
      obj2: {},
      list4: [],
      obj3: {},
      list5: [],
      type1: '01', // 01 任务提醒 02 任务推送提醒
      type2: '01', // 01 天 02 30天 03总任务数 04本季度 05 应访未访
      type3: '01', // 01 血压 02 血糖
      type4: '01' // 01 血压 02 血糖
    }
  },
  computed: {
    ...mapState(['loginInfo'])
  },
  created () {
    this.getInfo()
    this.getList1()
    this.getList2()
    this.getList3()
    this.getList4()
    this.getList5()
    this.getjglb1()
    this.getjglb2()
    this.getjglb3()
  },
  mounted () {
    this.$nextTick(() => {
      this.canvas5()
      this.canvas8()
      this.canvas9()
      this.canvas10()
    })
    window.addEventListener('resize', this.getresize)
    // this.getPageWidth()
  },
  beforeDestroy () {
    // 页面销毁时销毁监听事件
    window.removeEventListener('resize', this.getresize)
  },
  methods: {
    getresize () {
      setTimeout(() => {
        this.myChart5.resize()
        this.myChart8.resize()
        this.myChart9.resize()
        this.myChart10.resize()
        this.resizeHeight()
      }, 100)
    },
    canvas5 () {
      var chartDom = document.getElementById('echarts5')
      this.myChart5 = this.$echarts.init(chartDom)
      const option = {
        grid: {
          show: false,
          top: '14%',
          right: '5%',
          bottom: '20%',
          left: '14%'
        },
        dataZoom: [
          {
            xAxisIndex: 0, // 这里是从X轴的0刻度开始
            disabled: false,
            zoomLock: true,
            preventDefaultMouseMove: false,
            filterMode: 'empty',
            type: 'inside',
            startValue: 0, // 从头开始。
            endValue: 3 // 一次性展示6个。
          }

        ],
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          }
        },
        legend: {
          // orient: 'vertical',
          x: 'right',
          y: 'top',
          textStyle: {
            fontSize: 12,
            color: '#FFFFFF'
          },
          padding: [12, 21, 0, 0]
        },
        xAxis: {
          type: 'category',
          data: ['2022-08-03', '2022-08-04', '2022-08-05', '2022-08-06', '2022-08-07', '2022-08-08', '2022-08-09'],
          axisTick: {
            // y轴刻度线
            show: false
          },
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            },
            textStyle: {
              color: '#04B4D0',
              fontSize: 13
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            color: '#fff',
            fontSize: 13
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: ['#04B4D0']
            }
          }
        },
        series: [
          {
            name: 'xxx-1',
            data: [820, 932, 901, 934, 1290, 1330, 1320],
            type: 'line',
            smooth: true
          },
          {
            name: 'xxx-2',
            data: [720, 832, 901, 834, 1290, 330, 320],
            type: 'line',
            smooth: true
          },
          {
            name: 'xxx-3',
            data: [620, 600, 701, 834, 990, 330, 320],
            type: 'line',
            smooth: true
          }
        ]
      }

      option && this.myChart5.setOption(option)
    },
    canvas8 () {
      var chartDom = document.getElementById('echarts8')
      this.myChart8 = this.$echarts.init(chartDom)
      const option = {
        color: ['#59E8FF'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 2,
                color: [
                  [1, '#fff']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 80,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 9,
              color: '#fff',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart8.setOption(option)
    },
    canvas9 () {
      var chartDom = document.getElementById('echarts9')
      this.myChart9 = this.$echarts.init(chartDom)
      const option = {
        color: ['#59E8FF'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 2,
                color: [
                  [1, '#fff']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 80,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 9,
              color: '#fff',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart9.setOption(option)
    },
    canvas10 () {
      var chartDom = document.getElementById('echarts10')
      this.myChart10 = this.$echarts.init(chartDom)
      const option = {
        color: ['#59E8FF'],
        series: [
          {
            type: 'gauge',
            startAngle: 90,
            endAngle: -270,
            pointer: {
              show: false
            },
            progress: {
              show: true,
              overlap: false,
              roundCap: true,
              clip: false
            },
            axisLine: {
              lineStyle: {
                width: 2,
                color: [
                  [1, '#fff']
                ]
              }
            },
            splitLine: {
              show: false,
              distance: 0,
              length: 10
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              show: false,
              distance: 50
            },
            data: [
              {
                value: 90,
                detail: {
                  valueAnimation: true,
                  offsetCenter: ['0%', '0%']
                }
              }
            ],
            detail: {
              fontSize: 9,
              color: '#fff',
              fontWeight: '400',
              formatter: '{value}%'
            }
          }
        ]
      }

      option && this.myChart10.setOption(option)
    },

    tabClick (index) {
      const arr = ['01', '02']
      this.tabIndex = index
      this.type1 = arr[index]
      this.getList2()
    },
    tabClick1 (index) {
      this.tabIndex1 = index
      this.type2 = '01'
      if (index === 0) {
        this.getList3()
      } else {
        const arr = ['', '01', '02']
        this.type3 = arr[index]
        this.getList4()
      }
    },
    tabClick2 (index) {
      const arr = ['01', '02', '03', '04', '05']
      this.tabIndex2 = index
      this.type2 = arr[index]
      this.getList3()
    },
    tabClick3 (index) {
      this.tabIndex3 = index
      const arr = ['01', '02', '']
      this.type4 = arr[index]
      this.getList5()
    },
    async getInfo () {
      try {
        const { data: res } = await this.$http.post('/zhyyapi/ykdrwkbsy', { ssjgbh: this.loginInfo.jgbh || '', userid: this.loginInfo.usercode || '' })
        console.log('任务看板首页', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('请求失败', error)
      }
    },
    // 实时监测列表
    async getList1 () {
      try {
        const obj = {
          start: '0', // 分页起始
          length: '10', // 分页长度
          ssjgbh: this.loginInfo.jgbh, // 所属机构编号
          xzjdmcid: '', // 乡镇社区id
          cwhmcid: '', // 村街道id
          fwrq: '' // 服务日期
        }
        const { data: res } = await this.$http.get('/ykdssjcs', { params: obj })
        console.log('实时监测列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list1 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 实时监测根据参数获取单条记录
    async getObj1 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-87395172
      try {
        const obj = {
          ssjcid: '', // 监测记录id
          sfzh: '', // 证件号码
          jcsj: '', // 监测时间
          ssjgbh: this.loginInfo.jgbh // 所属机构编号
        }
        const { data: res } = await this.$http.post('/zhyyapi/ykdssjcfind/', obj)
        console.log('实时监测根据参数获取单条记录', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.obj1 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 任务列表
    async getList2 () {
      try {
        const obj = {
          start: '0',
          length: '10',
          xzjdmcid: '',
          cwhmcid: '',
          fwrq: '',
          ssjgbh: this.loginInfo.jgbh,
          type: this.type1
        }
        const { data: res } = await this.$http.get('/ykdssjcs', { params: obj })
        console.log('任务列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list2 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 随访预警列表
    async getList3 () {
      try {
        const obj = {
          start: '0',
          length: '10',
          xzjdmcid: '',
          cwhmcid: '',
          ssjgbh: this.loginInfo.jgbh,
          fwrq: '',
          type: this.type2
        }
        const { data: res } = await this.$http.get('/ykdsfyjs', { params: obj })
        console.log('随访预警列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list3 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 随访预警根据参数获取单条
    async getObj2 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-87407401
      try {
        const obj = {
          sfyjid: '',
          ssjgbh: this.loginInfo.jgbh,
          type: '',
          sfzh: ''
        }
        const { data: res } = await this.$http.post('/zhyyapi/ykdsfyjchafind', obj)
        console.log('随访预警根据参数获取单条', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.obj2 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 血压血糖预警列表
    async getList4 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97499276
      try {
        const obj = {
          start: '0',
          length: '10',
          xzjdmcid: '',
          cwhmcid: '',
          ssjgbh: this.loginInfo.jgbh,
          fwrq: '',
          type: this.type3
        }
        const { data: res } = await this.$http.get('/ykdxyxtyjs', { params: obj })
        console.log('血压血糖预警列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list4 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 血压血糖预警根据参数获取单条
    async getObj3 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97499669
      try {
        const obj = {
          sfyjid: '',
          ssjgbh: this.loginInfo.jgbh,
          type: this.type3,
          sfzh: ''
        }
        const { data: res } = await this.$http.post('/zhyyapi/ykdxyxtyjfind', obj)
        console.log('血压血糖预警根据参数获取单条', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.obj3 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    // 慢病管理血压血糖BMI列表
    async getList5 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-97504739
      try {
        const obj = {
          fwrq: '',
          ssjgbh: this.loginInfo.jgbh,
          type: this.type4,
          xzjdmcid: '',
          cwhmcid: ''
        }
        const { data: res } = await this.$http.post('/zhyyapi/ykdmbgkxyxtfind', obj)
        console.log('慢病管理血压血糖BMI列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.list5 = res.data
      } catch (error) {
        console.log('请求失败', error)
        this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    },
    async getjglb1 () {
      try {
        const { data: res } = await this.$http.post('/api/jkct/getjgbhjb', { ssjgbh: this.loginInfo.jgbh || '', userid: this.loginInfo.usercode || '', jgjb: '3' })
        console.log('机构列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.jglist = res.data
      } catch (error) {
        console.log('机构列表请求失败', error)
      }
    },
    async getjglb2 () {
      try {
        const { data: res } = await this.$http.post('/api/jkct/getjgbhjb', { ssjgbh: this.loginInfo.jgbh || '', userid: this.loginInfo.usercode || '', jgjb: '4' })
        console.log('机构列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.xsjglist = res.data
      } catch (error) {
        console.log('机构列表请求失败', error)
      }
    },
    async getjglb3 () {
      try {
        const { data: res } = await this.$http.post('/api/jkct/getjgbhjb', { ssjgbh: this.loginInfo.jgbh || '', userid: this.loginInfo.usercode || '', jgjb: '5' })
        console.log('机构列表', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.jgpm = res.data
      } catch (error) {
        console.log('机构列表请求失败', error)
      }
    },
    change (e) {
      console.log(e)
      console.log('++++++++++++' + this.info.jgbh)
      console.log(this.jglist.filter(item => item.jgbh === this.value))
      const arr = this.jglist.filter(item => item.jddw === this.value)
      if (arr.length < 1) return
      console.log(arr[0].jddw)
    },
    change2 (e) {
      console.log(this.xsjglist.filter(item => item.jgbh === this.value))
      const arr = this.xsjglist.filter(item => item.jddw === this.value)
      if (arr.length < 1) return
      console.log(arr[0].jddw)
    },
    change3 (e) {
      console.log(this.jgpm.filter(item => item.jgbh === this.value))
      const arr = this.jgpm.filter(item => item.jddw === this.value)
      if (arr.length < 1) return
      console.log(arr[0].jddw)
    }
  }
}
</script>

<style lang="less" scoped>
.sub-page {
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  background-color: #081C38;

  &::-webkit-scrollbar {
    width: 0;
  }

  .sub-top {
    display: flex;
    align-items: center;
    width: 100%;
    height: 70px;
    padding: 0 20px;
    box-sizing: border-box;

    .sub-label {
      position: relative;
      width: 220px;
      height: 70px;
      margin-right: 30px;
      background-size: 100% 100%;
      background-repeat: no-repeat;

      .info {
        position: absolute;
        left: 9px;
        bottom: 6px;
        display: flex;
        flex-direction: column;
        color: #fff;
        font-size: 16px;
      }
    }

    .sub-label1 {
      background-image: url('../../../../assets/img/znjc/label1.png');
    }

    .sub-label2 {
      background-image: url('../../../../assets/img/znjc/label2.png');
    }

    .sub-label3 {
      background-image: url('../../../../assets/img/znjc/label3.png');
    }

    .sub-label4 {
      background-image: url('../../../../assets/img/znjc/label4.png');
    }
  }

  .sub-bottom {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin-top: 10px;
    padding: 0 20px;
    box-sizing: border-box;

    .sub-item {
      width: calc(50% - 20px);
      height: 492px;

      .sub-select {
        width: 100%;
        height: 120px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .sub-select-box {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          height: 100px;
          padding: 10px 20px;
          background-color: #0CDEFF;
          border-radius: 8px;
          box-sizing: border-box;

          .sub-select-title {
            display: flex;
            align-items: center;
            color: #fff;
            font-size: 18px;
            box-sizing: border-box;

            .icon {
              width: 30px;
              height: 30px;
              margin-right: 10px;
            }
          }

          .sub-select-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }
      }

      .sub-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 70px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .sub-title-box {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 40px;
          padding: 0 20px;
          background-color: #01EEFD;
          color: #fff;
          font-size: 22px;
          box-sizing: border-box;
        }
      }

      .sub-title1 {
        align-items: flex-start;
        height: 40px;
        border-bottom: none;
      }

      .sub-tab {
        display: flex;
        align-items: center;
        width: 100%;
        height: 45px;
        padding-left: 25px;
        border-bottom: 1px solid #01EEFD;
        box-sizing: border-box;

        .sub-tab-item {
          margin-right: 24px;
          color: #fff;
          font-size: 14px;
          cursor: pointer;
        }

        .sub-tab-item:last-child {
          margin-right: 0;
        }

        .sub-tab-active {
          color: #01EEFD;
          font-size: 16px;
          border-bottom: 1px solid #01EEFD;
          box-sizing: border-box;
        }
      }

      .sub-charts {
        display: flex;
        height: calc(100% - 100px);
        margin-top: 10px;
        padding: 0 12px;
        box-sizing: border-box;

        .sub-charts-left {
          width: 226px;
          height: 100%;

          .sub-charts-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 90px;
            margin-bottom: 20px;
            padding: 10px;
            background-color: #04B4D0;
            box-sizing: border-box;

            .sub-charts-info {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              white-space: nowrap;
              height: 100%;
              color: #fff;
              font-size: 16px;

              .size {
                font-size: 14px;
                letter-spacing: 0;
              }
            }

            .echarts {
              width: 64px;
              height: 100%;
            }
          }
        }

        .sub-charts-right {
          width: calc(100% - 236px);
          height: 310px;
          margin-left: 10px;
        }
      }

      .el-table {
        margin-top: 20px;
        background-color: transparent;
        border: 4px #073F73 solid;
        color: #01EEFD;
      }

      .el-table::before {
        height: 0;
      }

      .operate {
        display: flex;
        justify-content: space-between;

        span {
          cursor: pointer;
        }
      }

      /deep/.el-table th.el-table__cell {
        background-color: #073F73;
      }

      /deep/.el-table thead {
        color: #01EEFD;
      }

      /deep/.el-table tr {
        background-color: #081C38;
      }

      /deep/.el-table th.el-table__cell.is-leaf {
        border: none;
      }

      /deep/.el-table td.el-table__cell {
        border-color: #01EEFD;
      }

      /deep/.el-table__cell {
        text-align: center;
      }

      /deep/.el-table__body-wrapper {
        &::-webkit-scrollbar {
          width: 0;
        }
      }

      /deep/.el-table__body {
        width: 100% !important;
      }

      /deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
        background-color: transparent;
      }

      /deep/.el-table colgroup col[name='gutter'] {
        display: none;
        width: 0;
      }

      /deep/ .el-table th.gutter {
        display: none;
        width: 0;
      }
    }

    .sub-item:first-child,
    .sub-item:nth-child(3) {
      margin-right: 20px;
    }
  }

}
</style>
