<template>
  <div class="ncd">
    <div class="tab">
      <div class="item" v-for="(item, index) in tabs" :key="index" @click="tabClick(index)">
        <span class="item-top" :class="{ 'item-top1': tabIndex === index }"></span>
        <span class="item-bottom" :class="{ 'item-bottom1': tabIndex === index }">{{ item }}</span>
      </div>
    </div>
    <div class="box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['患者管理', '数据管理', '门诊管理', '智能提醒', '质控管理', '设备管理', '系统管理']),
      routerName: 'routerName'
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
      this.routerName = this.$route.path.split('/')[1]
      console.log(this.$route.path)
      const urls = ['/patientrun', '/inforun', '/outpatientrun', '/smartreminder', '/controlrun', '/devicerun', '/systemrun']
      this.$router.push('/' + this.routerName + urls[index])
    }
  },
  created () {
    this.routerName = this.$route.path
      .split('/')[1]
  }
}
</script>

<style lang="less" scoped>
.ncd {
  width: 100%;
  height: 99%;
  padding: 0 20px;
  box-sizing: border-box;

  .tab {
    display: flex;
    width: 100%;
    height: 50px;

    .item {
      display: flex;
      flex-direction: column;
      width:137px;
      height: 50px;
      margin-right: 40px;
      cursor: pointer;

      .item-top {
        position: relative;
        width: 84px;
        height: 10px;
        background-color: rgba(3, 188, 215, .22);

        &::after {
          position: absolute;
          top: -7px;
          left: -7.2px;
          z-index: 8;
          content: '';
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-right-color: #000;
          transform: rotate(45deg);
        }

        &::before {
          position: absolute;
          top: -7px;
          right: -7.2px;
          z-index: 8;
          content: '';
          width: 0;
          height: 0;
          border: 7px solid transparent;
          border-left-color: #000;
          transform: rotate(-45deg);
        }
      }

      .item-top1 {
        background-color: #03BCD7;
      }

      .item-bottom {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        font-size: 18px;
        background: linear-gradient(107deg, rgba(1, 118, 204, .22) 0%, rgba(2, 86, 151, 0.22) 60%, rgba(2, 86, 151, .22) 60%, rgba(2, 64, 115, .22) 100%);
        border: 1px solid rgba(68, 215, 182, .22);
        box-sizing: border-box;
      }

      .item-bottom1 {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        border: 1px solid #44D7B6;
      }
    }
  }

  .box {
    width: 100%;
    height: calc(100% - 66px);
    margin-top: 16px;
    background-color: #08223E;
    border: 4px solid rgba(1, 124, 219, .27);
    box-sizing: border-box;
  }
}
</style>
