<template>
  <div class="referral">
    <div class="head">
      <el-button class="blue">定位设备</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search" v-model="sfzh">
      </el-input>
    </div>
    <div class="input">
      <div class="item">
        <el-input placeholder="姓名或编号" v-model="params.name">
        </el-input>
      </div>
      <div class="item">
        <el-input placeholder="请输入联系方式" v-model="params.lxdh">
        </el-input>
      </div>
      <div class="item">
        <el-input placeholder="SIM卡号" v-model="params.sim">
        </el-input>
      </div>
      <div class="item">
        <el-input placeholder="设备编号" v-model="params.sbbh">
        </el-input>
      </div>
      <div class="item">
        <el-select v-model="value" placeholder="设备厂家">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <span class="span">绑定</span>
        <el-select v-model="value" placeholder="">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-button @click="getList1" class="blue width">搜索</el-button>
      <el-button class="blue" @click="openAdd">添加设备</el-button>
    </div>
    <div class="swich">
      <el-table :data="showList1" max-height="456" :header-cell-style="{ background: '#024276' }">
        <el-table-column type="selection" width="50">
        </el-table-column>
        <el-table-column prop="name" label="长者编号">
        </el-table-column>
        <el-table-column prop="date" label="姓名">
        </el-table-column>
        <el-table-column prop="date" label="联系方式">
        </el-table-column>
        <el-table-column prop="type" label="设备名称">
        </el-table-column>
        <el-table-column prop="type1" label="设备厂家">
        </el-table-column>
        <el-table-column prop="type2" label="SIM卡">
        </el-table-column>
        <el-table-column prop="type" label="设备编号">
        </el-table-column>
        <el-table-column label="操作" width="260">
          <template>
            <div class="flex">
              <span @click="openSee">查看</span>
              <span @click="openUpdate">修改</span>
              <span @click="openElectronicFence">围栏设置</span>
              <span @click="openBind">解除绑定</span>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="query1.pageNum" background :page-size="query1.pageSize"
          layout="total, prev, pager, next, jumper" :total="query1.total">
        </el-pagination>
      </div>
    </div>
    <!-- 新增 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 查看 -->
    <See v-if="showSee" @close="closeSee"></See>
    <!-- 修改 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 围栏设置 -->
    <ElectronicFence v-if="showElectronicFence" @close="closeElectronicFence"></ElectronicFence>
    <!-- 绑定 -->
    <Bind v-if="showBind" @close="closeBind"></Bind>
  </div>
</template>

<script>
import Add from './add.vue'
import See from './see.vue'
import Update from './update.vue'
import ElectronicFence from './electronicFence.vue'
import Bind from './bind.vue'
export default {
  components: {
    Add,
    See,
    Update,
    ElectronicFence,
    Bind
  },
  data () {
    return {
      tableData: [],
      tabIndex: 0,
      options: [],
      value: '',
      showAdd: false,
      showSee: false,
      showUpdate: false,
      showBind: false,
      showElectronicFence: false,
      list1: [],
      showList1: [],
      // 分页数据
      query1: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      },
      sfzh: '',
      params: {
        name: '',
        lxdh: '',
        sim: '',
        sbbh: ''
      }
    }
  },
  created () {
    this.getList1()
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openElectronicFence () {
      this.showElectronicFence = true
    },
    closeElectronicFence () {
      this.showElectronicFence = false
    },
    openBind () {
      this.showBind = true
    },
    closeBind () {
      this.showBind = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
      this.query1.pageNum = val
      this.getList1()
    },
    // 定位设备
    async getList1 () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-86131147
      try {
        this.showList1 = []
        const start = this.query1.pageNum === 1 ? '10' : (this.query1.pageNum - 1) * 10 + 1 + ''
        const obj = {
          start: start,
          length: '10'
        }
        const { data: res } = await this.$http.get('/zhyyapi/ykddwsblist', { params: obj })
        console.log('定位设备', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.showList1 = res.data
      } catch (error) {
        console.log('定位设备请求失败', error)
        // this.$message.error(error.response.data.msg || '系统异常，请稍后重试')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.referral {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 10px 37px;
  background: linear-gradient(114deg, rgba(10, 71, 123, .27) 0%, rgba(17, 94, 156, .27) 100%);
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .input {
    margin-bottom: 20px;
  }

  .head,
  .input {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-select {
      width: 170px;
      background-color: #fff;
    }

    .el-button {
      width: 152px;
      height: 38px;
      line-height: 38px;
      padding: 0;
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      background-color: #284867;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .color {
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;
      border-radius: 6px;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff;
        font-size: 18px;
        border: none;

      }

      /deep/ .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }

      /deep/ .el-icon-search {
        display: flex;
        align-items: center;
      }
    }

  }

  .input {
    .item {
      display: flex;
      align-items: center;
      width: 180px;
      margin-right: 10px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        white-space: nowrap;
        margin-right: 10px;
      }

      .el-input {
        height: 38px;
        line-height: 38px;
        background-color: #fff;

        /deep/.el-input__inner {
          height: 38px;
          line-height: 38px;
        }
      }
    }
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
