<template>
  <div class="tab">
    <div class="tab-left">
      <div class="tab-add">
        <span>方案记录</span>
        <el-button type="text">
          <span class="el-icon-plus"></span>
          <span>新增</span>
        </el-button>
      </div>
      <div class="tab-scroll">
        <div v-for="(item, index) in sons" :key="index" :class="{'tab-active': index === sonIndex}" @click="sonClick(index)" class="tab-item">
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div class="tab-right">
      <el-descriptions :column="2" title="基本信息">
        <el-descriptions-item label="最近一次诊断血压">150/90mmHg</el-descriptions-item>
        <el-descriptions-item label="血压分级">Ⅱ级</el-descriptions-item>
        <el-descriptions-item label="当前分层结果">中危</el-descriptions-item>
        <el-descriptions-item label="管理分级">Ⅱ级</el-descriptions-item>
      </el-descriptions>
      <div class="tab-title">
        <span>控制目标</span>
      </div>
      <table border borderColor="gray" :cellspacing="0" :cellpadding="0" class="tab-table">
        <tr class="tab-tr">
          <td class="tab-td">指标</td>
          <td class="tab-td">当前值</td>
          <td class="tab-td">目标限制值</td>
          <td class="tab-td">单位</td>
        </tr>
        <tr class="tab-tr">
          <td class="tab-td">血压</td>
          <td class="tab-td">
            <el-input-number v-model="info.ssy" controls-position="right" :min="0"></el-input-number>
            <span>/</span>
            <el-input-number v-model="info.szy" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">
            <el-input-number v-model="info.ssy" controls-position="right" :min="0"></el-input-number>
            <span>~</span>
            <el-input-number v-model="info.szy" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">mmHg</td>
        </tr>
        <tr class="tab-tr">
          <td class="tab-td">空腹血糖值</td>
          <td class="tab-td">
            <el-input-number v-model="info.kfxtz" :step=".1" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">
            <el-input-number v-model="info.kfxtzz" controls-position="right" :min="0"></el-input-number>
            <span>~</span>
            <el-input-number v-model="info.kfxtzy" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">mmol/L</td>
        </tr>
        <tr class="tab-tr">
          <td class="tab-td">体重</td>
          <td class="tab-td">
            <el-input-number v-model="info.tz" :step=".1" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">
            <el-input-number v-model="info.tzz" controls-position="right" :min="0"></el-input-number>
            <span>~</span>
            <el-input-number v-model="info.tzy" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">kg</td>
        </tr>
        <tr class="tab-tr">
          <td class="tab-td">日吸烟量</td>
          <td class="tab-td">
            <el-input-number v-model="info.rxyl" :step=".1" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">
            <el-input-number v-model="info.rxylz" controls-position="right" :min="0"></el-input-number>
            <span>~</span>
            <el-input-number v-model="info.rxyly" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">支</td>
        </tr>
        <tr class="tab-tr">
          <td class="tab-td">日饮酒量</td>
          <td class="tab-td">
            <el-input-number v-model="info.ryjl" :step=".1" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">
            <el-input-number v-model="info.ryjlz" controls-position="right" :min="0"></el-input-number>
            <span>~</span>
            <el-input-number v-model="info.ryjly" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">两</td>
        </tr>
        <tr class="tab-tr">
          <td class="tab-td">摄盐量</td>
          <td class="tab-td">
            <el-input-number v-model="info.syl" :step=".1" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">
            <el-input-number v-model="info.sylz" controls-position="right" :min="0"></el-input-number>
            <span>~</span>
            <el-input-number v-model="info.syly" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">g/天</td>
        </tr>
        <tr class="tab-tr">
          <td class="tab-td">BMI</td>
          <td class="tab-td">
            <el-input-number v-model="info.bmi" :step=".1" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">
            <el-input-number v-model="info.bmiz" controls-position="right" :min="0"></el-input-number>
            <span>~</span>
            <el-input-number v-model="info.bmiy" controls-position="right" :min="0"></el-input-number>
          </td>
          <td class="tab-td">kg/m²</td>
        </tr>
      </table>
      <div class="tab-title">
        <span>饮食指导</span>
        <el-select v-model="info.yszd" placeholder="请选择">
          <el-option
            v-for="item in yszds"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-input type="textarea" placeholder="请输入内容" v-model="info.yszdsr"></el-input>
      <div class="tab-title">
        <span>运动指导</span>
        <el-select v-model="info.ydzd" placeholder="请选择">
          <el-option
            v-for="item in yszds"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-input type="textarea" placeholder="请输入内容" v-model="info.ydzdsr"></el-input>
      <div class="tab-title">
        <span>心理指导</span>
        <el-select v-model="info.xlzd" placeholder="请选择">
          <el-option
            v-for="item in yszds"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-input type="textarea" placeholder="请输入内容" v-model="info.xlzdsr"></el-input>
      <div class="tab-button">
        <el-button type="primary">保存</el-button>
        <el-button type="success">打印</el-button>
        <el-button type="success">推送</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      sonIndex: 0,
      sons: ['2024-03-02', '2024-03-02', '2024-03-02'],
      yszds: [],
      ydzds: [],
      xlzds: [],
      info: {
        ssy: '',
        szy: '',
        ssymb: '',
        szymb: '',
        kfxtz: '',
        kfxtzz: '',
        kfxtzy: '',
        tz: '',
        tzz: '',
        tzy: '',
        rxyl: '',
        rxylz: '',
        rxyly: '',
        ryjl: '',
        ryjlz: '',
        ryjly: '',
        syl: '',
        sylz: '',
        syly: '',
        bmi: '',
        bmiz: '',
        bmiy: '',
        yszd: '',
        yszdsr: '',
        ydzd: '',
        ydzdsr: '',
        xlzd: '',
        xlzdsr: ''
      }
    }
  },
  methods: {
    sonClick (index) {
      if (index === this.sonIndex) return
      this.sonIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
  .tab {
    display: flex;
    width: 100%;
    height: 100%;

    .tab-left {
      width: 190px;
      height: 100%;
      padding-left: 5px;
      padding-right: 5px;
      border-right: 1px solid #f2f7fb;
      box-sizing: border-box;

      .tab-add {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        font-size: 16px;
      }

      .tab-scroll {
        overflow: hidden scroll;
        width: 100%;
        height: calc(100% - 50px);

        &::-webkit-scrollbar {
          width: 0;
        }
      }

      .tab-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        border-bottom: 1px solid #f2f7fb;
        box-sizing: border-box;
        color: #000;
        font-size: 14px;
        cursor: pointer;
      }

      .tab-active {
        border-color: rgba(0, 0, 255, 1);
      }
    }

    .tab-right {
      overflow: hidden scroll;
      width: calc(100% - 190px);
      height: 100%;
      padding: 40px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .tab-title {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 10px;
        font-size: 16px;

        .el-select {
          margin-left: 10px;
        }
      }

      .tab-table {
        display: flex;
        flex-direction: column;
        width: 90%;

        .tab-tr {
          display: flex;
          align-items: center;
          width: 100%;

          .tab-td {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 25%;
            height: 50px;
            font-size: 14px;

            .el-input-number {
              width: 40%;
            }
          }
        }

        .tab-tr:first-child {
          background-color: #e4e4e4;
        }
      }

      .el-textarea {
        width: 50%;
        height: 100px;
        margin-top: 20px;
        margin-left: 70px;
      }

      /deep/.el-textarea__inner {
        width: 100%;
        height: 100%;
        resize: none;
      }

      .tab-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 80px;
      }
    }
  }
</style>
