<template>
  <div class="page">
    <div class="switch">
      <span class="item" :class="{ item1: switchIndex === index }" v-for="(item, index) in switchs" :key="index"
        @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="content" v-if="switchIndex === 0">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input placeholder="设备SN号试纸批次" suffix-icon="el-icon-search">
            </el-input>
            <el-date-picker type="date" class="date" placeholder="选择时间">
            </el-date-picker>
            <el-select v-model="value" placeholder="结果">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
          <div class="right">
            <el-button size="small" class="blue">打印/下载</el-button>
            <div class="add" @click="openAddAccuse">
              <span class="el-icon-plus"></span>
            </div>
          </div>
        </div>
        <el-table :data="tableData" height="206" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="质控日期">
          </el-table-column>
          <el-table-column prop="date" label="设备SN号">
          </el-table-column>
          <el-table-column prop="date" label="试纸批次号">
          </el-table-column>
          <el-table-column prop="type" label="质控液类型">
          </el-table-column>
          <el-table-column prop="type1" label="质控结果(mmol/L)">
          </el-table-column>
          <el-table-column prop="type1" label="质控范围(mmol/L)">
          </el-table-column>
          <el-table-column prop="type1" label="操作人">
          </el-table-column>
          <el-table-column prop="type1" label="测试结果">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex">
                <span @click="openUpdateAccuse">编辑</span>
                <span @click="openDeleteRecord">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 1">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input placeholder="试纸批号">
            </el-input>
            <el-date-picker type="date" class="date" placeholder="登记时间">
            </el-date-picker>
            <el-select v-model="value" placeholder="全部试纸品牌">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="全部登记人">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
          <div class="right">
            <div class="add" @click="openAddPaper">
              <span class="el-icon-plus"></span>
            </div>
          </div>
        </div>
        <el-table :data="tableData" height="206" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="登记时间">
          </el-table-column>
          <el-table-column prop="date" label="试纸品牌">
          </el-table-column>
          <el-table-column prop="date" label="试纸批号">
          </el-table-column>
          <el-table-column prop="type" label="质控液low参考范围">
          </el-table-column>
          <el-table-column prop="type1" label="质控液Normal参考范围">
          </el-table-column>
          <el-table-column prop="type1" label="质控范围(mmol/L)">
          </el-table-column>
          <el-table-column prop="type1" label="质控液High参考范围">
          </el-table-column>
          <el-table-column prop="type1" label="试纸有效期">
          </el-table-column>
          <el-table-column prop="type1" label="状态">
          </el-table-column>
          <el-table-column prop="type1" label="登记人">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 2">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-date-picker type="date" class="date" placeholder="登记时间">
            </el-date-picker>
            <el-input placeholder="质控液批号">
            </el-input>
            <el-select v-model="value" placeholder="全部质控液品牌">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="质控液类型">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="全部登记人">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
          <div class="right">
            <div class="add" @click="openAddControlSolution">
              <span class="el-icon-plus"></span>
            </div>
          </div>
        </div>
        <el-table :data="tableData" height="206" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="登记时间">
          </el-table-column>
          <el-table-column prop="date" label="质控液品牌">
          </el-table-column>
          <el-table-column prop="date" label="质控液类型">
          </el-table-column>
          <el-table-column prop="type1" label="质控液批号">
          </el-table-column>
          <el-table-column prop="type" label="质控有效期">
          </el-table-column>
          <el-table-column prop="type1" label="状态">
          </el-table-column>
          <el-table-column prop="type1" label="登记人">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 添加质控 -->
    <AddAccuse v-if="showAddAccuse" @close="closeAddAccuse"></AddAccuse>
    <!-- 编辑质控 -->
    <UpdateAccuse v-if="showUpdateAccuse" @close="closeUpdateAccuse"></UpdateAccuse>
    <!-- 删除记录 -->
    <DeleteRecord v-if="showDeleteRecord" @close="closeDeleteRecord"></DeleteRecord>
    <!-- 添加试纸 -->
    <AddPaper v-if="showAddPaper" @close="closeAddPaper"></AddPaper>
    <!-- 添加质控液 -->
    <AddControlSolution v-if="showAddControlSolution" @close="closeAddControlSolution"></AddControlSolution>
  </div>
</template>

<script>
import AddAccuse from './addAccuse.vue'
import UpdateAccuse from './updateAccuse.vue'
import DeleteRecord from './deleteRecord.vue'
import AddPaper from './addPaper.vue'
import AddControlSolution from './addControlSolution.vue'
export default {
  components: {
    AddAccuse,
    UpdateAccuse,
    DeleteRecord,
    AddPaper,
    AddControlSolution
  },
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['质控记录', '试纸批次管理', '质控液备案']),
      tableData: [
        { name: '1111' }
      ],
      value: '',
      options: [{
        value: '',
        label: ''
      }],
      showAddAccuse: false,
      showUpdateAccuse: false,
      showDeleteRecord: false,
      showAddPaper: false,
      showAddControlSolution: false
    }
  },
  methods: {
    switchClick (index) {
      if (index === this.switchIndex) return
      this.switchIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openAddAccuse () {
      this.showAddAccuse = true
    },
    closeAddAccuse () {
      this.showAddAccuse = false
    },
    openUpdateAccuse () {
      this.showUpdateAccuse = true
    },
    closeUpdateAccuse () {
      this.showUpdateAccuse = false
    },
    openDeleteRecord () {
      this.showDeleteRecord = true
    },
    closeDeleteRecord () {
      this.showDeleteRecord = false
    },
    openAddPaper () {
      this.showAddPaper = true
    },
    closeAddPaper () {
      this.showAddPaper = false
    },
    openAddControlSolution () {
      this.showAddControlSolution = true
    },
    closeAddControlSolution () {
      this.showAddControlSolution = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 20px;
  box-sizing: border-box;

  .switch {
    display: flex;
    width: 100%;
    height: 40px;

    .item {
      height: 40px;
      line-height: 40px;
      margin-right: 20px;
      padding: 0 10px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - 50px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .line {
      width: 100%;
      padding: 20px;
      border: 4px solid #073F73;
      box-sizing: border-box;
      border-bottom: none;

      .title {
        color: #01EEFD;
        font-size: 20px;
      }

      .count {
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        margin-top: 10px;

        .count-item {
          display: flex;
          align-items: center;
          width: 360px;
          height: 40px;
          margin-right: 20px;
          background: linear-gradient(79deg, #0A477B 0%, #0E67AD 100%);
          color: #fff;
          font-size: 18px;

          img {
            width: 30px;
            height: 30px;
            margin: 0 20px;
          }
        }
      }

      .echarts {
        width: 398px;
        height: 330px;
      }

      .echarts1 {
        width: 40%;
        height: 330px;
      }

      .echarts2 {
        width: 100%;
        height: 395px;
      }

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 10px;

        .width {
          width: 246px;
        }

        .el-select {
          width: 120px;
        }
      }
    }

    .line1 {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0px;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      background-color: #092B50;
      border: 4px #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: 17px;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: 2px solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: 1px solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: 1px solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: 1px solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    ::v-deep .el-table__empty-text {
      color: #01EEFD;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 40px;

      ::v-deep.el-pagination__total,
      ::v-deep.el-pagination__jump {
        color: #01EEFD;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #01EEFD;
      font-size: 15px;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      justify-content: center;
    }

    .content-box {
      width: 100%;
      height: 100%;
      padding: 20px;
      border: 4px solid #073F73;
      box-sizing: border-box;

      .content-box-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 260px;
          }

          .el-select,
          .el-input {
            width: 200px;
            margin-left: 10px;
          }

          .date {
            margin-left: 10px;
          }

          .blue,
          .gray {
            margin-left: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            margin-right: 10px;
            background-color: #284867;
          }
        }

        .right {
          display: flex;
          align-items: center;

          .add {
            width: 30px;
            height: 30px;
            line-height: 30px;
            text-align: center;
            border-radius: 50%;
            background-color: #04B4D0;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            cursor: pointer;
          }

          .blue {
            margin-right: 40px;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }
        }
      }
    }
  }
}
</style>
