<template>
  <div class="report">
    <div class="report-close" @click="close">
      <span class="icon el-icon-close"></span>
    </div>
    <div class="report-top">
      <div class="title">
        <span>门诊会诊</span>
      </div>
    </div>
    <div class="report-bottom">
      <div class="report-left">
        <div class="info">
          <div class="info-left">
            <img src="../../img/emr/user.png" class="info-img">
          </div>
          <div class="info-right">
            <span>王丽霞</span>
            <span class="sex">女</span>
            <span>44岁3月</span>
            <span>202111606032</span>
          </div>
        </div>
        <div class="list">
          <div class="head">
            <div class="select active">
              <span>今日</span>
            </div>
            <div class="select">
              <span>近7日</span>
            </div>
            <div class="select">
              <span>近3月</span>
            </div>
            <el-select v-model="value" placeholder="请选择" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="scroll">
            <div class="item">
              <div class="line"></div>
              <div class="round round-active"></div>
              <div class="card card-active">
                <div class="card-bottom">
                  <span>2021-05-22 08:55 </span>
                  <span class="status">已结束</span>
                </div>
                <div class="card-top">
                  <span>李明明主任【喉咙专家】</span>
                </div>
                <div class="card-top">
                  <span>中医院</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="line"></div>
              <div class="round"></div>
              <div class="card">
                <div class="card-bottom">
                  <span>2021-05-22 08:55 </span>
                  <span class="status">已结束</span>
                </div>
                <div class="card-top">
                  <span>李明明主任【喉咙专家】</span>
                </div>
                <div class="card-top">
                  <span>中医院</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-right">
        <div class="report-scroll">
          <div class="report-scroll-title">
            <span>邀请会诊医院</span>
          </div>
          <div class="report-scroll-list report-scroll-list1">
            <el-select v-model="value" placeholder="请输入选择会诊医院" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-radio-group v-model="radio">
              <el-radio label="0">普通会诊</el-radio>
              <el-radio label="1">急诊</el-radio>
            </el-radio-group>
          </div>
          <div class="report-scroll-title">
            <span>病历摘要</span>
          </div>
          <div class="report-scroll-list">
            <div class="key">
              <span>门诊诊断</span>
            </div>
            <el-select class="select1" v-model="value" placeholder="">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="report-scroll-list">
            <div class="key">
              <span>备注</span>
            </div>
            <el-select class="select1" v-model="value" placeholder="">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="report-scroll-list">
            <div class="key">
              <span>会诊目的</span>
            </div>
            <div class="value">
              <el-upload class="avatar-uploader" action="/" :show-file-list="false" :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </div>
          <div class="report-scroll-list">
            <div class="key">
              <span>会诊目的</span>
            </div>
            <el-select class="select1" v-model="value" placeholder="">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="report-scroll-title">
            <span>需会诊医院</span>
          </div>
          <div class="report-scroll-text">
            <span>申请单位：健康无忧人民医院</span>
          </div>
          <div class="report-scroll-text">
            <span>申请医院：叶谓之</span>
          </div>
          <div class="report-scroll-text">
            <span>联系方式：15808888838</span>
          </div>
        </div>
        <div class="report-btns">
          <el-button size="small" @click="close">取消</el-button>
          <el-button size="small" class="blue">提交会诊申请</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectIndex: 0,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      radio: '',
      imageUrl: ''
    }
  },
  methods: {
    handleAvatarSuccess (res, file) {
      this.imageUrl = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload (file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.report {
  position: absolute;
  top: 24px;
  right: 110px;
  z-index: 88;
  width: 740px;
  height: 740px;
  padding: 14px 20px;
  background-color: #fff;
  box-shadow: -3px 2px 6px 1px rgba(0, 0, 0, 0.1608);
  border-radius: 6px;
  box-sizing: border-box;

  .report-close {
    position: absolute;
    top: 8px;
    right: 14px;
    z-index: 888;
    color: #707070;
    font-size: 26px;
    cursor: pointer;
  }

  .report-top {
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    border-bottom: 1px solid #A1A6BB;
    box-sizing: border-box;

    .title {
      color: #666666;
      font-size: 14px;
    }
  }

  .report-bottom {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 30px);

    .report-left {
      width: 264px;
      height: 100%;
      border-right: 1px solid #A1A6BB;
      box-sizing: border-box;

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 90px;
        margin: 10px 0;
        padding: 9px 15px;
        background-color: #81ACD3;
        box-sizing: border-box;

        .info-left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 69px;
          height: 69px;
          border-radius: 50%;
          background-color: #fff;

          .info-img {
            width: 60px;
            height: 60px;
          }
        }

        .info-right {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: calc(100% - 75px);
          color: #333333;
          font-size: 16px;

          .sex {
            color: #FF00F7;
          }
        }
      }

      .list {
        width: 100%;
        height: calc(100% - 110px);
        border-top: 1px solid #A1A6BB;
        box-sizing: border-box;

        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 50px;
          padding-right: 16px;
          box-sizing: border-box;

          .select {
            white-space: nowrap;
            color: #3E3E3E;
            font-size: 14px;
            cursor: pointer;
          }

          .active {
            color: #1269B7;
          }

          .el-select {
            width: 100px;
          }
        }

        .scroll {
          width: calc(100% - 8px);
          height: calc(100% - 50px);

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 70px;
            margin-bottom: 10px;

            .line {
              width: 15px;
              height: 1px;
              background-color: #A1A6BB;
            }

            .round {
              width: 14px;
              height: 14px;
              background-color: #666666;
              border-radius: 50%;
            }

            .round-active {
              background-color: #1269B7;
            }

            .card {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: calc(100% - 35px);
              height: 100%;
              margin-left: 4px;
              padding: 7px 5px;
              background-color: #D9D9D9;
              border-radius: 6px;
              box-sizing: border-box;
              cursor: pointer;

              .card-top {
                color: #333333;
                font-size: 14px;
              }

              .card-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #666666;
                font-size: 12px;

                .status {
                  // color: #FF9200;
                }
              }
            }

            .card-active {
              background-color: #79B3E7;

              .card-top {
                color: #1269B7;
              }

              .card-bottom {
                .status {
                  color: #1269B7;
                }
              }
            }
          }
        }
      }
    }

    .report-right {
      width: calc(100% - 264px);
      height: 100%;
      padding: 10px;
      box-sizing: border-box;

      .report-scroll {
        width: 100%;
        height: calc(100% - 50px);

        .report-scroll-title {
          height: 40px;
          line-height: 40px;
          color: #3E3E3E;
          font-size: 14px;
        }

        .report-scroll-list {
          display: flex;
          justify-content: space-between;
          margin-bottom: 10px;

          /deep/.el-radio__label {
            font-size: 14px;
          }

          .key {
            width: 70px;
            color: #3E3E3E;
            font-size: 14px;
          }

          .select1 {
            width: calc(100% - 80px);

            /deep/.el-input__inner {
              height: 60px;
            }
          }

          .value {
            width: calc(100% - 80px);
            height: 120px;

            /deep/.avatar-uploader .el-upload {
              border: 1px solid #D8DAE3;
              border-radius: 6px;
              cursor: pointer;
              position: relative;
              overflow: hidden;
            }

            .avatar-uploader .el-upload:hover {
              border-color: #409EFF;
            }

            .avatar-uploader-icon {
              font-size: 28px;
              color: #8c939d;
              width: 120px;
              height: 120;
              line-height: 120px;
              text-align: center;
            }

            .avatar {
              width: 178px;
              height: 178px;
              display: block;
            }
          }
        }

        .report-scroll-list1 {
          align-items: center;
        }

        .report-scroll-text {
          margin-bottom: 10px;
          color: #3E3E3E;
          font-size: 14px;
        }
      }

      .report-btns {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        height: 50px;

        .el-button {
          min-width: 100px;
          border: 1px solid #1269B7;
          color: #1269B7;
        }

        .blue {
          background-color: #1269B7;
          color: #fff;
        }
      }
    }
  }
}</style>
