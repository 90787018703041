<template>
  <div class="write">
    <div class="title">
      <span>新增设备</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">序号</span>
          <el-input placeholder="" v-model="info.xh"></el-input>
        </div>
        <div class="item">
          <span class="span">姓名</span>
          <el-input placeholder="" v-model="info.name"></el-input>
        </div>
        <div class="item">
          <span class="span">身份证号</span>
          <el-input placeholder="" v-model="info.sfzh"></el-input>
        </div>
        <div class="item">
          <span class="span">性别</span>
          <el-input placeholder="" v-model="info.sex"></el-input>
        </div>

        <div class="item">
          <span class="span">年龄</span>
          <el-input placeholder="" v-model="info.age"></el-input>
        </div>
        <div class="item">
          <span class="span">住址</span>
          <el-input placeholder="" v-model="info.zz"></el-input>
        </div>
        <div class="item">
          <span class="span">人群分类</span>
          <el-select v-model="info.rqfl" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">养老专属编号</span>
          <el-input placeholder="" v-model="info.zsbh"></el-input>
        </div>

        <div class="item">
          <span class="span">所属机构乡镇/社区</span>
          <el-select v-model="info.xzsq" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">所属机构街道/村</span>
          <el-select v-model="info.jdc" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">服务等级</span>
          <el-select v-model="info.fwdj" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">护理等级</span>
          <el-select v-model="info.hldj" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="item">
          <span class="span">入住时间</span>
          <el-input placeholder="" v-model="info.rzsj" ></el-input>
        </div>
        <div class="item">
          <span class="span">设备编号</span>
          <el-input placeholder="" v-model="info.sbbh"></el-input>
        </div>
        <div class="item">
          <span class="span">家属联系人电话</span>
          <el-input placeholder="" v-model="info.lxrdh"></el-input>
        </div>
        <div class="item">
          <span class="span">家属姓名</span>
          <el-input placeholder="" v-model="info.jsxm"></el-input>
        </div>

        <div class="item">
          <span class="span">与其关系</span>
          <el-select v-model="info.yqgx" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">本人电话</span>
          <el-input placeholder="" v-model="info.brdh"></el-input>
        </div>
        <div class="item">
          <span class="span">服务人员</span>
          <el-select v-model="info.fwry" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">专属服务</span>
          <el-select v-model="info.zsfw" placeholder="">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="item">
          <span class="span">入住科室</span>
          <el-select v-model="info.rzks" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">入住病区</span>
          <el-select v-model="info.rzbq" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">是否独居</span>
          <el-radio-group v-model="info.sfdj">
            <el-radio label="1">是</el-radio>
            <el-radio label="2">否</el-radio>
          </el-radio-group>
        </div>
        <div class="item"></div>
      </div>
      <div class="btns">
        <el-button>
          <span class="el-icon-bank-card"></span>
          <span>读身份证</span>
        </el-button>
        <el-button @click="close">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      num: 1,
      radio: '1',
      options: [],
      value: '',
      info: {
        xh: '',
        name: '',
        sfzh: '',
        sex: '',
        age: '',
        zz: '',
        rqfl: '',
        zsbh: '',
        xzsq: '',
        jdc: '',
        fwdj: '',
        hldj: '',
        rzsj: '',
        sbbh: '',
        lxrdh: '',
        jsxm: '',
        yqgx: '',
        brdh: '',
        fwry: '',
        zsfw: '',
        rzks: '',
        rzbq: '',
        sfdj: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 100px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 167px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    height: 631px;
    padding: 50px 65px;
    background-image: url('../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 24px;
      right: 30px;
      color: #fff;
      font-size: 28px;
      cursor: pointer;
    }

    .content-title {
      margin-top: 20px;
      color: #01EEFD;
      font-size: 18px;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;

      flex-wrap: wrap;
      width: 100%;

      .tab {
        display: flex;
        width: 100%;
        height: 38px;

        .tab-item {
          width: 90px;
          height: 38px;
          line-height: 38px;
          margin-right: 30px;
          text-align: center;
          background-color: #616B73;
          color: #fff;
          font-size: 16px;
          cursor: pointer;
        }

        .tab-item:last-child {
          margin-right: 0;
        }

        .tab-active {
          background-color: #04B4D0;
        }
      }
    }

    .item {
      position: relative;
      display: flex;
      align-items: center;
      width: 25%;
      margin-top: 30px;
      color: #01EEFD;
      font-size: 18px;

      .span {
        margin-right: 10px;
        white-space: nowrap;
      }

      .span1 {
        width: 108px;
      }

      .el-input, .el-select {
        width: 167px;
        height: 38px;
      }
    }

    .btns {
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 400px;
      height: 46px;
      margin: 0 auto;

      .el-button {
        width: 150px;
        height: 30px;
        line-height: 30px;
        padding: 0 10px;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #1E58A6;
        border-radius: 8px;
        border: none;
      }
    }
  }

  .el-radio-group {
    margin-top: 6px;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio {
    color: #fff;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label {
    color: #01EEFD;
  }

  .el-select {
    width: calc(100% - 200px);
  }

  .el-input-number {
    width: 90px;
  }

  .width {
    width: 94px;
    margin-left: 30px;
  }

  /deep/.el-textarea__inner {
    resize: none;
  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 0px;
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  .el-table {
    background-color: #092B50;
    border: 4px #073F73 solid;
  }

  // 表头文字颜色
  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  //表格内容颜色
  /deep/.el-table__body tbody tr {
    color: #fff;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  // 改变边框颜色
  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  /**
  改变表格内竖线颜色
   */
  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: 1px solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: 1px solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: transparent;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: 1px solid #073F73 !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: #0e75d4;
    font-size: 15px;

    span {
      cursor: pointer;
    }
  }

  .flex1 {
    justify-content: center;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }

}
</style>
