<template>
  <div class="write">
    <div class="title">
      <span>设备绑定</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="item">
        <span class="span">GPRS设备</span>
        <el-input></el-input>
        <el-button size="small" class="button">绑定</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      value: ''
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 3.75rem;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: 3.75rem;
    margin-top: .125rem;
    padding: .625rem .8125rem;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: .1rem;
      right: .3rem;
      color: #fff;
      font-size: .25rem;
      cursor: pointer;
    }

    .item {
      display: flex;
      align-items: center;
      margin-top: .125rem;
      color: #01EEFD;
      font-size: .2rem;

      .span {
        text-align-last: justify;
        margin-right: .125rem;
        white-space: nowrap;
      }

      .el-input {
        width: 3.75rem;
      }

      .el-button {
        margin-left: .25rem;
        background-color: #33465C;
        border-radius: 0;
        border: none;
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        text-align: center;
        color: #fff;
        font-size: .175rem;
      }
    }
  }
}
</style>
