<template>
  <div class="children-page">
    <div class="head">
      <el-button size="small" class="blue">肺结核随访</el-button>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="input input1">
      <div class="item">
        <div class="key">
          <span>管辖范围</span>
        </div>
        <el-select v-model="value" placeholder="我管辖的档案">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="item">
        <div class="key">
          <span>个人档案编号</span>
        </div>
        <el-input v-model="grdabh"></el-input>
        <el-select v-model="value" placeholder="医疗机构" class="margin">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <el-radio v-model="radio" label="1">只查本级</el-radio>
      <div class="item">
        <div class="key">
          <span>建档日期范围</span>
        </div>
        <el-date-picker v-model="value1" type="daterange" range-separator="至" start-placeholder="开始日期"
          end-placeholder="结束日期">
        </el-date-picker>
      </div>
      <div class="item">
        <el-select v-model="value" placeholder="身份证">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <div class="key">
          <span>:</span>
        </div>
        <el-input v-model="sfzhm"></el-input>
      </div>
      <div class="item">
        <el-button class="green" size="mini">查询</el-button>
        <el-button class="green" size="mini" @click="clear">清空</el-button>
      </div>
    </div>
    <div class="input">
      <el-button class="blue" size="mini">新增</el-button>
    </div>
    <el-table :data="tableData" height="50%" :header-cell-style="{ background: '#024276' }">
      <el-table-column type="selection" width="50">
      </el-table-column>
      <el-table-column prop="name" label="序号" width="150">
      </el-table-column>
      <el-table-column prop="name" label="随访编号" width="150">
      </el-table-column>
      <el-table-column prop="date" label="姓名" width="150">
      </el-table-column>
      <el-table-column prop="date" label="性别" width="150">
      </el-table-column>
      <el-table-column prop="date" label="年龄" width="150">
      </el-table-column>
      <el-table-column prop="date" label="身份证号" width="150">
      </el-table-column>
      <el-table-column prop="date" label="人群分类" width="150">
      </el-table-column>
      <el-table-column prop="date" label="联系方式" width="150">
      </el-table-column>
      <el-table-column prop="date" label="乡镇/社区" width="150">
      </el-table-column>
      <el-table-column prop="date" label="村/服务站" width="150">
      </el-table-column>
      <el-table-column prop="date" label="随访时间" width="150">
      </el-table-column>
      <el-table-column prop="date" label="最近随访时间" width="150">
      </el-table-column>
      <el-table-column prop="date" label="随访类型" width="150">
      </el-table-column>
      <el-table-column prop="date" label="随访人员" width="150">
      </el-table-column>
      <el-table-column prop="date" label="随访状态" width="150">
      </el-table-column>
      <el-table-column prop="date" label="血压舒张" width="150">
      </el-table-column>
      <el-table-column prop="date" label="血压收缩" width="150">
      </el-table-column>
      <el-table-column prop="date" label="分析结果" width="150">
      </el-table-column>
      <el-table-column prop="date" label="随访分类" width="150">
      </el-table-column>
      <el-table-column prop="date" label="转诊机构" width="150">
      </el-table-column>
      <el-table-column label="操作" width="300" fixed="right">
        <!-- slot-scope="scope" -->
        <template>
          <div class="operate">
            <span @click="openSee">查看</span>
            <span @click="openSee1">查看第一次记录</span>
            <span @click="openUpdate">编辑</span>
            <span>删除</span>
            <span @click="openReferral">转诊</span>
          </div>
        </template>
      </el-table-column>
    </el-table>

    <div class="pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
      </el-pagination>
    </div>
    <See v-if="showSee" @close="closeSee"></See>
    <See1 v-if="showSee1" @close="closeSee1"></See1>
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 转诊 -->
    <Referral v-if="showReferral" @close="closeReferral"></Referral>
  </div>
</template>

<script>
import See from './see.vue'
import See1 from './see1.vue'
import Update from './update.vue'
import Referral from '../pupop/referral.vue'
export default {
  components: {
    See,
    See1,
    Update,
    Referral
  },
  data () {
    return {
      tableData: [{}],
      tabIndex: 0,
      tabs: Object.freeze(['高血压随访']),
      options: [],
      value: '',
      radio: '',
      value1: '',
      sfzh: '',
      grdabh: '',
      sfzhm: '',
      showSee: false,
      showSee1: false,
      showUpdate: false,
      showReferral: false
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    clear () {
      this.sfzh = ''
      this.grdabh = ''
      this.sfzhm = ''
      this.radio = ''
      this.value1 = ''
    },
    openSee () {
      this.showSee = true
    },
    closeSee () {
      this.showSee = false
    },
    openSee1 () {
      this.showSee1 = true
    },
    closeSee1 () {
      this.showSee1 = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openReferral () {
      this.showReferral = true
    },
    closeReferral () {
      this.showReferral = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  position: relative;
  width: calc(100% - 10px);
  height: 100%;
  padding: 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      margin-right: 20px;
      background-color: #D5D5D5;
    }

    .blue {
      margin-right: 20px;
      background-color: #01EEFD;
    }

    .color {}

    .width {
      width: 80px;
      font-size: 18px;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: 1px solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: .225rem;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .input {
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 10px;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      margin-right: 20px;

      .key {
        margin-right: 10px;
        white-space: nowrap;
        color: #01EEFD;
        font-size: 16px;
      }

      .margin {
        margin-left: 20px;
      }

      .green {
        width: 90px;
        height: 38px;
        line-height: 38px;
        padding: 0;
        margin: 0;
        margin-right: 20px;
        text-align: center;
        background-color: #04B4D0;
        color: #fff;
      }
    }

    /deep/.el-radio__inner {
      background-color: transparent;
    }

    /deep/.el-radio__label {
      color: #fff;
      font-size: 14px;
    }

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      border: 0;
    }

    .blue {
      margin-right: 20px;
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .search {
      width: 300px;
    }
  }

  .input1 {
    flex-wrap: wrap;
    height: 120px;
    border-bottom: 1px solid #01EEFD;
  }

  .el-table {
    margin-top: 20px;
    background-color: transparent;
    outline: 4px #073F73 solid;
    color: #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .operate {
    display: flex;
    justify-content: space-between;

    span {
      cursor: pointer;
      color: #0077F9;
    }
  }

  /deep/.el-table th.el-table__cell {
    background-color: #073F73;
  }

  /deep/.el-table thead {
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border: none;
  }

  /deep/.el-table td.el-table__cell {
    border-color: #01EEFD;
  }

  /deep/.el-table__cell {
    text-align: center;
  }

  /deep/.el-table__body-wrapper {
    &::-webkit-scrollbar {
      // width: 10px;
      height: 10px;
      // background-color: red;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      // width: 6px;
      // height: 6px;
      background-color: rgba(255, 255, 255, .4);
      border-radius: 10px;
    }

    // &::-webkit-scrollbar-corner {
    //   background-color: transparent;
    // }
    // &::-webkit-scrollbar-track {
    //   background-color: red;
    // }
    // &::-webkit-scrollbar-track-piece {
    //   background-color: red;
    // }
  }

  /deep/.el-table__fixed-right-patch {
    background-color: red;
  }

  /deep/.el-table__fixed-right {
    right: 10px !important;
  }

  /deep/.el-table__body {
    width: 100% !important;
  }

  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-table th.gutter {
    display: none;
    width: 0;
  }

  /deep/.el-table__fixed-right-patch {
    background-color: #073F73;
    border: none;
  }

  /deep/.el-table__fixed-right::before,
  /deep/.el-table__fixed::before {
    background-color: transparent;
  }

  /deep/.el-table__body tr.hover-row>td.el-table__cell {
    background-color: transparent;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
