<template>
  <div class="son">
    <div class="son-head">
      <div class="son-title">
        <span>智能筛查</span>
      </div>
      <el-button @click="go">返回</el-button>
    </div>
    <div class="son-box">
      <div class="son-label">
        <span>基本信息</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input clearable v-model="ruleForm.name" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="sfzh">
          <el-input maxlength="18" clearable v-model="ruleForm.sfzh" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select clearable v-model="ruleForm.sex" placeholder="请选择" class="son-input">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="年龄" prop="age">
          <el-input clearable v-model="ruleForm.age" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="sjhm">
          <el-input maxlength="11" clearable v-model="ruleForm.sjhm" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <div class="son-label">
          <span>健康信息</span>
        </div>
        <el-form-item label="身高" prop="sg">
          <el-input-number v-model="ruleForm.sg" controls-position="right" :step=".1" :min="0" placeholder="请输入"></el-input-number>
          <span>cm</span>
        </el-form-item>
        <el-form-item label="体重" prop="tz">
          <el-input-number v-model="ruleForm.tz" controls-position="right" :step=".1" :min="0" placeholder="请输入"></el-input-number>
          <span>kg</span>
        </el-form-item>
        <el-form-item label="BMI" prop="bmi">
          <el-input-number v-model="ruleForm.bmi" controls-position="right" :step=".1" :min="0" placeholder="请输入"></el-input-number>
          <span>kg</span>
        </el-form-item>
        <el-form-item label="腰围" prop="yw">
          <el-input-number v-model="ruleForm.yw" controls-position="right" :step=".1" :min="0" placeholder="请输入"></el-input-number>
          <span>cm</span>
        </el-form-item>
        <el-form-item label="心率" prop="lv">
          <el-input-number v-model="ruleForm.lv" controls-position="right" :min="0" placeholder="请输入"></el-input-number>
          <span>次/分钟</span>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="血压" prop="ssy">
          <el-input-number v-model="ruleForm.ssy" controls-position="right" :min="0" placeholder="请输入" class="son-input1"></el-input-number>
          <span>/</span>
          <el-input-number v-model="ruleForm.szy" controls-position="right" :min="0" placeholder="请输入" class="son-input1"></el-input-number>
        </el-form-item>
        <el-form-item label="空腹血糖" prop="kfxt">
          <el-input-number v-model="ruleForm.kfxt" controls-position="right" :step=".1" :min="0" placeholder="请输入"></el-input-number>
          <span>mmol/L</span>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="日吸烟量" prop="rxyl">
          <el-input-number v-model="ruleForm.rxyl" controls-position="right" :min="0" placeholder="请输入"></el-input-number>
          <span>支</span>
        </el-form-item>
        <el-form-item label="日饮酒量" prop="ryjl">
          <el-input-number v-model="ruleForm.ryjl" controls-position="right" :min="0" placeholder="请输入"></el-input-number>
          <span>两</span>
        </el-form-item>
        <el-form-item label="日运动量" prop="rydl">
          <el-input-number v-model="ruleForm.rydl" controls-position="right" :min="0" placeholder="请输入"></el-input-number>
          <span>次/周</span>
        </el-form-item>
        <el-form-item label="既往史" prop="jwsjb" class="son-item">
          <el-radio-group v-model="ruleForm.jwsjb">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
            <el-input v-show="ruleForm.jwsjb === '2'" clearable v-model="ruleForm.jwsDes" placeholder="请输入" class="son-input"></el-input>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="就诊史" prop="jzszn" class="son-item">
          <el-radio-group v-model="ruleForm.jzszn">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
            <el-input v-show="ruleForm.jzszn === '2'" clearable v-model="ruleForm.jzsDes" placeholder="请输入" class="son-input"></el-input>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="家族病史" prop="familyHistory" class="son-item">
          <el-radio-group v-model="ruleForm.familyHistory">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
            <el-input v-show="ruleForm.familyHistory === '2'" clearable v-model="ruleForm.jzbsDes" placeholder="请输入" class="son-input"></el-input>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="son-item">
          <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog title="提示" :visible.sync="showTip" width="30%">
      <el-descriptions :colon="false" :column="1" style="display: flex;flex-direction: column;" title="">
        <el-descriptions-item label="筛查结果：">
          <span style="color: red;">高危人群</span>
        </el-descriptions-item>
        <el-descriptions-item label="监测血压：">
          <span style="color: red;">170/100mmHg</span>
        </el-descriptions-item>
        <el-descriptions-item label="">
          <span style="color: red;">请对患者进行辅助检查进一步确诊，并对患者进行“高危人群路径”进行管理</span>
        </el-descriptions-item>
      </el-descriptions>
      <div slot="footer" class="dialog-footer">
        <el-button @click="showTip = false">取 消</el-button>
        <el-button type="primary" @click="showTip = false">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      showTip: false,
      ruleForm: {
        name: '',
        sfzh: '',
        sex: '',
        age: '',
        sjhm: '',
        sg: '',
        tz: '',
        bmi: '',
        yw: '',
        lv: '',
        ssy: '',
        szy: '',
        kfxt: '',
        rxyl: '',
        ryjl: '',
        rydl: '',
        jwsjb: '',
        jwsDes: '',
        jzszn: '',
        jzsDes: '',
        familyHistory: '',
        jzbsDes: ''
      },
      rules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[\u4E00-\u9FA5]{2,5}$/.test(value))) {
                callback(new Error('请输入汉字'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        sfzh: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value))) {
                callback(new Error('请输入正确身份证号'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'blur' }
        ],
        sjhm: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/.test(value))) {
                callback(new Error('请输入正确手机号'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        dlmm: [
          {
            validator: (rule, value, callback) => {
              if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/.test(value))) {
                callback(new Error('请输入8位以上的数字和字母组合'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    go () {
      this.$router.go(-1)
    },
    takePhoto () {
      this.$refs.fileInput.click()
    },
    onFileChange (e) {
      const file = e.target.files[0]
      if (!file) return
      const reader = new FileReader()
      reader.onload = (e) => {
        const dataURL = e.target.result
        // console.log(dataURL)
        this.ruleForm.jmtx = dataURL
      }
      reader.readAsDataURL(file)
    },
    submitForm (formName) {
      this.showTip = true
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    improve () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/improveresidentfiles'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f4f8fb;
    box-sizing: border-box;

    .son-title {
      display: flex;
      align-items: center;
      font-size: 18px;

      &::before {
        display: block;
        content: '';
        width: 6px;
        height: 20px;
        margin-right: 10px;
        background-color: rgba(0, 0, 255, 1);
        border-radius: 8px;
      }
    }
  }

  .son-box {
    overflow: hidden scroll;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-label {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }

    .el-form {
      display: flex;
      flex-wrap: wrap;
    }

    .el-form-item {
      width: calc(30% - 50px);
      margin-right: 50px;
    }

    .son-update {
      display: flex;
      align-items: center;

      .el-image {
        width: 40px;
        height: 40px;
      }

      .image-slot {
        width: 40px;
        height: 40px;

        .son-icon {
          color: gray;
          font-size: 30px;
        }
      }
    }

    .son-item {
      width: 100%;

      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
    }

    .son-width {
      width: calc(60% - 50px);
    }

    .son-input {
      width: 200px;
    }

    .son-input1 {
      width: 100px;
    }

    /deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
      display: none;
    }
    /deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label::after {
      content: '*';
      color: #F56C6C;
    }
  }
}
</style>
