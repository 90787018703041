<template>
  <div class="sun">
    <div class="sun-search">
      <div class="sun-search-item">
        <div class="sun-search-key">
          <span>分层</span>
        </div>
        <el-select v-model="params.fc" placeholder="请选择">
          <el-option v-for="item in fcs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="sun-search-item">
        <div class="sun-search-key">
          <span>管理机构</span>
        </div>
        <el-select v-model="params.gljg" placeholder="请选择">
          <el-option v-for="item in gljgs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="sun-search-item">
        <el-input v-model="params.ssnr" placeholder="请输入姓名/身份证号/手机号" suffix-icon="el-icon-search"></el-input>
        <el-button type="primary" plain>查询</el-button>
      </div>
      <div class="sun-search-item">
        <div class="sun-search-key">
          <span>路径执行</span>
        </div>
        <el-select v-model="params.ljzx" placeholder="请选择">
          <el-option v-for="item in ljzxs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="sun-switch">
      <div :class="{ 'sun-switch-acitve': tabIndex === 0 }" @click="tabClick(0)" class="sun-switch-item">
        <span class="el-icon-s-operation"></span>
      </div>
      <div :class="{ 'sun-switch-acitve': tabIndex === 1 }" @click="tabClick(1)" class="sun-switch-item">
        <span class="el-icon-menu"></span>
      </div>
      <div class="sun-status sun-status1" style="--color: rgba(228, 228, 228, 1)">
        <span>已完成</span>
      </div>
      <div class="sun-status" style="--color: rgba(0, 15, 255, 1)">
        <span>执行中</span>
      </div>
      <div class="sun-status" style="--color: rgba(254, 162, 19, 0.98)">
        <span>未通过评估</span>
      </div>
      <div class="sun-status" style="--color: rgba(153, 0, 51, 1)">
        <span>中途退出</span>
      </div>
      <div class="sun-status" style="--color: rgba(0, 255, 255, 1)">
        <span>未引入</span>
      </div>
    </div>
    <div class="sun-box">
      <el-table :data="tableData" border stripe height="100%" v-if="tabIndex === 1">
        <el-table-column prop="date" label="编号" width="180">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="address" label="身份证号">
        </el-table-column>
        <el-table-column prop="address" label="手机号码">
        </el-table-column>
        <el-table-column prop="address" label="病种">
        </el-table-column>
        <el-table-column prop="address" label="管理机构">
        </el-table-column>
        <el-table-column prop="address" label="建档来源">
        </el-table-column>
        <el-table-column prop="address" label="建档日期">
        </el-table-column>
        <el-table-column prop="address" label="管理医生">
        </el-table-column>
        <el-table-column prop="address" label="管理状态">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <!-- slot-scope="scope" -->
          <template >
            <el-button type="text" size="small" @click="see">详情</el-button>
            <el-button type="text" size="small" class="del" @click="del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="sun-card" v-else-if="tabIndex === 0">
        <div v-for="(item, index) in 16" :key="index" @click="see" class="sun-card-item">
          <div class="sun-card-label green">
            <span>I级</span>
          </div>
          <div class="sun-card-top">
            <el-image src="https://files.axshare.com/gsc/RBUY6U/0d/db/3c/0ddb3c286c8144039541bd12133cfd43/images/%E5%B7%A5%E4%BD%9C%E5%8F%B0/u380.png"></el-image>
            <div class="sun-info">
              <div class="sun-info-top">
                <span class="name">张三</span>
                <span class="age">56岁</span>
                <span class="sex">男</span>
              </div>
              <div class="sun-info-label">
                <span>脑卒中</span>
              </div>
            </div>
          </div>
          <div class="sun-card-center">
            <span>身份证号：5130******0900</span>
            <span>电话：13011111111</span>
            <span class="status" style="--color: rgba(0, 255, 255, 1)">路径状态</span>
          </div>
          <div class="sun-card-bottom">
            <el-button type="primary" size="mini" @click="see">用户详情</el-button>
            <el-button size="mini" @click.stop="del">删除</el-button>
          </div>
        </div>
      </div>
    </div>
    <div class="sun-pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      fcs: Object.freeze([{
        value: '1',
        label: '低危'
      }, {
        value: '2',
        label: '中危'
      }, {
        value: '3',
        label: '高危'
      }]),
      gljgs: [],
      ljzxs: Object.freeze([{
        value: '1',
        label: '已完成'
      }, {
        value: '2',
        label: '执行中'
      }, {
        value: '3',
        label: '中途退出'
      }, {
        value: '4',
        label: '未引入'
      }, {
        value: '5',
        label: '未通过评估'
      }]),
      params: {
        fc: '',
        gljg: '',
        ssnr: '',
        ljzx: ''
      },
      tabIndex: 0,
      tableData: [{}],
      showDetailedInfo: false,
      currentPage: 1
    }
  },
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
    },
    see () {
      // this.showDetailedInfo = true
      this.$router.push({
        path: '/intelligentmonitoring/aichronicdiseaseassistantsystem/detailedinfo',
        query: {
          sfzh: ''
        }
      })
    },
    del () {

    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.sun {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-sizing: border-box;

  .sun-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100px;

    .sun-search-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 30%;

      .sun-search-key {
        width: 80px;
        color: #999999;
        font-size: 16px;
      }

      .el-button {
        margin-left: 10px;
      }
    }
  }

  .sun-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    .sun-switch-item {
      margin-left: 10px;
      margin-right: 10px;
      font-size: 30px;
      cursor: pointer;
    }

    .sun-switch-acitve {
      color: blue;
    }

    .sun-status {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 20px;
      font-size: 16px;

      &::before {
        display: block;
        content: '';
        width: 20px;
        height: 20px;
        margin-right: 10px;
        background-color: var(--color);
      }
    }

    .sun-status1 {
      margin-left: 160px;
    }
  }

  .sun-box {
    width: 100%;
    height: calc(100% - 240px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
      font-family: '微软雅黑 Regular', '微软雅黑';
    }

    .del {
      color: red;
    }

    .sun-card {
      overflow: hidden scroll;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      width: 100%;
      height: 100%;

      &::-webkit-scrollbar {
        width: 0;
      }

      .sun-card-item {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        width: calc(25% - 20px);
        height: 220px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        background-color: #fff;
        border-radius: 8px;
        border: 1px solid rgba(242, 242, 242, 1);
        box-shadow: 0px 0px 15px rgba(242, 242, 242, 1);
        box-sizing: border-box;
        cursor: pointer;

        &:hover {
          background-color: rgba(242, 242, 242, 0.829);
        }

        .sun-card-label {
          position: absolute;
          top: 10px;
          right: 10px;
          padding: 5px 20px;
          border-radius: 24px;
          color: #fff;
          font-size: 16px;
        }

        .green {
          background-color: rgba(61, 212, 167, 1);
        }

        .red {
          background-color: red;
        }

        .yellow {
          background-color: yellow;
        }

        .sun-card-top {
          display: flex;
          flex-direction: row;
          align-items: center;
          height: 50px;

          .el-image {
            width: 84px;
            height: 84px;
            border-radius: 50%;
          }

          .sun-info {
            display: flex;
            flex-direction: column;
            width: calc(100% - 70px);
            margin-left: 10px;

            .name {
              font-size: 18px;
            }

            .age {
              margin-left: 5px;
              margin-right: 5px;
              color: #CCCCCC;
              font-size: 14px;
            }

            .sex {
              color: #CCCCCC;
              font-size: 14px;
            }
          }

          .sun-info-label {
            max-width: 60px;
            text-align: center;
            margin-top: 5px;
            padding: 1px 10px;
            border-radius: 10px;
            background-color: rgba(242, 247, 251, 1);
            color: #3DD4A7;
          }
        }

        .sun-card-center {
          display: flex;
          flex-direction: column;
          color: #000;
          font-size: 13px;

          .status {
            display: flex;
            flex-direction: row;
            align-items: center;

            &::after {
              display: block;
              content: '';
              width: 20px;
              height: 20px;
              margin-left: 40px;
              background-color: var(--color);
            }
          }
        }

        .sun-card-bottom {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;
        }
      }
    }
  }

  .sun-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    /deep/.el-pager li {
      margin-left: 5px;
      margin-right: 5px;
      background-color: rgba(242, 247, 251, 1);
    }

    /deep/.el-pager li.active {
      background-color: rgba(0, 15, 255, 1);
      color: #fff;
    }
  }
}
</style>
