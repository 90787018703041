<template>
  <div class="son">
    <div class="son-title">
      <span>档案管理</span>
    </div>
    <div class="son-switch">
      <div v-for="(item, index) in tabs" :key="index" :class="{ 'son-switch-acitve': tabIndex === index }" @click="tabClick(index)" class="son-switch-item">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="son-search">
      <div class="son-search-item">
        <div class="son-search-key">
          <span>签约状态</span>
        </div>
        <el-select v-model="params.qyzt" placeholder="请选择">
          <el-option v-for="item in qyzts" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>签约机构</span>
        </div>
        <el-select v-model="params.qyjg" placeholder="请选择">
          <el-option v-for="item in qyjgs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>医生团队</span>
        </div>
        <el-select v-model="params.ystd" placeholder="请选择">
          <el-option v-for="item in ystds" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <el-button type="primary" @click="add">新增居民档案</el-button>
        <el-button type="primary" plain>导入</el-button>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>居民标签</span>
        </div>
        <el-select multiple collapse-tags v-model="params.jmbq" placeholder="请选择">
          <el-option v-for="item in jmbqs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <el-input v-model="params.ssnr" placeholder="请输入关键字"></el-input>
      </div>
    </div>
    <div class="son-box">
      <el-table :data="tableData" border stripe height="100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="bh" label="编号" width="180">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="180">
        </el-table-column>
        <el-table-column prop="sfzh" label="身份证号">
        </el-table-column>
        <el-table-column prop="sjhm" label="手机号码">
        </el-table-column>
        <el-table-column label="签约状态">
          <template slot-scope="scope">
            <span v-show="scope.row.signFlag === '0'" class="green">待签约</span>
            <span v-show="scope.row.signFlag === '1'" class="gray">已过期</span>
            <span v-show="scope.row.signFlag === '2'" class="blue">签约中</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="签约团队">
        </el-table-column>
        <el-table-column prop="address" label="签约医生">
        </el-table-column>
        <el-table-column prop="address" label="申请时间">
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="see(scope.row)">查看详情</el-button>
            <el-button type="text" size="small" @click="update(scope.row)">编辑</el-button>
            <el-button type="text" size="small" class="del" @click="del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="son-pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      qyzts: Object.freeze([{
        value: '1',
        label: '待签约'
      }, {
        value: '2',
        label: '签约中'
      }, {
        value: '3',
        label: '已过期'
      }]),
      qyjgs: [],
      ystds: [],
      jmbqs: Object.freeze([{
        value: '1',
        label: '慢病护理'
      }, {
        value: '2',
        label: '高血压'
      }, {
        value: '3',
        label: '高血糖'
      }]),
      params: {
        qyzt: '',
        qyjg: '',
        ystd: '',
        ssnr: '',
        jmbq: ''
      },
      tabIndex: 0,
      tabs: Object.freeze(['高危人群', '一般人群', '慢病患者']),
      tableData: [
        { bh: '11111', name: '张三', sfzh: '130133199909090909', sjhm: '17633333333', signFlag: '0' }
      ],
      showDetailedInfo: false,
      currentPage: 1
    }
  },
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
    },
    add () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/addresidentfiles'
      })
    },
    update () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/addresidentfiles'
      })
    },
    see () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/archivedetails'
      })
    },
    del () {
      this.$confirm('此操作将永久删除该数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 18px;

    &::before {
      display: block;
      content: '';
      width: 6px;
      height: 20px;
      margin-right: 10px;
      background-color: rgba(0, 0, 255, 1);
      border-radius: 8px;
    }
  }

  .son-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    .son-switch-item {
      width: 100px;
      height: 50px;
      line-height: 50px;
      margin-right: 20px;
      text-align: center;
      border-bottom: 3px solid #fff;
      box-sizing: border-box;
      font-size: 18px;
      cursor: pointer;
    }

    .son-switch-acitve {
      border-color: blue;
      color: blue;
    }
  }

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 110px;

    .son-search-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;

      .son-search-key {
        width: 80px;
        color: #999999;
        font-size: 16px;
      }

      .el-button {
        margin-left: 10px;
      }
    }
  }

  .son-box {
    width: 100%;
    height: calc(100% - 300px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .del {
      color: red;
    }

    .green {
      color: #28D094;
    }

    .gray {
      color: #999999;
    }

    .blue {
      color: #0000FF;
    }
  }

  .son-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    /deep/.el-pager li {
      margin-left: 5px;
      margin-right: 5px;
      background-color: rgba(242, 247, 251, 1);
    }

    /deep/.el-pager li.active {
      background-color: rgba(0, 15, 255, 1);
      color: #fff;
    }
  }
}
</style>
