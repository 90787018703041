<template>
  <div class="popup">
    <div class="popup-title">
      <span>查看</span>
    </div>
    <div class="popup-line">
      <span>设备信息</span>
    </div>
    <div class="popup-list">
      <div class="item">
        <span>姓名：{{ info.name || '' }}</span>
      </div>
      <div class="item">
        <span>联系方式：{{ info.lxfs || '' }}</span>
      </div>
      <div class="item">
        <span>设备厂家：{{ info.sbcj || '' }}</span>
      </div>
      <div class="item">
        <span>设备编号：{{ info.ssbbhbcj || '' }}</span>
      </div>
    </div>

    <div class="popup-line">
      <span>配置信息</span>
    </div>
    <div class="popup-list">
      <div class="item">
        <span>设备名称：{{ info.sbmc || '' }}</span>
      </div>
      <div class="item">
        <span>SIM卡号：{{ info.simk || '' }}</span>
      </div>
      <div class="item">
        <span>紧急呼叫模式：SOS紧急呼叫模式</span>
      </div>
      <div class="item">
        <span>静音：关</span>
      </div>
      <div class="item">
        <span>计步监测：开</span>
      </div>

      <div class="item">
        <span>睡眠监测：开</span>
      </div>
      <div class="item">
        <span>设备蓝牙：关</span>
      </div>
      <div class="item">
        <span>跌倒监测：开</span>
      </div>
      <div class="item">
        <span>体温上报频率：30分钟</span>
      </div>
      <div class="item">
        <span>体温定时测量：30分钟</span>
      </div>

      <div class="item">
        <span>心率监测频率：30分钟</span>
      </div>
      <div class="item">
        <span>心率阈值：40-140</span>
      </div>
    </div>
    <el-button size="mini" @click="close">返回</el-button>
  </div>
</template>

<script>
export default {
  data () {
    return {
      info: {
        name: '',
        lxfs: '',
        sbcj: '',
        ssbbhbcj: '',
        sbmc: '',
        simk: ''
      }
    }
  },
  created () {
    this.getInfo()
  },
  methods: {
    // 定位设备根据id获取
    async getInfo () {
      // 接口地址 https://app.apifox.com/project/2726972/apis/api-86132056
      try {
        const obj = {
          sblx: '',
          sbbh: '',
          ssjgbh: '',
          cwhmcid: '',
          xzjdmcid: ''
        }
        const { data: res } = await this.$http.post('/zhyyapi/ykddwsbcha/find', obj)
        console.log('定位设备根据id获取', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
      } catch (error) {
        console.log('定位设备根据id获取请求失败', error)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
  .popup {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 88;
    width: 100%;
    height: 100%;
    padding: 0 20px;
    background-color: #081C38;
    box-sizing: border-box;

    .popup-title {
      height: 60px;
      line-height: 60px;
      color: #01EEFD;
      font-size: 20px;
    }

    .popup-line {
      width: calc(100% - 24px);
      height: 45px;
      line-height: 45px;
      margin-left: 24px;
      padding-left: 18px;
      border-bottom: 1px solid #01EEFD;
      box-sizing: border-box;
      color: #01EEFD;
      font-size: 18px;
      font-weight: bold;
    }

    .popup-list {
      display: flex;
      flex-wrap: wrap;
      margin-top: 20px;
      padding-left: 42px;
      box-sizing: border-box;

      .item {
        width: 20%;
        margin-bottom: 20px;
        color: #01EEFD;
        font-size: 16px;
      }
    }

    .el-button {
      position: absolute;
      left: 40px;
      bottom: 20px;
      background-color: #33465C;
      border: none;
      border-radius: 0;
      color: #fff;
    }
  }
</style>
