<template>
  <div class="report">
    <div class="report-close" @click="close">
      <span class="icon el-icon-close"></span>
    </div>
    <div class="report-top">
      <div class="title">
        <span>收住院</span>
      </div>
    </div>
    <div class="report-bottom">
      <div class="report-left">
        <div class="info">
          <div class="info-left">
            <img src="../../img/emr/user.png" class="info-img">
          </div>
          <div class="info-right">
            <span>王丽霞</span>
            <span class="sex">女</span>
            <span>44岁3月</span>
            <span>202111606032</span>
          </div>
        </div>
        <div class="list">
          <div class="head">
            <div class="select active">
              <span>今日</span>
            </div>
            <div class="select">
              <span>近7日</span>
            </div>
            <div class="select">
              <span>近3月</span>
            </div>
            <el-select v-model="value" placeholder="请选择" size="small">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="scroll">
            <div class="item">
              <div class="line"></div>
              <div class="round round-active"></div>
              <div class="card card-active">
                <div class="card-top">
                  <span>新型冠状病毒核酸检测</span>
                </div>
                <div class="card-bottom">
                  <span>2021-05-22 08:55 </span>
                  <span class="status">未出报告</span>
                </div>
              </div>
            </div>
            <div class="item">
              <div class="line"></div>
              <div class="round"></div>
              <div class="card">
                <div class="card-top">
                  <span>新型冠状病毒核酸检测</span>
                </div>
                <div class="card-bottom">
                  <span>2021-05-22 08:55 </span>
                  <span class="status">未出报告</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-right">
        <div class="news">
          <span>检验项目：新型冠状病毒核酸检测</span>
          <span>申请科室：五官科门诊</span>
          <span>医生：叶谓之</span>
          <span>送检时间：2021-05-22 08:55</span>
        </div>
        <div class="news">
          <span>样本号：15</span>
          <span>样本类型：鼻咽拭子</span>
          <span>检验：宋叶月</span>
          <span>审核：王爱护</span>
          <span>报告时间：2021-05-22 08:55</span>
        </div>
        <div class="table">
          <div class="table-head">
            <span class="table-flex table-flex2">项目名称</span>
            <span class="table-flex">英文标识</span>
            <span class="table-flex">结果</span>
            <span class="table-flex">报警</span>
            <span class="table-flex">参考范围</span>
            <span class="table-flex">单位</span>
          </div>
          <div class="table-scroll">
            <div class="table-tr">
              <span class="table-flex table-flex2">新型冠状病毒2019-nCoV核算检测</span>
              <span class="table-flex">2019-nCo</span>
              <span class="table-flex">阴性(-)</span>
              <span class="table-flex"></span>
              <span class="table-flex">阴性(-)</span>
              <span class="table-flex"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      selectIndex: 0,
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.report {
  position: absolute;
  top: 24px;
  right: 110px;
  z-index: 88;
  width: 1350px;
  height: 504px;
  padding: 14px 20px;
  background-color: #fff;
  box-shadow: -3px 2px 6px 1px rgba(0, 0, 0, 0.1608);
  border-radius: 6px;
  box-sizing: border-box;

  .report-close {
    position: absolute;
    top: 8px;
    right: 14px;
    z-index: 888;
    color: #707070;
    font-size: 26px;
    cursor: pointer;
  }

  .report-top {
    position: relative;
    display: flex;
    align-items: center;
    height: 30px;
    padding: 0 10px;
    border-bottom: 1px solid #A1A6BB;
    box-sizing: border-box;

    .title {
      color: #666666;
      font-size: 14px;
    }
  }

  .report-bottom {
    display: flex;
    align-items: center;
    width: 100%;
    height: calc(100% - 30px);

    .report-left {
      width: 264px;
      height: 100%;
      border-right: 1px solid #A1A6BB;
      box-sizing: border-box;

      .info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 90px;
        margin: 10px 0;
        padding: 9px 15px;
        background-color: #81ACD3;
        box-sizing: border-box;

        .info-left {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 69px;
          height: 69px;
          border-radius: 50%;
          background-color: #fff;

          .info-img {
            width: 60px;
            height: 60px;
          }
        }

        .info-right {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          width: calc(100% - 75px);
          color: #333333;
          font-size: 16px;

          .sex {
            color: #FF00F7;
          }
        }
      }

      .list {
        width: 100%;
        height: calc(100% - 110px);
        border-top: 1px solid #A1A6BB;
        box-sizing: border-box;

        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          height: 50px;
          padding-right: 16px;
          box-sizing: border-box;

          .select {
            white-space: nowrap;
            color: #3E3E3E;
            font-size: 14px;
            cursor: pointer;
          }

          .active {
            color: #1269B7;
          }

          .el-select {
            width: 100px;
          }
        }

        .scroll {
          width: calc(100% - 8px);
          height: calc(100% - 50px);

          .item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            height: 55px;
            margin-bottom: 10px;

            .line {
              width: 15px;
              height: 1px;
              background-color: #A1A6BB;
            }

            .round {
              width: 14px;
              height: 14px;
              background-color: #666666;
              border-radius: 50%;
            }

            .round-active {
              background-color: #1269B7;
            }

            .card {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              width: calc(100% - 35px);
              height: 100%;
              margin-left: 4px;
              padding: 7px 5px;
              background-color: #D9D9D9;
              border-radius: 6px;
              box-sizing: border-box;
              cursor: pointer;

              .card-top {
                color: #333333;
                font-size: 14px;
              }

              .card-bottom {
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #666666;
                font-size: 12px;

                .status {
                  color: #FF9200;
                }
              }
            }

            .card-active {
              background-color: #79B3E7;

              .card-top {
                color: #1269B7;
              }

              .card-bottom {
                .status {
                  color: #1269B7;
                }
              }
            }
          }
        }
      }
    }

    .report-right {
      width: calc(100% - 264px);
      height: 100%;
      padding: 10px;
      box-sizing: border-box;

      .news {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 10px;
        color: #333333;
        font-size: 14px;
        box-sizing: border-box;
      }

      .table {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #A1A6BB;
        border-left: 1px solid #A1A6BB;
        box-sizing: border-box;

        .table-head {
          display: flex;
          align-items: center;
          width: 100%;
          height: 33px;
          background-color: #D4D4D4;
        }

        .table-flex {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          flex: 1;
          height: 100%;
          line-height: 33px;
          text-align: center;
          border-right: 1px solid #A1A6BB;
          border-bottom: 1px solid #A1A6BB;
          box-sizing: border-box;
          color: #333333;
          font-size: 12px;
        }

        .table-flex2 {
          flex: 2;
        }

        .table-tr {
          display: flex;
          align-items: center;
          width: 100%;
          height: 33px;
        }

        .table-scroll {}
      }
    }
  }
}
</style>
