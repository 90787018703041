<template>
  <div class="son">
    <div class="son-title">
      <span>体重趋势</span>
    </div>
    <div id="charts" class="son-charts"></div>
    <div class="son-title">
      <span>体重记录</span>
      <el-button type="primary" size="mini" @click="add">新增</el-button>
    </div>
    <div class="son-table">
      <el-table :data="tableData" border stripe style="height: 100%">
        <el-table-column prop="date" label="测量时间">
        </el-table-column>
        <el-table-column prop="name" label="数值（bmp）">
        </el-table-column>
        <el-table-column prop="address" label="评估">
        </el-table-column>
        <el-table-column prop="address" label="数据来源">
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="update(scope.row)">编辑</el-button>
            <el-button type="text" @click="del(scope.row)" class="red">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Add v-if="showAdd"></Add>
    <Update v-if="showUpdate"></Update>
  </div>
</template>

<script>
import Add from './add.vue'
import Update from './update.vue'
export default {
  components: {
    Add,
    Update
  },
  data () {
    return {
      myChart: null,
      tableData: [{}],
      showAdd: false,
      showUpdate: false
    }
  },
  mounted () {
    this.canvas()
    window.addEventListener('resize', this.getResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getResize)
  },
  methods: {
    getResize () {
      this.myChart.resize()
    },
    canvas () {
      const chartDom = document.getElementById('charts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        grid: {
          containLabel: true,
          left: '2%',
          top: '15%',
          right: '10%',
          bottom: '5%'
        },
        xAxis: {
          type: 'category',
          data: ['09/01', '09/02', '09/03', '09/04', '09/05', '09/06', '09/07']
        },
        yAxis: {
          type: 'value',
          name: '单位：bmp',
          nameTextStyle: {
            color: '#999999',
            fontSize: 12,
            padding: [0, 0, 0, 5]
          }
        },
        series: [
          {
            data: [150, 230, 224, 218, 135, 147, 260],
            type: 'line',
            label: {
              show: true
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    add () {
      this.showAdd = true
    },
    update () {
      this.showUpdate = true
    },
    del () {
      this.$confirm('删除后无法恢复，确定要删除此条记录吗？', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 40px;
  box-sizing: border-box;

  .son-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    font-size: 16px;
  }

  .son-charts,
  .son-table {
    width: 100%;
    height: calc(50% - 30px);

    /deep/.el-table .cell {
      text-align: center;
    }

    .green {
      color: #3DD4A7;
    }

    .red {
      color: #FA746B;
    }
  }
}
</style>
