<template>
  <div class="tab">
    <div class="tab-left">
      <div v-for="(item, index) in sons" :key="index" :class="{'tab-active': index === sonIndex}" @click="sonClick(index)" class="tab-item">
        <div :style="{ '--color': item.color }" class="tab-img">
          <el-image :src="item.img" fit="contain" class="img"></el-image>
        </div>
        <span>{{ item.text }}</span>
      </div>
    </div>
    <div class="tab-right">
      <Son1 v-if="sonIndex === 0"></Son1>
      <Son2 v-if="sonIndex === 1"></Son2>
      <Son3 v-if="sonIndex === 2"></Son3>
      <Son4 v-if="sonIndex === 3"></Son4>
    </div>
  </div>
</template>

<script>
import Son1 from './son1/son1.vue'
import Son2 from './son2/son2.vue'
import Son3 from './son3/son3.vue'
import Son4 from './son4/son4.vue'
export default {
  components: {
    Son1,
    Son2,
    Son3,
    Son4
  },
  data () {
    return {
      sonIndex: 0,
      sons: Object.freeze([
        { index: 0, text: '体重', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5961.png', color: '#d8f7ed' },
        { index: 1, text: '血压', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5973.png', color: '#fee3e1' },
        { index: 2, text: '血糖', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5966.png', color: '#ffe2b6' },
        { index: 3, text: '心率', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5979.png', color: '#bbbbff' },
        { index: 4, text: '尿酸', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5966.png', color: '#ffe2b6' },
        { index: 5, text: 'BMI', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5973.png', color: '#fee3e1' },
        { index: 6, text: '肺功能', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5961.png', color: '#d8f7ed' },
        { index: 7, text: '肾小球滤过率', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5979.png', color: '#bbbbff' },
        { index: 8, text: '尿白蛋白', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5973.png', color: '#fee3e1' },
        { index: 9, text: '肌酐', img: 'https://files.axshare.com/gsc/V9BTMO/e1/94/a6/e194a6ce81f8487c8ed9fc975c31b9ba/images/健康监测/u5966.png', color: '#ffe2b6' }
      ])
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      try {
        const index = sessionStorage.getItem('detailedInfoSonIndex') || 0
        this.sonIndex = parseInt(index)
      } catch (error) {

      }
    },
    sonClick (index) {
      if (index === this.sonIndex) return
      if (index > 3) return this.$message({ message: '暂无开放', type: 'warning' })
      this.sonIndex = index
      sessionStorage.setItem('detailedInfoSonIndex', index)
    }
  }
}
</script>

<style lang="less" scoped>
  .tab {
    display: flex;
    width: 100%;
    height: 100%;

    .tab-left {
      width: 190px;
      height: 100%;
      padding-left: 5px;
      padding-right: 5px;
      border-right: 1px solid #f2f7fb;
      box-sizing: border-box;

      .tab-item {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        padding-left: 20px;
        border-bottom: 1px solid #f2f7fb;
        box-sizing: border-box;
        font-size: 14px;
        cursor: pointer;

        .tab-img {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30px;
          height: 30px;
          margin-right: 5px;
          border-radius: 50%;
          background-color: var(--color);
          cursor: pointer;

          .img {
            width: 18px;
            height: 20px;
            object-fit: contain;
          }
        }
      }

      .tab-active {
        border-color: rgba(0, 0, 255, 1);
      }
    }

    .tab-right {
      width: calc(100% - 190px);
      height: 100%;
    }
  }
</style>
