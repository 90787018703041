<template>
  <div class="son">
    <div class="son-title">
      <span>院外筛查</span>
    </div>
    <div class="son-search">
      <div class="son-search-item">
        <div class="son-search-key">
          <span>筛查日期</span>
        </div>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="son-search-item">
        <el-input v-model="params.ssnr" placeholder="请输入姓名/身份证号"></el-input>
      </div>
      <div class="son-search-item">
        <el-button type="primary" plain>查询</el-button>
        <el-button type="primary" @click="openTip">筛查</el-button>
      </div>
    </div>
    <div class="son-switch">
      <div v-for="(item, index) in tabs" :key="index" :class="{ 'son-switch-acitve': tabIndex === index }"
        @click="tabClick(index)" class="son-switch-item">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="son-box">
      <el-table :data="tableData" border stripe height="100%">
        <el-table-column type="selection" width="55"></el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column prop="name" label="性别">
          <template slot-scope="scope">
            <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="年龄">
        </el-table-column>
        <el-table-column prop="address" label="身份证号">
        </el-table-column>
        <el-table-column prop="address" label="电话">
        </el-table-column>
        <el-table-column prop="address" label="病种">
        </el-table-column>
        <el-table-column prop="address" label="筛查时间">
        </el-table-column>
        <el-table-column prop="address" label="筛查结果">
        </el-table-column>
        <el-table-column prop="address" label="筛查机构">
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="see(scope.row)">筛查详情</el-button>
            <el-button type="text" size="small" @click="add(scope.row)">建档</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="son-pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400" hide-on-single-page>
      </el-pagination>
    </div>
    <el-dialog title="智能筛查" :visible.sync="showTip" width="35%">
      <el-descriptions :colon="false" :column="1" style="display: flex;flex-direction: column;" title="">
        <el-descriptions-item label="">筛查说明：智能筛查依据系统已配置的病种高危监测指标进行筛查，筛查结果为慢性病高危初筛结果，需结合临床指标做进一步筛查判断！</el-descriptions-item>
        <el-descriptions-item label="">
          <span style="color: #000;">请选择筛查病种：</span>
        </el-descriptions-item>
      </el-descriptions>
      <el-checkbox-group v-model="checkboxGroup">
        <el-checkbox-button v-for="(item, index) in sxlxs" :label="item.value" :key="index">{{ item.label }}</el-checkbox-button>
      </el-checkbox-group>
      <div slot="footer" class="dialog-footer">
        <el-button @click="closeTip">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      params: {
        date: '',
        ssnr: ''
      },
      tabIndex: 0,
      tabs: Object.freeze(['高危人群', '一般人群']),
      tableData: [
        { name: '张三' }
      ],
      currentPage: 1,
      showTip: false,
      checkboxGroup: [],
      sxlxs: Object.freeze([
        { label: '高血压', value: '1' },
        { label: '糖尿病', value: '2' },
        { label: '脑卒中', value: '3' },
        { label: '冠心病', value: '4' },
        { label: '慢性阻塞性肺病', value: '5' }
      ])
    }
  },
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
    },
    openTip () {
      this.showTip = true
    },
    closeTip () {
      this.checkboxGroup = []
      this.showTip = false
    },
    submit () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/intelligentscreen'
      })
    },
    see () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/intelligentscreen'
      })
    },
    add () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/addresidentfiles'
      })
    },
    del () { },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 18px;

    &::before {
      display: block;
      content: '';
      width: 6px;
      height: 20px;
      margin-right: 10px;
      background-color: rgba(0, 0, 255, 1);
      border-radius: 8px;
    }
  }

  .son-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    .son-switch-item {
      width: 100px;
      height: 50px;
      line-height: 50px;
      margin-right: 20px;
      text-align: center;
      border-bottom: 3px solid #fff;
      box-sizing: border-box;
      font-size: 18px;
      cursor: pointer;
    }

    .son-switch-acitve {
      border-color: blue;
      color: blue;
    }
  }

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 110px;

    .son-search-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
      padding-right: 10px;
      box-sizing: border-box;

      .son-search-key {
        width: 80px;
        color: #999999;
        font-size: 16px;
      }

      .el-button {
        margin-left: 10px;
      }
    }
  }

  .son-box {
    width: 100%;
    height: calc(100% - 300px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .del {
      color: red;
    }

    .green {
      color: #28D094;
    }

    .gray {
      color: #999999;
    }

    .blue {
      color: #0000FF;
    }
  }

  .son-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    /deep/.el-pager li {
      margin-left: 5px;
      margin-right: 5px;
      background-color: rgba(242, 247, 251, 1);
    }

    /deep/.el-pager li.active {
      background-color: rgba(0, 15, 255, 1);
      color: #fff;
    }
  }
}
</style>
