<template>
  <div class="organRun">
    <div class="seeInfo-left">
      <div class="title">
        <span>患者住院信息</span>
      </div>
      <el-input class="margin" placeholder="机构名称" suffix-icon="el-icon-search">
      </el-input>
      <el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
    </div>
    <div class="seeInfo-right">
      <div class="title">
        <span>编辑菜单信息</span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">创建时间</span>
          <el-date-picker class="width" type="date" placeholder="选择日期">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="span">机构类型</span>
          <el-input class="width"></el-input>
        </div>
        <div class="item">
          <span class="span">医院名称</span>
          <el-input class="width"></el-input>
        </div>
        <div class="item">
          <span class="span">医院性质</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">医院类型</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">医院级别</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item item1">
          <span class="span">所属区域</span>
          <el-select class="width1" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="span span1">省</span>
          <el-select class="width1" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="span span1">市</span>
          <el-select class="width1" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <span class="span span1">区/县</span>
          <el-input class="width" placeholder="请输入地址"></el-input>
        </div>
        <div class="item">
          <span class="span">联系电话</span>
          <el-input class="width" maxlength="11"></el-input>
        </div>
        <div class="item">
          <span class="span">系统名称</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">云端开户</span>
          <el-select class="width" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-button>确认</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      data: [{
        label: '宝安中心医院',
        children: [{
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }, {
          label: '01 李明明'
        }]
      }],
      defaultProps: {
        children: 'children',
        label: 'label'
      }
    }
  },
  methods: {
    handleNodeClick (data) {
      // console.log(data)
    }
  }
}
</script>

<style lang="less" scpoed>
.organRun {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-top: 1px solid #01EEFD;
  box-sizing: border-box;

  .seeInfo-left {
    width: 3.375rem;
    height: 10.3125rem;
    padding: .125rem;
    background-color: #081C38;
    box-sizing: border-box;

    .title {
      width: 100%;
      height: .4375rem;
      line-height: .52rem;
      text-align: center;
      background-image: url('../../../img/ssjk/title.png');
      background-size: 100% 100%;
      color: #fff;
      font-size: .25rem;
      font-weight: bold;
    }

    .margin {
      margin: .125rem 0;
    }

    .el-select {
      margin-top: .25rem;
    }

    .btns {
      display: flex;
      justify-content: space-between;
      width: 90%;
      margin: .125rem auto;

      .el-button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        border-radius: 0;
        color: #fff;
        border: none;

        &::after {
          border: none;
        }
      }
    }

    .el-tree {
      background-color: #081C38;
      color: #fff;
      .el-tree-node>.el-tree-node__content:hover {
        background-color: transparent;
      }

      .is-current {
        background-color: transparent;
        color: #01EEFD;
        background-color: #081C38;
      }

      .el-tree-node.is-current>.el-tree-node__content {
        background-color: transparent !important;
      }

      /deep/.el-tree-node>.el-tree-node__content:hover {
        background-color: transparent;
      }

      /deep/.is-current {
        background-color: transparent;
        color: #01EEFD;
        background-color: #081C38;
      }

      /deep/.el-tree-node.is-current>.el-tree-node__content {
        background-color: transparent !important;
      }
    }
  }

  .seeInfo-right {
    width: calc(100% - 3.625rem);
    height: 100%;
    padding: .125rem;
    border-left: .05rem solid rgba(1, 124, 219, .27);
    box-sizing: border-box;
    background-color: #08284B;
  }

  .title {
    height: .625rem;
    line-height: .625rem;
    color: #04B4D0;
    font-size: .225rem;
  }

  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;

    .item {
      display: flex;
      align-items: center;
      width: 48%;
      margin-bottom: .25rem;
      color: #04B4D0;
      font-size: .225rem;
    }

    .item1 {
      width: 100%;
    }

    .span {
      margin-right: .125rem;
      white-space: nowrap;
    }

    .span1 {
      margin-left: .125rem;
    }

    .width {
      width: 6.875rem;
    }

    .width1 {
      width: 1.5rem;
    }
  }

  .el-button {
    background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    border: none;
    border-radius: 0;
    color: #fff;
  }
}
</style>
