<template>
  <div class="page">
    <div class="switch">
      <span class="item" :class="{ item1: switchIndex === index }" v-for="(item, index) in switchs" :key="index"
        @click="switchClick(index)">{{ item }}</span>
    </div>
    <div class="content" v-if="switchIndex === 0">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input class="search-input" placeholder="姓名/床位" suffix-icon="el-icon-search">
            </el-input>
            <el-date-picker type="date" class="date margin-left" placeholder="时间">
            </el-date-picker>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
        </div>
        <el-table :data="tableData" height="206" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="检测日期">
          </el-table-column>
          <el-table-column prop="date" label="检测时间">
          </el-table-column>
          <el-table-column prop="type" label="床号">
          </el-table-column>
          <el-table-column prop="type1" label="姓名">
          </el-table-column>
          <el-table-column prop="type1" label="血糖值">
          </el-table-column>
          <el-table-column prop="type1" label="检测时段">
          </el-table-column>
          <el-table-column prop="type1" label="处理结果">
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex">
                <span @click="openHandleRecord">处理</span>
                <span @click="openDeleteRecord('是否删除患者：李四,血糖值为：21.2的预警？')">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 1">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input class="search-input" placeholder="姓名/床位" suffix-icon="el-icon-search">
            </el-input>
            <el-date-picker type="date" class="date margin-left" placeholder="时间">
            </el-date-picker>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
        </div>
        <el-table :data="tableData" height="206" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="检测日期">
          </el-table-column>
          <el-table-column prop="date" label="检测时间">
          </el-table-column>
          <el-table-column prop="type" label="床号">
          </el-table-column>
          <el-table-column prop="type1" label="姓名">
          </el-table-column>
          <el-table-column prop="type1" label="收缩压(mmHg)">
          </el-table-column>
          <el-table-column prop="type1" label="舒张压(mmHg)">
          </el-table-column>
          <el-table-column prop="type1" label="心率(次/分)">
          </el-table-column>
          <el-table-column label="预警结果">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex flex1">
                <span>轻高</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <div class="content" v-else-if="switchIndex === 2">
      <div class="content-box">
        <div class="content-box-search">
          <div class="left">
            <el-input class="search-input" placeholder="姓名/床位" suffix-icon="el-icon-search">
            </el-input>
            <el-date-picker type="date" class="date margin-left" placeholder="时间">
            </el-date-picker>
            <el-button size="small" class="blue">搜索</el-button>
            <el-button size="small" class="gray">重置</el-button>
          </div>
        </div>
        <el-table :data="tableData" height="206" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="检测日期">
          </el-table-column>
          <el-table-column prop="date" label="检测时间">
          </el-table-column>
          <el-table-column prop="type" label="床号">
          </el-table-column>
          <el-table-column prop="type1" label="姓名">
          </el-table-column>
          <el-table-column prop="type1" label="体温(℃)">
          </el-table-column>
          <el-table-column prop="type1" label="处理结果">
          </el-table-column>
          <el-table-column prop="type1" label="处理人">
          </el-table-column>
          <el-table-column prop="type1" label="处理时间">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex flex1">
                <span @click="openDeleteRecord('是否删除患者：潘小章,体温值为：38.6℃的预警？')">删除</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 处理记录 -->
    <HandleRecord v-if="showHandleRecord" @close="closeHandleRecord"></HandleRecord>
    <!-- 删除记录 -->
    <DeleteRecord v-if="showDeleteRecord" :tipInfo="tipInfo" @close="closeDeleteRecord"></DeleteRecord>
  </div>
</template>

<script>
import HandleRecord from './handleRecord.vue'
import DeleteRecord from './deleteRecord.vue'
export default {
  components: {
    HandleRecord,
    DeleteRecord
  },
  data () {
    return {
      switchIndex: 0,
      switchs: Object.freeze(['血糖预警', '血压预警', '体温预警']),
      tableData: [{
        date: '1',
        name: '1',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }],
      value: '',
      options: [{
        value: '',
        label: ''
      }],
      showHandleRecord: false,
      tipInfo: '',
      showDeleteRecord: false
    }
  },
  methods: {
    switchClick (index) {
      if (index === this.switchIndex) return
      this.switchIndex = index
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openHandleRecord () {
      this.showHandleRecord = true
    },
    closeHandleRecord () {
      this.showHandleRecord = false
    },
    openDeleteRecord (value) {
      this.tipInfo = value
      this.showDeleteRecord = true
    },
    closeDeleteRecord () {
      this.showDeleteRecord = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: .25rem;
  box-sizing: border-box;

  .switch {
    display: flex;
    width: 100%;
    height: .5rem;

    .item {
      width: 1rem;
      height: .5rem;
      line-height: .5rem;
      margin-right: .25rem;
      padding: 0 .125rem;
      text-align: center;
      color: #fff;
      font-size: .175rem;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - .625rem);
    margin-top: .125rem;

    &::-webkit-scrollbar {
      width: 0;
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0rem;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      background-color: #092B50;
      border: .05rem #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: .2125rem;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: .025rem solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: .0125rem solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: .0125rem solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: .0125rem solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    ::v-deep .el-table__empty-text {
      color: #01EEFD;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: .5rem;

      ::v-deep.el-pagination__total,
      ::v-deep.el-pagination__jump {
        color: #01EEFD;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #01EEFD;
      font-size: .1875rem;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      justify-content: center;
    }

    .content-box {
      width: 100%;
      height: 100%;
      padding: .25rem;
      border: .05rem solid #073F73;
      box-sizing: border-box;

      .content-box-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .25rem;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 3.25rem;
          }

          .el-select {
            width: 2.5rem;
            margin-left: .125rem;
          }

          .date {
            margin-right: .125rem;
          }

          .margin-left {
            margin-left: .25rem;
          }

          .blue,
          .gray {
            margin-left: .5rem;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            background-color: #284867;
          }
        }
      }
    }
  }
}
</style>
