<template>
  <div class="sun">
    <div class="sun-title">
      <span>签约信息</span>
    </div>
    <div class="sun-content">
      <div class="sun-item">
        <div class="sun-key">
          <span>签约编号</span>
        </div>
        <div class="sun-value">
          <span>20240908900</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>签约状态</span>
        </div>
        <div class="sun-value blue">
          <span>生效中</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>签约机构</span>
        </div>
        <div class="sun-value">
          <span>六里屯区卫生服务站</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>签约团队</span>
        </div>
        <div class="sun-value">
          <span>李医生团队</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>签约医生</span>
        </div>
        <div class="sun-value">
          <span>李医生</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>签约周期</span>
        </div>
        <div class="sun-value">
          <span>1年</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>服务包</span>
        </div>
        <div class="sun-value">
          <span>慢性病护理包</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>签约类型</span>
        </div>
        <div class="sun-value">
          <span>首次签约</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>申请时间</span>
        </div>
        <div class="sun-value">
          <span>2024/09/09 10:09:09</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>生效日期</span>
        </div>
        <div class="sun-value">
          <span>2024/09/09</span>
        </div>
      </div>
      <div class="sun-item">
        <div class="sun-key">
          <span>签约备注</span>
        </div>
        <div class="sun-value">
          <span> 无</span>
        </div>
      </div>
    </div>
    <div class="sun-title">
      <span>绑定设备</span>
      <el-button type="text">
        <span class="el-icon-plus"></span>
        <span>绑定设备</span>
      </el-button>
    </div>
    <el-table :data="tableData" border style="width: 100%">
      <el-table-column prop="name" label="设备名称"></el-table-column>
      <el-table-column prop="date" label="设备SN号"></el-table-column>
      <el-table-column prop="date" label="绑定时间"></el-table-column>
      <el-table-column prop="date" label="状态"></el-table-column>
      <el-table-column label="操作">
        <el-button type="text">解绑</el-button>
      </el-table-column>
    </el-table>
    <div class="sun-title">
      <span>签约记录</span>
    </div>
    <el-table :data="tableData1" border stripe style="width: 100%">
      <el-table-column prop="date" label="编号"></el-table-column>
      <el-table-column prop="date" label="签约机构"></el-table-column>
      <el-table-column prop="date" label="签约医生团队"></el-table-column>
      <el-table-column prop="date" label="签约医生"></el-table-column>
      <el-table-column prop="date" label="申请时间"></el-table-column>
      <el-table-column prop="date" label="到期时间"></el-table-column>
      <el-table-column label="操作">
        <el-button type="text" @click="see">查看详情</el-button>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tableData: [
        { name: '血压计' }
      ],
      tableData1: [
        {},
        {}
      ]
    }
  },
  methods: {
    see () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/signdetails'
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .sun {
    overflow: hidden scroll;
    width: 100%;
    height: 100%;

    &::-webkit-scrollbar {
      width: 0;
    }

    .sun-title {
      display: flex;
      align-items: center;
      width: 100%;
      margin-top: 20px;
      margin-bottom: 10px;
      font-size: 16px;

      .el-button {
        margin-left: 10px;
      }
    }

    .sun-content {
      display: flex;
      flex-wrap: wrap;
      align-content: start;
      width: 100%;

      .sun-item {
        display: flex;
        align-items: center;
        width: calc(25% - 10px);
        margin-top: 30px;

        .sun-key {
          width: 100px;
          text-align: right;
          color: #999999;
          font-size: 14px;
        }

        .sun-value {
          width: calc(100% - 120px);
          margin-left: 10px;
          margin-right: 10px;
          word-break: break-all;
        }

        .blue {
          color: #0000FF;
        }
      }
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }
  }
</style>
