<template>
  <div class="pupop">
    <div class="pupop-title">
      <span>收住院</span>
    </div>
    <div class="pupop-close" @click="close">
      <span class="el-icon-close"></span>
    </div>
    <div class="pupop-scroll">
      <div class="list">
        <span>李艳艳 44岁 3月</span>
      </div>
      <div class="list">
        <span>2021102033555</span>
      </div>
      <div class="list list1">
        <span class="span">身份证号:</span>
        <el-input value="130730199808080808"></el-input>
      </div>
      <div class="list list1">
        <span class="span">手机号:</span>
        <el-input value="15803339264"></el-input>
      </div>
      <div class="list list1">
        <span class="span">住址:</span>
        <el-input value="山东省  青岛市  市南区  某某小区单元"></el-input>
      </div>
      <div class="list list1">
        <span class="span">入院科签:</span>
        <el-select v-model="value" placeholder="">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="list list1">
        <span class="span">入院病区:</span>
        <el-select v-model="value" placeholder="">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="list list1">
        <span class="span">申请时间:</span>
        <el-date-picker type="date" placeholder="">
        </el-date-picker>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '选项1',
        label: '黄金糕'
      }, {
        value: '选项2',
        label: '双皮奶'
      }, {
        value: '选项3',
        label: '蚵仔煎'
      }, {
        value: '选项4',
        label: '龙须面'
      }, {
        value: '选项5',
        label: '北京烤鸭'
      }],
      value: ''
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.pupop {
  position: absolute;
  top: 24px;
  right: 110px;
  width: 400px;
  height: 504px;
  padding: 0 20px;
  background-color: #fff;
  box-shadow: -3px 2px 6px 1px rgba(0, 0, 0, 0.1608);
  border-radius: 6px;
  box-sizing: border-box;

  .pupop-title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    margin-bottom: 7px;
    padding-left: 4px;
    border-bottom: 1px solid #A1A6BB;
    box-sizing: border-box;
    color: #666666;
    font-size: 16px;
    box-sizing: border-box;
  }

  .pupop-close {
    position: absolute;
    top: 14px;
    right: 14px;
    color: #707070;
    font-size: 20px;
    cursor: pointer;
  }

  .pupop-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 80px);

    &::-webkit-scrollbar {
      width: 0;
    }

    .list {
      width: 100%;
      margin-bottom: 10px;
      color: #999999;
      font-size: 12px;

      .icon {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #1269B7;
      }

      .blue {
        margin-right: 8px;
        color: #1269B7;
      }

      .red {
        margin-right: 8px;
        color: #FF0000;
      }
    }

    .list1 {
      display: flex;
      align-items: center;

      .span {
        width: 70px;
        white-space: nowrap;
      }

      /deep/.el-input__inner {
        height: 30px;
        line-height: 30px;
        border-radius: 0;
        border: none;
        border-bottom: 1px solid #A1A6BB;
        color: #333;
      }

      .el-select,
      .el-input {
        width: calc(100% - 70px);
      }

      /deep/.el-input__icon {
        height: 30px;
        line-height: 30px;
      }
    }
  }
}
</style>
