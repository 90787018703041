<template>
  <div class="son">
    <div class="son-head">
      <div class="son-title">
        <span>完善居民档案</span>
      </div>
      <el-button @click="go">返回</el-button>
    </div>
    <div class="son-box">
      <div class="son-label">
        <span>居民信息</span>
      </div>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm">
        <el-form-item label="居民编号">
          <span>{{ ruleForm.jkdnid }}</span>
        </el-form-item>
        <el-form-item label="姓名" prop="name">
          <el-input clearable v-model="ruleForm.name" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="居民头像">
          <div class="son-update">
            <el-image :src="ruleForm.jmtx" fit="contain">
              <div slot="error" class="image-slot">
                <i class="el-icon-picture-outline son-icon"></i>
              </div>
            </el-image>
            <input type="file" @change="onFileChange" style="display: none" ref="fileInput">
            <el-button type="text" @click="takePhoto">
              <span class="el-icon-plus"></span>
              <span>点击上传</span>
            </el-button>
          </div>
        </el-form-item>
        <el-form-item label="身份证号" prop="sfzh">
          <el-input maxlength="18" clearable v-model="ruleForm.sfzh" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="手机号码" prop="sjhm">
          <el-input maxlength="11" clearable v-model="ruleForm.sjhm" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="居民标签" prop="jmbq">
          <el-select v-model="ruleForm.jmbq" multiple collapse-tags placeholder="请选择" class="son-input">
            <el-option
              v-for="item in jmbqs"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="现居地" prop="xzd" class="son-width">
          <el-input clearable v-model="ruleForm.xzd" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="行政区划" prop="xzqy">
          <el-input clearable v-model="ruleForm.xzqy" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="户籍地址" prop="hjdz" class="son-width">
          <el-input clearable v-model="ruleForm.hjdz" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item></el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select clearable v-model="ruleForm.sex" placeholder="请选择" class="son-input">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出生日期" prop="csrq">
          <el-date-picker v-model="ruleForm.csrq" type="date" placeholder="选择日期" class="son-input"></el-date-picker>
        </el-form-item>
        <el-form-item label="籍贯" prop="jg">
          <el-input clearable v-model="ruleForm.jg" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="民族" prop="jmbq">
          <el-select v-model="ruleForm.mz" placeholder="请选择" class="son-input">
            <el-option
              v-for="item in mzs"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="文化程度" prop="jmbq">
          <el-select v-model="ruleForm.whcd" placeholder="请选择" class="son-input">
            <el-option
              v-for="item in whcds"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="婚姻状况" prop="jmbq">
          <el-select v-model="ruleForm.hyqk" placeholder="请选择" class="son-input">
            <el-option
              v-for="item in hyqks"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="职业" prop="zhiye">
          <el-input clearable v-model="ruleForm.zhiye" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="工作单位" prop="gzdw" class="son-width">
          <el-input clearable v-model="ruleForm.gzdw" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="紧急联系人" prop="jjlxr">
          <el-input clearable v-model="ruleForm.jjlxr" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="lxdh">
          <el-input clearable v-model="ruleForm.lxdh" placeholder="请输入" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="dlmm">
          <el-input clearable v-model="ruleForm.dlmm" placeholder="请输入8位以上的数字和字母组合" class="son-input"></el-input>
        </el-form-item>
        <el-form-item label="户籍类型" prop="jmbq">
          <el-select v-model="ruleForm.hjlx" placeholder="请选择" class="son-input">
            <el-option
              v-for="item in hjlxs"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="定点医疗单位" prop="ddyldw" class="son-width">
          <el-input clearable v-model="ruleForm.ddyldw" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="费用类型" prop="fylx" class="son-item">
          <el-checkbox-group v-model="ruleForm.fylx">
            <el-checkbox label="0">自费</el-checkbox>
            <el-checkbox label="1">社会医疗保险</el-checkbox>
            <el-checkbox label="2">商业保险</el-checkbox>
            <el-checkbox label="3">新农合</el-checkbox>
            <el-checkbox label="4">其他</el-checkbox>
            <el-input v-show="ruleForm.fylx.indexOf('4') > -1" clearable v-model="ruleForm.fylxdes" placeholder="请输入" class="son-input"></el-input>
          </el-checkbox-group>
        </el-form-item>
        <div class="son-label">
          <span>健康信息</span>
        </div>
        <el-form-item label="身高" prop="sg">
          <el-input-number v-model="ruleForm.sg" controls-position="right" :step=".1" :min="0" placeholder="请输入"></el-input-number>
          <span>cm</span>
        </el-form-item>
        <el-form-item label="体重" prop="tz">
          <el-input-number v-model="ruleForm.tz" controls-position="right" :step=".1" :min="0" placeholder="请输入"></el-input-number>
          <span>kg</span>
        </el-form-item>
        <el-form-item label="血型" prop="xuexing">
          <el-select v-model="ruleForm.xuexing" placeholder="请选择" class="son-input">
            <el-option
              v-for="item in xxs"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="RH" prop="rh">
          <el-select v-model="ruleForm.rh" placeholder="请选择" class="son-input">
            <el-option
              v-for="item in rhs"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="血压" prop="ssy">
          <el-input-number v-model="ruleForm.ssy" controls-position="right" :min="0" placeholder="请输入" class="son-input1"></el-input-number>
          <span>/</span>
          <el-input-number v-model="ruleForm.szy" controls-position="right" :min="0" placeholder="请输入" class="son-input1"></el-input-number>
        </el-form-item>
        <el-form-item label="空腹血糖" prop="kfxt">
          <el-input-number v-model="ruleForm.kfxt" controls-position="right" :step=".1" :min="0" placeholder="请输入"></el-input-number>
          <span>mmol/L</span>
        </el-form-item>
        <el-form-item label="过敏史" prop="allergyHistory" class="son-item">
          <el-radio-group v-model="ruleForm.allergyHistory">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
            <el-input v-show="ruleForm.allergyHistory === '2'" clearable v-model="ruleForm.gmsDes" placeholder="请输入" class="son-input"></el-input>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="既往史" prop="jwsjb" class="son-item">
          <el-radio-group v-model="ruleForm.jwsjb">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
            <el-input v-show="ruleForm.jwsjb === '2'" clearable v-model="ruleForm.jwsDes" placeholder="请输入" class="son-input"></el-input>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="就诊史" prop="jzszn" class="son-item">
          <el-radio-group v-model="ruleForm.jzszn">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
            <el-input v-show="ruleForm.jzszn === '2'" clearable v-model="ruleForm.jzsDes" placeholder="请输入" class="son-input"></el-input>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="家族病史" prop="familyHistory" class="son-item">
          <el-radio-group v-model="ruleForm.familyHistory">
            <el-radio label="1">无</el-radio>
            <el-radio label="2">有</el-radio>
            <el-input v-show="ruleForm.familyHistory === '2'" clearable v-model="ruleForm.jzbsDes" placeholder="请输入" class="son-input"></el-input>
          </el-radio-group>
        </el-form-item>
        <el-form-item class="son-item">
          <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      jmbqs: Object.freeze([
        { label: '慢病护理', value: '1' },
        { label: '高血压', value: '2' },
        { label: '高血糖', value: '3' }
      ]),
      mzs: Object.freeze([
        { label: '汉族', value: '01' },
        { label: '少数民族', value: '99' }
      ]),
      whcds: Object.freeze([
        { label: '研究生', value: '1' },
        { label: '大学本科', value: '2' },
        { label: '大学专科和专科学校', value: '3' },
        { label: '中等专业校', value: '4' },
        { label: '技工学校', value: '5' },
        { label: '高中', value: '6' },
        { label: '初中', value: '7' },
        { label: '小学', value: '8' },
        { label: '文盲及半文盲', value: '9' },
        { label: '不详', value: '10' }
      ]),
      hyqks: Object.freeze([
        { label: '未婚', value: '1' },
        { label: '已婚', value: '2' },
        { label: '丧偶', value: '3' },
        { label: '离婚', value: '4' },
        { label: '未说明的婚姻状况', value: '5' }
      ]),
      hjlxs: Object.freeze([
        { label: '农村', value: '1' },
        { label: '城镇', value: '2' }
      ]),
      xxs: Object.freeze([
        { label: 'A型', value: '1' },
        { label: 'B型', value: '2' },
        { label: 'O型', value: '3' },
        { label: 'AB型', value: '4' },
        { label: '不详', value: '5' }
      ]),
      rhs: Object.freeze([
        { label: '阴性', value: '1' },
        { label: '阳性', value: '2' },
        { label: '不详', value: '3' }
      ]),
      ruleForm: {
        jkdnid: '20240908900',
        name: '',
        jmtx: '',
        sfzh: '',
        sjhm: '',
        jmbq: '',
        xzd: '',
        xzqy: '',
        hjdz: '',
        sex: '',
        csrq: '',
        jg: '',
        mz: '',
        whcd: '',
        zhiye: '',
        gzdw: '',
        jjlxr: '',
        lxdh: '',
        dlmm: '',
        hjlx: '',
        ddyldw: '',
        fylx: [],
        fylxdes: '',
        sg: '',
        tz: '',
        xuexing: '',
        rhs: '',
        ssy: '',
        szy: '',
        kfxt: '',
        allergyHistory: '',
        gmsDes: '',
        jwsjb: '',
        jwsDes: '',
        jzszn: '',
        jzsDes: '',
        familyHistory: '',
        jzbsDes: ''
      },
      rules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[\u4E00-\u9FA5]{2,5}$/.test(value))) {
                callback(new Error('请输入汉字'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        sfzh: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value))) {
                callback(new Error('请输入正确身份证号'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        sjhm: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/.test(value))) {
                callback(new Error('请输入正确手机号'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        dlmm: [
          {
            validator: (rule, value, callback) => {
              if (!(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/.test(value))) {
                callback(new Error('请输入8位以上的数字和字母组合'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    go () {
      this.$router.go(-1)
    },
    takePhoto () {
      this.$refs.fileInput.click()
    },
    onFileChange (e) {
      const file = e.target.files[0]
      if (!file) return
      const reader = new FileReader()
      reader.onload = (e) => {
        const dataURL = e.target.result
        // console.log(dataURL)
        this.ruleForm.jmtx = dataURL
      }
      reader.readAsDataURL(file)
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    improve () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/improveresidentfiles'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f4f8fb;
    box-sizing: border-box;

    .son-title {
      display: flex;
      align-items: center;
      font-size: 18px;

      &::before {
        display: block;
        content: '';
        width: 6px;
        height: 20px;
        margin-right: 10px;
        background-color: rgba(0, 0, 255, 1);
        border-radius: 8px;
      }
    }
  }

  .son-box {
    overflow: hidden scroll;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .son-label {
      width: 100%;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
    }

    .el-form {
      display: flex;
      flex-wrap: wrap;
    }

    .el-form-item {
      width: calc(30% - 50px);
      margin-right: 50px;
    }

    .son-update {
      display: flex;
      align-items: center;

      .el-image {
        width: 40px;
        height: 40px;
      }

      .image-slot {
        width: 40px;
        height: 40px;

        .son-icon {
          color: gray;
          font-size: 30px;
        }
      }
    }

    .son-item {
      width: 100%;

      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
    }

    .son-width {
      width: calc(60% - 50px);
    }

    .son-input {
      width: 200px;
    }

    .son-input1 {
      width: 100px;
    }

    /deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
      display: none;
    }
    /deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label::after {
      content: '*';
      color: #F56C6C;
    }
  }
}
</style>
