<template>
  <div class="son">
    <div class="son-title">
      <span>管理路径</span>
    </div>
    <div class="son-search">
      <div class="son-search-item">
        <div class="son-search-key">
          <span>病种类型</span>
        </div>
        <el-select v-model="params.bzlx" placeholder="请选择">
          <el-option v-for="item in bzlxs" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>路径状态</span>
        </div>
        <el-select v-model="params.ljzt" placeholder="请选择">
          <el-option v-for="item in ljzts" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <el-input v-model="params.gjz" placeholder="请输入关键字" suffix-icon="el-icon-search"></el-input>
      </div>
      <div class="son-search-item">
        <el-button type="primary" plain>查询</el-button>
      </div>
      <div class="son-search-item son-search-item1">
        <el-button type="primary">新增</el-button>
      </div>
    </div>
    <div class="son-box">
      <el-table :data="tableData" border stripe height="100%">
        <el-table-column prop="name" label="编号">
        </el-table-column>
        <el-table-column prop="address" label="路径名称">
        </el-table-column>
        <el-table-column prop="address" label="病种">
        </el-table-column>
        <el-table-column prop="address" label="科室">
        </el-table-column>
        <el-table-column prop="address" label="状态">
        </el-table-column>
        <el-table-column prop="address" label="创建人">
        </el-table-column>
        <el-table-column prop="address" label="审核人">
        </el-table-column>
        <el-table-column prop="address" label="创建时间">
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="see(scope)">查看</el-button>
            <el-button type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" class="del">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="son-pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      bzlxs: Object.freeze([{
        value: '1',
        label: '全部'
      }, {
        value: '2',
        label: '高血压'
      }, {
        value: '3',
        label: '糖尿病'
      }]),
      ljzts: Object.freeze([{
        value: '1',
        label: '待审核'
      }, {
        value: '2',
        label: '使用中'
      }, {
        value: '3',
        label: '停用'
      }]),
      params: {
        bzlx: '',
        gjz: '',
        ljzt: ''
      },
      tableData: [],
      showDetailedInfo: false,
      currentPage: 1
    }
  },
  methods: {
    see () {},
    del () {},
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 18px;

    &::before {
      display: block;
      content: '';
      width: 6px;
      height: 20px;
      margin-right: 10px;
      background-color: rgba(0, 0, 255, 1);
      border-radius: 8px;
    }
  }

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 140px;

    .son-search-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
      padding-right: 10px;
      box-sizing: border-box;

      .son-search-key {
        margin-right: 10px;
        white-space: nowrap;
        color: #999999;
        font-size: 16px;
      }

      .el-button {
        margin-left: 10px;
      }
    }

    .son-search-item1 {
      width: 50%;
    }
  }

  .son-box {
    width: 100%;
    height: calc(100% - 270px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .del {
      color: red;
    }

    .green {
      color: #28D094;
    }

    .gray {
      color: #999999;
    }

    .blue {
      color: #0000FF;
    }
  }

  .son-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    /deep/.el-pager li {
      margin-left: 5px;
      margin-right: 5px;
      background-color: rgba(242, 247, 251, 1);
    }

    /deep/.el-pager li.active {
      background-color: rgba(0, 15, 255, 1);
      color: #fff;
    }
  }
}
</style>
