<template>
  <div class="page">
    <div class="switch">
      <span class="item item1">院外设备清单</span>
    </div>
    <div class="content border-none">
      <div class="line border-none">
        <div class="title">
          <span>患者总览</span>
        </div>
        <div class="count">
          <div class="count-item">
            <img src="../../../img/ssjk/count1.png" alt="本周激活">
            <span>本周激活1人</span>
          </div>
          <div class="count-item">
            <img src="../../../img/ssjk/count2.png" alt="本月激活">
            <span>本月激活0人</span>
          </div>
          <div class="count-item">
            <img src="../../../img/ssjk/count3.png" alt="总激活">
            <span>总激活1人</span>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="search">
          <div class="search-left">
            <el-input class="width" placeholder="设备SN号" suffix-icon="el-icon-search"></el-input>
            <el-select v-model="value" placeholder="品牌">
              <el-option label="1" value="1">
              </el-option>
              <el-option label="2" value="2">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="类型">
              <el-option label="1" value="1">
              </el-option>
              <el-option label="2" value="2">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="型号">
              <el-option label="1" value="1">
              </el-option>
              <el-option label="2" value="2">
              </el-option>
            </el-select>
            <el-select v-model="value" placeholder="科室">
              <el-option label="1" value="1">
              </el-option>
              <el-option label="2" value="2">
              </el-option>
            </el-select>
            <el-button class="margin-left" size="small" type="primary">搜索</el-button>
            <el-button size="small" type="info">重置</el-button>
            <el-switch v-model="isSwitch" active-color="#30F05D" inactive-color="#30F05D" active-text="在院设备"
              inactive-text="已激活设备" class="margin-left">
            </el-switch>
          </div>
          <div class="search-right" @click="openAddDevice">
            <span class="el-icon-plus"></span>
          </div>
        </div>
        <el-table v-if="!isSwitch" :data="tableData" height="300" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="设备类型">
          </el-table-column>
          <el-table-column prop="date" label="型号">
          </el-table-column>
          <el-table-column prop="date" label="设备SN号">
          </el-table-column>
          <el-table-column prop="type" label="出厂日期">
          </el-table-column>
          <el-table-column prop="type1" label="入库日期科室">
          </el-table-column>
          <el-table-column prop="type2" label="所属科室">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex flex1">
                <span @click="openSeeData">查看数据</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-table v-else-if="isSwitch" :data="tableData" height="300" :header-cell-style="{ background: '#024276' }">
          <el-table-column prop="name" label="设备类型">
          </el-table-column>
          <el-table-column prop="date" label="型号">
          </el-table-column>
          <el-table-column prop="date" label="设备SN号">
          </el-table-column>
          <el-table-column prop="type" label="出厂日期">
          </el-table-column>
          <el-table-column prop="type1" label="绑定日期">
          </el-table-column>
          <el-table-column prop="type2" label="激活日期">
          </el-table-column>
          <el-table-column prop="type2" label="所属科室">
          </el-table-column>
          <el-table-column prop="type2" label="使用者">
          </el-table-column>
          <el-table-column prop="type2" label="状态">
          </el-table-column>
          <el-table-column label="操作">
            <template>
              <!-- slot-scope="scope" -->
              <div class="flex flex1">
                <span @click="openSeeData">查看数据</span>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="pagination">
          <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4"
            background :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper"
            :total="400">
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 新增设备 -->
    <AddDevice v-if="showAddDevice" @close="closeAddDevice"></AddDevice>
    <!-- 查看数据 -->
    <SeeData v-if="showSeeData" @close="closeSeeData"></SeeData>
  </div>
</template>

<script>
import AddDevice from './addDevice.vue'
import SeeData from './seeData.vue'
export default {
  components: {
    AddDevice,
    SeeData
  },
  data () {
    return {
      tableData: [{
        name: '111'
      }],
      value: '',
      isSwitch: false,
      options: [{
        value: '',
        label: ''
      }],
      showAddDevice: false,
      showSeeData: false
    }
  },
  methods: {
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    },
    openAddDevice () {
      this.showAddDevice = true
    },
    closeAddDevice () {
      this.showAddDevice = false
    },
    openSeeData () {
      this.showSeeData = true
    },
    closeSeeData () {
      this.showSeeData = false
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: relative;
  width: 100%;
  height: 100%;
  padding: .25rem;
  box-sizing: border-box;

  .switch {
    display: flex;
    width: 100%;
    height: .5rem;

    .item {
      height: .5rem;
      line-height: .5rem;
      margin-right: .25rem;
      padding: 0 .125rem;
      text-align: center;
      color: #fff;
      font-size: .175rem;
      background-color: rgba(115, 115, 115, .4);
      cursor: pointer;
    }

    .item1 {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .content {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: calc(100% - .625rem);
    margin-top: .125rem;
    border-bottom: .05rem solid #073F73;
    box-sizing: border-box;
    border-bottom: none;

    &::-webkit-scrollbar {
      width: 0;
    }

    .line {
      width: 100%;
      padding: .25rem;
      border: .05rem solid #073F73;
      box-sizing: border-box;

      .title {
        color: #01EEFD;
        font-size: .25rem;
      }

      .count {
        display: flex;
        align-items: center;
        width: 100%;
        height: .5rem;
        margin-top: .125rem;

        .count-item {
          display: flex;
          align-items: center;
          width: 4.5rem;
          height: .5rem;
          margin-right: .25rem;
          background: linear-gradient(79deg, #0A477B 0%, #0E67AD 100%);
          color: #fff;
          font-size: .225rem;

          img {
            width: .375rem;
            height: .375rem;
            margin: 0 .25rem;
          }
        }
      }

      .search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-bottom: .125rem;

        .width {
          width: 3.075rem;
        }

        .el-select {
          width: 1.5rem;
          margin-left: .125rem;
        }

        .search-left {
          display: flex;
          align-items: center;

          .margin-left {
            margin-left: .125rem;
          }

          //按钮文字显示
          .el-switch {
            // height: .3125rem !important;
          }

          ::v-deep .el-switch__core {
            // width: 1.625rem !important;
            height: .3rem;
            padding-left: .375rem;
            padding-right: 1.125rem;
            border-radius: 1.25rem;
            border: none;
          }

          ::v-deep .el-switch__core::after {
            width: .25rem;
            height: .25rem;
            top: .025rem;
          }

          ::v-deep .el-switch.is-checked .el-switch__core::after {
            margin-left: -0.2625rem;
          }

          /*关闭时文字位置设置*/
          ::v-deep .el-switch__label--right {
            position: absolute;
            z-index: 1;
            right: .075rem;
            margin-left: 0rem;
            color: rgba(255, 255, 255, 0.9019607843137255);

            span {
              font-size: .2rem;
            }
          }

          /* 激活时另一个文字消失 */
          ::v-deep .el-switch__label.is-active {
            display: none;
          }

          /*开启时文字位置设置*/
          ::v-deep .el-switch__label--left {
            position: absolute;
            z-index: 1;
            left: .0625rem;
            margin-right: 0rem;
            color: rgba(255, 255, 255, 0.9019607843137255);

            span {
              font-size: .2rem;
            }
          }
        }

        .search-right {
          width: .375rem;
          height: .375rem;
          line-height: .375rem;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: .225rem;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }

    .border-none {
      border-bottom: none;
    }

    ::v-deep .el-table__body-wrapper {
      &::-webkit-scrollbar {
        width: 0rem;
      }
    }

    ::v-deep .el-table th.gutter {
      display: none;
      width: 0;
    }

    ::v-deep .el-table colgroup col[name='gutter'] {
      display: none;
      width: 0;
    }

    ::v-deep .el-table__body {
      width: 100% !important;
    }

    .el-table {
      background-color: #092B50;
      border: .05rem #073F73 solid;
    }

    // 表头文字颜色
    /deep/.el-table__header-wrapper {
      thead {
        th {
          div {
            color: #01EEFD;
            font-size: .2125rem;
            font-weight: 400;
          }
        }
      }
    }

    //表格内容颜色
    /deep/.el-table__body tbody tr {
      color: #fff;
      background-color: #092B50;
    }

    /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
      background-color: #092B50;
    }

    /deep/.el-table tbody tr:hover>td {
      background-color: transparent !important
    }

    // 改变边框颜色
    .el-table--border,
    .el-table--group {
      border: .025rem solid #ddd !important;
    }

    /**
  改变表格内竖线颜色
   */
    .el-table--border td,
    .el-table--border th,
    .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
      border-right: .0125rem solid #ddd !important;
    }

    /deep/.el-table td {
      border-bottom: .0125rem solid #01EEFD;
    }

    /deep/.el-table thead tr th {
      border-color: transparent;
    }

    /deep/ .el-table--border::after,
    /deep/ .el-table--group::after,
    /deep/ .el-table::before {
      background-color: #073F73;
    }

    /deep/.el-table--border {
      border: .0125rem solid #073F73 !important;
    }

    /deep/.el-table .cell {
      text-align: center;
      color: #01EEFD;
    }

    /deep/.el-checkbox__inner {
      background-color: transparent;
    }

    ::v-deep .el-table__empty-text {
      color: #01EEFD;
    }

    .pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: .5rem;

      ::v-deep.el-pagination__total,
      ::v-deep.el-pagination__jump {
        color: #01EEFD;

        .el-input__inner {
          color: #fff;
          background-color: transparent;
        }
      }

      ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #01EEFD;
      }
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      color: #0e75d4;
      font-size: .1875rem;

      span {
        cursor: pointer;
      }
    }

    .flex1 {
      justify-content: center;
    }

    .content-box {
      width: 100%;
      height: 100%;
      padding: .25rem;
      border: .05rem solid #073F73;
      box-sizing: border-box;

      .content-box-search {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: .25rem;

        .left {
          display: flex;
          align-items: center;

          .search-input {
            width: 3.25rem;
          }

          .el-select {
            width: 2.5rem;
            margin-left: .125rem;
          }

          .date {
            margin-left: .125rem;
          }

          .blue,
          .gray {
            margin-left: .5rem;
            background-color: #04B4D0;
            color: #fff;
            border: none;
            border-radius: 0;
          }

          .gray {
            background-color: #284867;
          }
        }

        .right {
          width: .375rem;
          height: .375rem;
          line-height: .375rem;
          text-align: center;
          border-radius: 50%;
          background-color: #04B4D0;
          color: #fff;
          font-size: .225rem;
          font-weight: 600;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
