import { render, staticRenderFns } from "./outHospitalScreen.vue?vue&type=template&id=0deabbb4&scoped=true&"
import script from "./outHospitalScreen.vue?vue&type=script&lang=js&"
export * from "./outHospitalScreen.vue?vue&type=script&lang=js&"
import style0 from "./outHospitalScreen.vue?vue&type=style&index=0&id=0deabbb4&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0deabbb4",
  null
  
)

export default component.exports