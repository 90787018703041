<template>
  <div class="children">
    <div class="children-left">
      <UserBasicInfo></UserBasicInfo>
    </div>
    <div class="children-right">
      <div class="children-title">
        <span>2型糖尿病患者随访服务记录表</span>
      </div>
      <div class="code">编号：</div>
      <!-- <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
    </el-input> -->
      <div class="children-info">
        <div class="item">
          <span>姓名：{{ info.name || '' }}</span>
          <span class="el-icon-circle-plus white white1" @click="add"></span>
          <span class="el-icon-edit white white1"></span>
          <span class="el-icon-delete-solid white white1" @click="reduce"></span>
        </div>
        <div class="item">
          <span class="el-icon-caret-left white blue" @click="left"></span>
          <span class="el-icon-caret-right white" @click="right"></span>
        </div>
        <el-date-picker size="small" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期">
        </el-date-picker>
        <div class="item">
          <span class="key">是否完整</span>
          <el-select size="small" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="key">二次随访</span>
          <el-select size="small" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>

      <div class="children-scroll">
        <div class="children-table border-bottom-none">
          <div class="table-left table-item1">
            <div class="table-item table-center1">
              <span>随访时间</span>
            </div>

            <div class="table-item table-center1">
              <span>随访方式</span>
            </div>

            <div class="table-item table-item-height">
              <div class="table-item-left">
                <span>症状</span>
              </div>
              <div class="table-item-right">
                <el-checkbox-group v-model="checks">
                  <el-checkbox label="0" class="margin-bottom">无症状</el-checkbox>
                  <el-checkbox label="1" class="margin-bottom">多饮</el-checkbox>
                  <el-checkbox label="2" class="margin-bottom">多食</el-checkbox>
                  <el-checkbox label="3" class="margin-bottom">多尿</el-checkbox>
                  <el-checkbox label="4" class="margin-bottom">视力模糊</el-checkbox>
                  <el-checkbox label="5" class="margin-bottom">感染</el-checkbox>
                  <el-checkbox label="6" class="margin-bottom">手脚麻木</el-checkbox>
                  <el-checkbox label="8" class="margin-bottom">下肢水肿</el-checkbox>
                </el-checkbox-group>
              </div>
            </div>

            <div class="table-item table-item-height table-item-height3">
              <div class="table-item-left">
                <span>体征</span>
              </div>
              <div class="table-item-right">
                <div class="table-item table-center1 border-right-none">
                  <span>血压（mmHg）</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>体重（kg）</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>体质指数（BMI）（kg/m2）</span>
                </div>
                <div class="table-item table-center1 border-right-none table-item-height5">
                  <span>足背动脉搏动</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>其他</span>
                </div>
              </div>
            </div>

            <div class="table-item table-item-height table-item-height3">
              <div class="table-item-left">
                <span>生活方式指导</span>
              </div>
              <div class="table-item-right">
                <div class="table-item table-center1 border-right-none">
                  <span>日吸烟量（支）</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>日饮酒量（两）</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>运动</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>主食（克/天）</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>心理调整</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>遵医行为</span>
                </div>
              </div>
            </div>

            <div class="table-item table-item-height table-item-height6">
              <div class="table-item-left">
                <span>辅助检查</span>
              </div>
              <div class="table-item-right">
                <div class="table-item table-center1 border-right-none">
                  <span>空腹血糖值</span>
                </div>
                <div class="table-item table-center1 border-right-none table-item-height5">
                  <span>其他检查</span>
                </div>
              </div>
            </div>

            <div class="table-item table-center1">
              <span>服药依从性</span>
            </div>

            <div class="table-item table-center1">
              <span>药物不良反应</span>
            </div>

            <div class="table-item table-center1">
              <span>低血糖反应</span>
            </div>

            <div class="table-item table-center1 table-item-height5">
              <span>此次随访分类</span>
            </div>

            <div class="table-item table-item-height table-item-height4">
              <div class="table-item-left">
                <span>用药情况</span>
              </div>
              <div class="table-item-right">
                <div class="table-item table-center1 border-right-none">
                  <span>药物名称1</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>用法用量</span>
                </div>
                <div class="table-item table-center1 border-right-none">
                  <span>药物名称2</span>
                </div>
                <div class="table-item table-center1 border-right-none border-bottom-none">
                  <span>用法用量</span>
                </div>
              </div>
            </div>

            <div class="table-item table-item-height table-item-height5 border-bottom-none">
              <div class="table-item-left">
                <span>转诊</span>
              </div>
              <div class="table-item-right">
                <div class="table-item table-center1 border-right-none">
                  <span>原因</span>
                </div>
                <div class="table-item table-center1 border-right-none border-bottom-none">
                  <span>机构及科别</span>
                </div>
              </div>
            </div>

          </div>

          <!-- 滚动区域 -->
          <div class="table-right table-right1">
            <div class="table-right-box" ref="scrollRef">
              <div class="pointer" ref="pointerRef" :class="{ select: index === checkIndex }" v-for="(item, index) in list" :key="index" @click="select(index)">
                <div class="table-item table-center">
                  <span>{{ info.followupDate || '' }}</span>
                </div>
                <div class="table-item table-center">
                  <el-radio-group v-model="info.followupMode">
                    <el-radio label="1">门诊</el-radio>
                    <el-radio label="2">家庭</el-radio>
                    <el-radio label="3">电话</el-radio>
                  </el-radio-group>
                </div>
                <div class="table-item table-item-height table-item-column">
                  <div class="table-item-height1 table-center">
                    <el-checkbox-group v-model="info.symptom" @change="change">
                      <el-checkbox label="1" class="margin-bottom">无症状</el-checkbox>
                      <el-checkbox label="2" class="margin-bottom" :disabled="info.symptom.includes('1')">多饮</el-checkbox>
                      <el-checkbox label="3" class="margin-bottom" :disabled="info.symptom.includes('1')">多食</el-checkbox>
                      <el-checkbox label="4" class="margin-bottom" :disabled="info.symptom.includes('1')">多尿</el-checkbox>
                      <el-checkbox label="5" class="margin-bottom" :disabled="info.symptom.includes('1')">视力模糊</el-checkbox>
                      <el-checkbox label="6" class="margin-bottom" :disabled="info.symptom.includes('1')">感染</el-checkbox>
                      <el-checkbox label="7" class="margin-bottom" :disabled="info.symptom.includes('1')">手脚麻木</el-checkbox>
                      <el-checkbox label="8" class="margin-bottom" :disabled="info.symptom.includes('1')">下肢水肿</el-checkbox>
                    </el-checkbox-group>
                  </div>
                </div>
                <div class="table-item table-item-height3 table-item-column border-bottom-none">
                  <div class="table-item table-width table-center">{{ info.bloodLeft || '' }} / {{ info.bloodRight || '' }}</div>
                  <div class="table-item table-width table-center">{{ info.weight || '' }}</div>
                  <div class="table-item table-width table-center">{{ info.bmi || '' }}</div>
                  <div class="table-item table-width table-item-height5">
                    <el-radio-group v-model="info.zbdmbd" class="table-item-column group">
                      <el-radio label="1" class="margin-top">触及正常</el-radio>
                      <el-radio label="2" class="margin-top">减弱（双侧 左侧 右侧）</el-radio>
                      <el-radio label="3" class="margin-top">消失（双侧 左侧 右侧）</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="table-item table-width table-center">{{ info.other || '' }}</div>
                </div>

                <div class="table-item table-item-height3 table-item-column border-bottom-none">
                  <div class="table-item table-width table-center">{{ info.smoking || '' }}</div>
                  <div class="table-item table-width table-center">{{ info.drinking || '' }}</div>
                  <div class="table-item table-width table-center">
                    <span class="color">{{ info.motionWeek || '' }}次/周分钟</span>
                  </div>
                  <div class="table-item table-width table-center">
                    {{ info.stapleFood || '' }}
                  </div>
                  <div class="table-item table-width table-center">
                    <el-radio-group v-model="info.psy">
                      <el-radio label="1">良好</el-radio>
                      <el-radio label="2">一般</el-radio>
                      <el-radio label="3">差</el-radio>
                    </el-radio-group>
                  </div>
                  <div class="table-item table-width table-center">
                    <el-radio-group v-model="info.respect">
                      <el-radio label="1">良好</el-radio>
                      <el-radio label="2">一般</el-radio>
                      <el-radio label="3">差</el-radio>
                    </el-radio-group>
                  </div>
                </div>

                <div class="table-item table-center table-item-height6 table-item-column border-bottom-none">
                  <div class="table-item table-width"></div>
                  <div class="table-item table-width table-item-height5">
                    <div class="table-item table-width table-center border-bottom-none">
                      <span>糖化血红蛋白 {{ info.bloodSugarKf || '' }} ％</span>
                    </div>
                    <div class="table-item table-width table-center">
                      <span>检查日期： 月 日</span>
                    </div>
                  </div>
                </div>

                <div class="table-item table-center">
                  <el-radio-group v-model="info.medicine">
                    <el-radio label="1">规律</el-radio>
                    <el-radio label="2">间断</el-radio>
                    <el-radio label="3">不服药</el-radio>
                  </el-radio-group>
                </div>

                <div class="table-item table-center">
                  <el-radio-group v-model="info.adverseFlag">
                    <el-radio label="1">无</el-radio>
                    <el-radio label="2">有</el-radio>
                  </el-radio-group>
                </div>

                <div class="table-item table-center">
                  <el-radio-group v-model="info.hypoglycemi">
                    <el-radio label="1">无</el-radio>
                    <el-radio label="2">偶尔</el-radio>
                    <el-radio label="3">频繁</el-radio>
                  </el-radio-group>
                </div>

                <div class="table-item table-center table-item-height5">
                  <el-radio-group v-model="info.followupClassify" class="group">
                    <el-radio label="1" class="margin-top">控制满意</el-radio>
                    <el-radio label="2" class="margin-top">控制不满意</el-radio>
                    <el-radio label="3" class="margin-top">频繁</el-radio>
                    <el-radio label="4" class="margin-top">不良反应</el-radio>
                    <el-radio label="5" class="margin-top">并发症</el-radio>
                  </el-radio-group>
                </div>

                <div class="table-item table-item-height4 table-item-column">
                  <div class="table-item table-width"></div>
                  <div class="table-item table-width table-item-row">
                    <div class="table-item table-item1 table-center">
                      <span>每日 次</span>
                    </div>
                    <div class="table-item table-item1 table-center border-right-none">
                      <span>每日 次</span>
                    </div>
                  </div>
                  <div class="table-item table-width"></div>
                  <div class="table-item table-width table-item-row border-bottom-none">
                    <div class="table-item table-item1 table-center border-bottom-none">
                      <span>每日 次</span>
                    </div>
                    <div class="table-item table-item1 table-center border-right-none border-bottom-none">
                      <span>每日 次</span>
                    </div>
                  </div>
                </div>

                <div class="table-item table-center table-item-height5 table-item-column border-bottom-none">
                  <div class="table-item table-width"></div>
                  <div class="table-item table-width border-bottom-none"></div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="children-table border-bottom-none">
          <div class="table-left table-item1">
            <div class="table-item table-center1 border-bottom-none">
              <span>下次随访时间：</span>
            </div>
          </div>
          <div class="table-right table-right1">
            <div class="table-item1 table-text">
              {{ info.nextFollowDate || '' }}
            </div>
          </div>
        </div>

        <div class="children-table">
          <div class="table-left table-item1">
            <div class="table-item table-center1 border-bottom-none">
              <span>随访医生签名：</span>
            </div>
          </div>
          <div class="table-right table-right1">
            <div class="table-item1"></div>
          </div>
        </div>
      </div>

      <div class="btns">
        <el-button size="mini" @click="close">取消</el-button>
        <el-button class="blue" size="mini" @click="submit">确定</el-button>
      </div>
    </div>

  </div>
</template>

<script>
import UserBasicInfo from '../../../../components/user-basic-info/user-basic-info.vue'
export default {
  props: {
    visitInfo: {
      type: Object,
      default () { }
    }
  },
  components: {
    UserBasicInfo
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['新生儿随访', '1-8月龄', '12-30 月龄', '3-6岁', '男童生长监测图', '女童生长监测图']),
      sex: '0',
      checks: [],
      checkIndex: -1,
      options: [{
        value: '',
        label: ''
      }],
      value: '',
      list: ['1', '2', '3', '4', '5'],
      pageNumber: 1,
      leftScrollWidth: 0,
      info: {
        rqfl: '2',
        name: '',
        diabeteid: '', // 条码
        diabeteId: '',
        hypertenId: '', // 条码
        sfzh: '', // 身份证号
        lxdh: '',
        ybzkyw: '', // 腰围
        jktjid: '', // 类型：Number  必有字段  备注：健康体检id
        followupDate: '', // 随访时间
        followupMode: '1', // 随访方式
        symptom: '', // 症状
        symptomOther: '', // 症状其他
        bloodLeft: '', // 血压左
        bloodRight: '', // 血压右
        height: '', // 类型：String  必有字段  备注：身高
        weight: '', // 体重
        aimWeight: '', // 目标体重
        bmi: '', // 体质指数
        aimBmi: '', // 目标体质指数
        zbdmbd: '', // 足背动脉搏动
        other: '', // 其他
        smoking: '', // 日吸烟量
        aimSmoking: '', // 目标吸烟量
        drinking: '', // 日饮酒量
        aimDrinking: '', // 目标饮酒量
        motionWeek: '', // 次/周
        motionMinute: '', // 分钟/次
        motionAimWeek: '', // 运动-目标-周
        motionAimMinute: '', // 运动-目标-分钟
        stapleFood: '', // 主食(克/天)
        aimStapleFood: '', // 目标主食(克/天)
        psy: '1', // 心态调整
        respect: '1', // 遵医行为
        bloodSugarKf: '', // 空腹血糖值
        bloodSugarDb: '', // 糖化血红蛋白
        otherCheckDate: '', // 其他检查
        medicines: [], // 用药情况
        medicine: '1', // 服药依从性
        adverseFlag: '1', // 药物不良反应
        hypoglycemi: '1', // 低血糖反应
        insulinClass: '', // 胰岛素种类
        insulinUse: '', // 胰岛素用法用量

        assist: '', // 辅助检查
        salt: '1', // 摄盐情况
        aimSalt: '', // 目标摄盐量
        followupClassify: '1', // 此次随访类型
        checkDate: '', // 检查日期
        adverseReason: '', // 类型：String  必有字段  备注：不良反应其他

        zzyj: '', // 转诊建议
        referralReason: '', // 原因
        referralDept: '', // 机构及科别
        nextFollowDate: '', // 下次随访日期
        loss: '', // 类型：String  必有字段  备注：是否失访
        createdate: '', // 类型：String  必有字段  备注：录入时间
        creator: '', // 类型：String  必有字段  备注：录入人
        updator: '', // 类型：String  必有字段  备注：更新人
        updatedate: '', // 类型：String  必有字段  备注：更新时间
        deptname: '', // 类型：String  必有字段  备注：录入科室（机构
        hypertenid: '',
        bloodSugarCh: '', // 餐后血糖
        jbxxid: '',
        jgcmhid: '',
        jgxzjdid: '',
        sfjgbh: '',
        sfryid: '',
        sfry: '',
        xybglcs: '01', // 下一步管理措施
        referralcode: '0', // 转诊建议
        referraljg: '', // 转诊机构
        referraljgbm: '', // 转诊原因机构编码
        respdoctorname: '', // 随访医生
        sfhyid: '',
        sfyhqm: '',
        tmcode: ''
      }
    }
  },
  created () {
    console.log('选择糖尿病人员信息', this.visitInfo)
    this.getInfo()
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    select (index) {
      if (index === this.checkIndex) {
        this.checkIndex = -1
      } else {
        this.checkIndex = index
      }
    },
    add () {
      this.list.push(this.list.length + 4 + '')
      console.log(this.list)
    },
    reduce () {
      this.list = this.list.filter(item => item !== this.checkIndex + 1 + '')
      this.checkIndex = -1
    },
    left () {
      console.log(this.list.length - this.pageNumber)
      if (this.pageNumber < 2) return console.log('无法向左滚动')
      this.pageNumber--
      console.log(this.pageNumber)
      this.$refs.scrollRef.style.cssText = 'transform: translateX(-' + this.$refs.pointerRef[0].clientWidth * (this.pageNumber - 1) + 'px);'
    },
    right () {
      if (this.list.length - this.pageNumber <= 3) return console.log('无法向右滚动')
      this.$refs.scrollRef.style.cssText = 'transform: translateX(-' + this.$refs.pointerRef[0].clientWidth * this.pageNumber + 'px);'
      this.pageNumber++
    },

    // 获取糖尿病编辑信息
    async getInfo () {
      try {
        const obj = {
          diabeteid: this.visitInfo.diabeteid || '',
          sfzh: this.visitInfo.sfzhm || ''
        }
        const { data: res } = await this.$http.post('/diabeteRecords/findapp', obj)
        console.log('获取糖尿病编辑信息', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
        this.info = res.data
        this.info.symptom = res.data.symptom.split(',')
      } catch (error) {
        console.log('获取糖尿病编辑信息请求失败', error)
      }
    },
    change (e) {
      if (e.includes('1')) {
        this.info.symptom = ['1']
      }
    },
    submit () {
      const list = ['followupDate', 'followupMode', 'symptom', 'bloodLeft', 'bloodRight', 'height', 'weight',
        'aimWeight', 'bmi', 'aimBmi', 'zbdmbd', 'smoking', 'aimSmoking', 'drinking', 'aimDrinking', 'motionWeek',
        'motionMinute', 'motionAimWeek', 'motionAimMinute', 'stapleFood', 'aimStapleFood', 'psy', 'respect',
        'bloodSugarKf', 'adverseFlag', 'hypoglycemi', 'followupClassify', 'referralcode', 'nextFollowDate',
        'xybglcs', 'respdoctorname'
      ]
      const tip = [
        '随访日期', '随访方式', '症状', '收缩压', '舒张压',
        '身高', '目前体重', '目标体重', '当前体质指数',
        '目标体质指数', '足背动脉搏动', '当前日吸烟量', '目标日吸烟量',
        '当前日饮酒量', '目标日饮酒量', '当前运动', '当前运动', '目标运动',
        '目标运动', '当前主食', '目标主食', '心理调整', '遵医行为',
        '空腹血糖', '药物不良反应', '低血糖反应', '此次随访分类', '转诊建议', '下次随访日期', '下一步管理措施', '随访医生'
      ]

      for (var i = 0; i < list.length; i++) {
        if (this.info[list[i]] === '') {
          return this.$message.error(`请完善信息:${tip[i]}为空， 是否继续提交！`)
        }
      }
      if (this.info.symptom && this.info.symptomOther === '') {
        return this.$confirm('请完善信息:症状其他为空， 是否继续提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.appInfo()
        }).catch(() => {})
      }
      if (this.info.medicines.length < 1) {
        return this.$confirm('请完善信息:用药情况为空， 是否继续提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.appInfo()
        }).catch(() => {})
      }
      if (this.info.referralcode === '1' && this.info.referralReason === '') {
        return this.$confirm('请完善信息:转诊原因为空， 是否继续提交?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.appInfo()
        }).catch(() => {})
      }
      this.appInfo()
    },
    // 糖尿病编辑信息保存
    async appInfo () {
      try {
        const { data: res } = await this.$http.post('/api/jkct/app/DiabeteRecord/save', this.info)
        console.log('糖尿病编辑信息保存', res)
        if (res.code !== 0) return this.$message.error(res.msg || res.message)
      } catch (error) {
        console.log('糖尿病编辑信息保存请求失败', error)
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style scoped lang="less">
.children {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #071224;

  .children-left {
    width: 270px;
    height: 100%;
    background-color: #081C38;
  }

  .children-right {
    position: relative;
    width: calc(100% - 290px);
    height: 100%;
    padding: 10px 20px;
    margin-left: 20px;
    box-sizing: border-box;
    background-color: #081C38;

    .code {
      position: absolute;
      top: 34px;
      right: 100px;
      color: #01EEFD;
      font-size: 22px;
    }
  }

  .children-scroll {
    overflow-y: scroll;
    width: 100%;
    height: calc(100% - 180px);

    &::-webkit-scrollbar {
      width: 0;
    }

  }

  .postion {
    position: absolute;
    top: 20px;
    right: 16px;
    width: 246px;
    background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
    border: 1px solid #1F72D3;
    border-radius: 6px;

    /deep/.el-input__inner {
      background-color: transparent;
      color: #fff !important;
      font-size: 18px;
      border: none;

    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: 18px;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .children-title {
    width: 100%;
    height: 54px;
    line-height: 54px;
    text-align: center;
    color: #01EEFD;
    font-size: 22px;
  }

  .children-info {
    display: flex;
    align-items: center;
    width: 100%;
    height: 60px;

    .item {
      margin-right: 20px;
      color: #01EEFD;
      font-size: 22px;

      .key {
        margin: 0 10px;
      }

      .white {
        color: #fff;
        cursor: pointer;
      }

      .white1 {
        margin-left: 20px;
      }

      .blue {
        color: #01EEFD;
      }
    }
  }

  .children-table {
    display: flex;
    width: 100%;
    border: 1px solid #01EEFD;
    box-sizing: border-box;
  }

  .btns {
    display: flex;
    align-items: center;
    height: 80px;

    .el-button {
      border: none;
      border-radius: 0;
      background-color: #33465C;
      color: #fff;
    }

    .blue {
      margin-left: 30px;
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  .table-left {
    display: flex;
    flex-direction: column;
    width: 250px;
  }

  .table-right {
    overflow-x: scroll;
    width: calc(100% - 250px);

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .table-right-box {
      transition: all 0.3s;
    }

    .pointer {
      width: 300px;
      display: inline-block;
      cursor: pointer;
    }
  }

  .table-right1 {

    white-space: nowrap;
  }

  .table-item {
    height: 50px;
    border-right: 1px solid #01EEFD;
    border-bottom: 1px solid #01EEFD;
    color: #01EEFD;
    font-size: 14px;
    box-sizing: border-box;

    .table-item-left {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 45px;
      height: 100%;
      writing-mode: vertical-lr;
      border-right: 1px solid #01EEFD;
      box-sizing: border-box;
    }

    .table-item-right {
      width: calc(100% - 45px);
      height: 100%;

      .el-checkbox-group {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        margin-left: 10px;
      }
    }
  }

  .table-item-height {
    display: flex;
    height: 320px;
  }

  .table-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-center1 {
    display: flex;
    align-items: center;
    padding-left: 14px;
    box-sizing: border-box;
  }

  .table-item1 {
    flex: 1;
  }

  .table-text {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: #01EEFD;
    font-size: 14px;
  }

  .pointer {
    cursor: pointer;
  }

  .select {
    border: 1px solid #fff;
  }

  .table-item5 {
    flex: 5;
  }

  .table-width {
    width: 100%;
    border-right: none;
  }

  .table-item-height1 {
    width: 100%;
    height: 100%;
    padding: 10px;
    box-sizing: border-box;

    .el-checkbox-group {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
    }
  }

  .table-item-height2 {
    width: 100%;
    height: 250px;
  }

  .table-item-height3 {
    width: 100%;
    height: 300px;
  }

  .table-item-height4 {
    width: 100%;
    height: 200px;
  }

  .table-item-height5 {
    height: 100px;
  }

  .table-item-height6 {
    height: 150px;
  }

  .table-item-column {
    display: flex;
    flex-direction: column;
  }

  .table-item-row {
    display: flex;
    flex-direction: row;
  }

  .color {
    color: #fff;
  }

  .border-right-none {
    border-right: none !important;
  }

  .border-bottom-none {
    border-bottom: none !important;
  }

  .group {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
  }

  .margin-top {
    margin-top: 10px;
  }

  .margin-bottom {
    margin-bottom: 10px;
  }

  .el-radio,
  .el-checkbox {
    color: #fff;
  }

  /deep/.el-radio__inner,
  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  /deep/.el-radio__input.is-checked .el-radio__inner,
  /deep/.el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label,
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #01EEFD;
  }

}
</style>
