var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"popup-list"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("姓名："+_vm._s(_vm.info.name || ''))])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("联系方式："+_vm._s(_vm.info.lxfs || ''))])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("设备厂家："+_vm._s(_vm.info.sbcj || ''))])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("设备编号："+_vm._s(_vm.info.ssbbhbcj || ''))])])]),_vm._m(2),_c('div',{staticClass:"popup-list"},[_c('div',{staticClass:"item"},[_c('span',[_vm._v("设备名称："+_vm._s(_vm.info.sbmc || ''))])]),_c('div',{staticClass:"item"},[_c('span',[_vm._v("SIM卡号："+_vm._s(_vm.info.simk || ''))])]),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12)]),_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.close}},[_vm._v("返回")])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-title"},[_c('span',[_vm._v("查看")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-line"},[_c('span',[_vm._v("设备信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"popup-line"},[_c('span',[_vm._v("配置信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("紧急呼叫模式：SOS紧急呼叫模式")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("静音：关")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("计步监测：开")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("睡眠监测：开")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("设备蓝牙：关")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("跌倒监测：开")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("体温上报频率：30分钟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("体温定时测量：30分钟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("心率监测频率：30分钟")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('span',[_vm._v("心率阈值：40-140")])])
}]

export { render, staticRenderFns }