<template>
  <div class="estimate">
    <div class="estimate-list">
      <div class="estimate-title">
        <span>血压水平</span>
      </div>
      <div class="estimate-line">
        <div class="estimate-item">
          <div class="key">
            <span>收缩压</span>
          </div>
          <el-input v-model="info.ssy" class="input"></el-input>
          <div class="unit">
            <span>mmHg</span>
          </div>
        </div>
        <div class="estimate-item">
          <div class="key">
            <span>舒张压</span>
          </div>
          <el-input v-model="info.szy" class="input"></el-input>
          <div class="unit">
            <span>mmHg</span>
          </div>
        </div>
      </div>
    </div>

    <div class="estimate-list">
      <div class="estimate-title">
        <span>危险因素</span>
      </div>
      <div class="estimate-line">
        <div class="estimate-item estimate-item1">
          <div class="key key1">
            <span>心血管病危险因素</span>
          </div>
          <el-input v-model="info.wxys" class="input1"></el-input>
          <div class="button">
            <span>引入</span>
          </div>
        </div>
      </div>
      <div class="estimate-line">
        <div class="estimate-item estimate-item1">
          <div class="key key1">
            <span>靶器官损害</span>
          </div>
          <el-input v-model="info.bqgsh" class="input1"></el-input>
          <div class="button">
            <span>引入</span>
          </div>
        </div>
      </div>

      <div class="estimate-line">
        <div class="estimate-item">
          <div class="key key2">
            <span>糖尿病</span>
          </div>
          <el-radio-group v-model="info.tnb">
            <el-radio label="0">否</el-radio>
            <el-radio label="1">是</el-radio>
            <el-radio label="2">不详</el-radio>
          </el-radio-group>
        </div>

        <div class="estimate-item">
          <div class="key key2">
            <span>脑血管病</span>
          </div>
          <el-radio-group v-model="info.nxgb">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>

        <div class="estimate-item">
          <div class="key key2">
            <span>心脏病</span>
          </div>
          <el-radio-group v-model="info.xzb">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="estimate-line">
        <div class="estimate-item">
          <div class="key key2">
            <span>肾脏病</span>
          </div>
          <el-radio-group v-model="info.szb">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>

        <div class="estimate-item">
          <div class="key key2">
            <span>周围血管病</span>
          </div>
          <el-radio-group v-model="info.zwxgb">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>

        <div class="estimate-item">
          <div class="key key2">
            <span>视网膜病变</span>
          </div>
          <el-radio-group v-model="info.swmbb">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="estimate-line">
        <div class="estimate-item estimate-item1">
          <div class="key key2">
            <span>其他</span>
          </div>
          <el-radio-group v-model="info.qt">
            <el-radio label="0">无</el-radio>
            <el-radio label="1">有</el-radio>
          </el-radio-group>
        </div>
      </div>

    </div>

    <div class="estimate-list">
      <div class="estimate-title">
        <span>总体评估</span>
      </div>
      <div class="estimate-line">
        <div class="estimate-item estimate-item2">
          <div class="key key3">
            <span>血压分级结果</span>
          </div>
          <el-radio-group v-model="info.xyfjjg">
            <el-radio label="0">一级高血压</el-radio>
            <el-radio label="1">二级高血压</el-radio>
            <el-radio label="2">三级高血压</el-radio>
          </el-radio-group>
        </div>

        <div class="estimate-item estimate-item2">
          <div class="key key3">
            <span>危险分层结果</span>
          </div>
          <el-radio-group v-model="info.wxfcjg">
            <el-radio label="0">低危</el-radio>
            <el-radio label="1">中危</el-radio>
            <el-radio label="2">高危</el-radio>
            <el-radio label="3">极高危</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="estimate-line">
        <div class="estimate-item estimate-item2">
          <div class="key key3">
            <span>分级管理结果</span>
          </div>
          <el-radio-group v-model="info.fjgljg">
            <el-radio label="0">一级管理</el-radio>
            <el-radio label="1">二级管理</el-radio>
            <el-radio label="2">三级管理</el-radio>
          </el-radio-group>
        </div>

        <div class="estimate-item estimate-item2">
          <div class="key key3">
            <span>降压效果</span>
          </div>
          <el-radio-group v-model="info.jyxg">
            <el-radio label="0" class="radio">血压正常</el-radio>
            <el-radio label="1" class="radio">血压正常常伴有药物副作用</el-radio>
            <el-radio label="2">血压正常并存有其他疾病</el-radio>
            <el-radio label="3">血压异常</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="estimate-line">
        <div class="estimate-item estimate-item1">
          <div class="key key3">
            <span>总体评价</span>
          </div>
          <el-radio-group v-model="info.ztpj">
            <el-radio label="0" class="radio">血压控制</el-radio>
            <el-radio label="1" class="radio">血压未控制</el-radio>
            <el-radio label="2">血压正常常伴有药物不良反应</el-radio>
            <el-radio label="3">血压未控制常伴有药物不良反应</el-radio>
            <el-radio label="3">血压控制伴靶器官损害</el-radio>
            <el-radio label="4">血压未控制伴靶器官损害</el-radio>
            <el-radio label="5">血压控制伴临床合并症（包括糖尿病）</el-radio>
            <el-radio label="6">血压未控制伴临床合并症（包括糖尿病）</el-radio>
          </el-radio-group>
        </div>
      </div>

      <div class="estimate-line">
        <div class="estimate-item estimate-item2">
          <div class="key key2">
            <span>总体评价</span>
          </div>
          <el-input v-model="info.ztpj1" class="input"></el-input>
        </div>

        <div class="estimate-item estimate-item2">
          <div class="key">
            <span>评估日期</span>
          </div>
          <el-date-picker type="date" placeholder="选择日期" v-model="info.pgrq">
          </el-date-picker>
        </div>
      </div>

      <div class="estimate-line">
        <div class="estimate-item estimate-item2">
          <div class="key key2">
            <span>录入人</span>
          </div>
          <el-input v-model="info.lrr" class="input"></el-input>
        </div>

        <div class="estimate-item estimate-item2">
          <div class="key">
            <span>录入日期</span>
          </div>
          <el-date-picker type="date" placeholder="选择日期" v-model="info.lrrq">
          </el-date-picker>
        </div>
      </div>

    </div>
    <div class="estimate-btns">
      <el-button size="mini" class="blue">保存</el-button>
      <el-button size="mini" @click="close">返回</el-button>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      radio: '',
      info: {
        ssy: '',
        szy: '',
        wxys: '',
        bqgsh: '',
        tnb: '',
        nxgb: '',
        xzb: '',
        szb: '',
        zwxgb: '',
        swmbb: '',
        qt: '',
        xyfjjg: '',
        wxfcjg: '',
        fjgljg: '',
        jyxg: '',
        ztpj: '',
        ztpj1: '',
        pgrq: '',
        lrr: '',
        lrrq: ''
      }
    }
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.estimate {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  height: 100%;
  padding: 30px 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .estimate-list {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #01EEFD;
    box-sizing: border-box;

    .estimate-title {
      width: 100%;
      height: 45px;
      line-height: 45px;
      padding-left: 10px;
      color: #01EEFD;
      font-size: 18px;
      box-sizing: border-box;
    }

    .estimate-line {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding-left: 20px;
      box-sizing: border-box;

      .estimate-item {
        display: flex;
        align-items: center;
        width: 30%;

        .key {
          margin-right: 20px;
          white-space: nowrap;
          color: #01EEFD;
          font-size: 18px;
        }

        .key1 {
          width: 190px;
          margin-right: 0;
        }

        .key2 {
          width: 110px;
          margin-right: 0;
        }

        .key3 {
          width: 140px;
          margin-right: 0;
        }

        .input {
          width: 100px;
        }

        .unit {
          margin-left: 20px;
          color: #01EEFD;
          font-size: 18px;
        }

        .input1 {
          width: 495px;
          border-radius: 0;
        }

        /deep/.el-input__inner {

          height: 38px;
          border-radius: 0;
        }

        .button {
          width: 76px;
          height: 38px;
          line-height: 38px;
          background-color: #16D4E0;
          text-align: center;
          color: #fff;
          font-size: 18px;
          cursor: pointer;
        }

        .radio {
          margin-bottom: 10px;
        }
      }

      .estimate-item1 {
        width: 100%;
      }

      .estimate-item2 {
        width: 50%;
      }
    }
  }

  .estimate-btns {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    height: 80px;

    .el-button {
      margin-left: 50px;
      background-color: #33465C;
      border: none;
      border-radius: 0;
      color: #fff;
    }

    .blue {
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }
  }

  /deep/.el-radio__input.is-checked .el-radio__inner {
    background-color: #01EEFD;
    border-color: #01EEFD;
  }

  .el-radio-group {
    // width: calc(100% - 80px);
  }

  /deep/.el-radio__label,
  /deep/.el-checkbox__label {
    font-size: 18px;
  }

  /deep/.el-radio__inner,
  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .el-radio,
  .el-checkbox {
    margin-right: 20px;
    color: #fff;
    font-size: 18px;
  }

  /deep/.el-radio__input.is-checked+.el-radio__label,
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #01EEFD;
    border-color: #01EEFD;
  }
}
</style>
