<template>
  <div class="son">
    <div class="son-head">
      <div class="son-title">
        <span>详情</span>
      </div>
      <el-button @click="go">返回</el-button>
    </div>
    <div class="son-box">
      <div class="son-left">
        <div class="son-tab">
          <div v-for="(item, index) in tabs" :key="index" :class="{ 'son-active': index === tabIndex }" @click="tabClick(index)" class="son-item">
            <span>{{ item }}</span>
          </div>
        </div>
        <div v-if="tabIndex === 0" class="son-content">
          <div class="son-add">
            <el-select v-model="value" clearable placeholder="请选择事件类型">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="son-scroll son-scroll1">
            <el-timeline>
              <el-timeline-item v-for="(item, index) in 10" :key="index" color="#000fff" timestamp="2024-09-14 10:30" placement="top">
                <el-card>
                  <p>随访</p>
                  <p>随访病种：高血压高危</p>
                  <p>随访医生：李四</p>
                  <p>血压：140/98 mmHg</p>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
        <div v-else-if="tabIndex === 1" class="son-content">
          <div class="son-add">
            <el-button type="primary">新增方案</el-button>
          </div>
          <div class="son-tip">
            <span>高危患者管理途径（4/4）</span>
            <span>管理医生：李四</span>
            <span>开始日期：2024-08-21</span>
          </div>
          <div class="son-scroll">
            <el-timeline>
              <el-timeline-item v-for="(item, index) in 10" :key="index" color="#000fff" placement="top">
                <el-card>
                  <p>2024-09-20 10:30</p>
                  <p>加入计划 30 天</p>
                  <p>随访：高血压随访</p>
                  <span class="son-status">已完成</span>
                </el-card>
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <div class="son-right"></div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['健康动态', '管理路径']),
      options: [{
        value: '1',
        label: '门诊'
      }, {
        value: '2',
        label: '住院'
      }, {
        value: '3',
        label: '随访'
      }],
      value: ''
    }
  },
  methods: {
    go () {
      this.$router.go(-1)
    },
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f4f8fb;
    box-sizing: border-box;

    .son-title {
      display: flex;
      align-items: center;
      font-size: 18px;

      &::before {
        display: block;
        content: '';
        width: 6px;
        height: 20px;
        margin-right: 10px;
        background-color: rgba(0, 0, 255, 1);
        border-radius: 8px;
      }
    }
  }

  .son-box {
    display: flex;
    width: 100%;
    height: calc(100% - 60px);
    margin-top: 10px;

    .son-left {
      width: 280px;
      height: 100%;
      border-right: 1px solid #f4f8fb;
      box-sizing: border-box;

      .son-tab {
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        width: 100%;
        height: 60px;

        .son-item {
          height: 60px;
          line-height: 60px;
          padding-left: 20px;
          padding-right: 20px;
          border-bottom: 3px solid transparent;
          box-sizing: border-box;
          font-size: 14px;
          cursor: pointer;
        }

        .son-active {
          border-color: rgb(0, 0, 255);
          color: rgb(0, 0, 255);
          font-weight: 700;
        }
      }

      .son-content {
        width: 100%;
        height: calc(100% - 70px);
        margin-top: 10px;
        padding-left: 20px;
        padding-right: 20px;
        box-sizing: border-box;

        .son-add {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 60px;
        }

        .son-tip {
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          width: 100%;
          height: 96px;
          padding-left: 10px;
          padding-right: 10px;
          background-color: #f2f2f2;
          border-radius: 4px;
          box-sizing: border-box;
          font-size: 13px;
        }

        .son-scroll {
          overflow: hidden scroll;
          width: 100%;
          height: calc(100% - 166px);
          margin-top: 10px;

          &::-webkit-scrollbar {
            width: 0;
          }

          /deep/.el-timeline-item__node--normal {
            left: 0;
          }

          .el-card {
            position: relative;
            background-color: #f2f2f2;

            .son-status {
              position: absolute;
              top: 5px;
              right: 5px;
              font-size: 13px;
            }
          }
        }

        .son-scroll1 {
          height: calc(100% - 70px);
        }
      }

    }

    .son-right {
      width: calc(100% - 280px);
      height: 100%;
    }
  }
}
</style>
