<template>
  <div class="son">
    <div class="son-title">
      <span>血糖趋势</span>
      <div class="son-label">
        <div v-for="(item, index) in labels" :key="index" :class="{ 'son-active': index === topIndex }" @click="topClick(index)" class="son-item">
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div id="charts" class="son-charts"></div>
    <div class="son-title">
      <span>血糖记录</span>
      <div class="son-right">
        <div class="son-label">
          <div v-for="(item, index) in labels" :key="index" :class="{ 'son-active': index === bottomIndex }" @click="bottomClick(index)" class="son-item">
            <span>{{ item }}</span>
          </div>
        </div>
        <el-button type="primary" size="mini" @click="add">新增</el-button>
      </div>
    </div>
    <div class="son-table">
      <el-table :data="tableData" border stripe style="height: 100%">
        <el-table-column prop="date" label="测量时间">
        </el-table-column>
        <el-table-column prop="name" label="数值（mmHg）">
        </el-table-column>
        <el-table-column prop="address" label="评估">
        </el-table-column>
        <el-table-column prop="address" label="数据来源">
        </el-table-column>
        <el-table-column prop="address" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="update(scope.row)">编辑</el-button>
            <el-button type="text" @click="del(scope.row)" class="red">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <Add v-if="showAdd"></Add>
    <Update v-if="showUpdate"></Update>
  </div>
</template>

<script>
import Add from './add.vue'
import Update from './update.vue'
export default {
  components: {
    Add,
    Update
  },
  data () {
    return {
      labels: Object.freeze(['餐前', '餐后']),
      topIndex: 0,
      bottomIndex: 0,
      myChart: null,
      tableData: [{}],
      showAdd: false,
      showUpdate: false
    }
  },
  mounted () {
    this.canvas()
    window.addEventListener('resize', this.getResize)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getResize)
  },
  methods: {
    getResize () {
      this.myChart.resize()
    },
    canvas () {
      const chartDom = document.getElementById('charts')
      this.myChart = this.$echarts.init(chartDom)
      const option = {
        color: ['#1d2aff'],
        grid: {
          containLabel: true,
          left: '2%',
          top: '15%',
          right: '10%',
          bottom: '5%'
        },
        xAxis: {
          type: 'category',
          data: ['09/01', '09/02', '09/03', '09/04', '09/05', '09/06', '09/07']
        },
        yAxis: {
          type: 'value',
          name: '单位：mmol/L',
          nameTextStyle: {
            color: '#999999',
            fontSize: 12,
            padding: [0, 0, 0, 5]
          }
        },
        series: [
          {
            name: '收缩压',
            data: [92, 230, 224, 218, 135, 147, 260],
            type: 'line',
            smooth: true,
            label: {
              show: true
            }
          }
        ]
      }

      option && this.myChart.setOption(option)
    },
    topClick (index) {
      this.topIndex = index
    },
    bottomClick (index) {
      this.bottomIndex = index
    },
    add () {
      this.showAdd = true
    },
    update () {
      this.showUpdate = true
    },
    del () {
      this.$confirm('删除后无法恢复，确定要删除此条记录吗？', '删除确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '删除成功!'
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 40px;
  box-sizing: border-box;

  .son-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 30px;
    padding-right: 60px;
    font-size: 16px;
    box-sizing: border-box;

    .son-right {
      display: flex;
      align-items: center;

      .son-label {
        margin-right: 40px;
      }
    }

    .son-label {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 150px;
      height: 30px;
      background-color: rgba(242, 242, 242, 1);
      border-radius: 8px;

      .son-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50%;
        height: 30px;
        border: 2px solid rgba(242, 242, 242, 1);
        border-radius: 8px;
        box-sizing: border-box;
        color: #999999;
        font-size: 14px;
        cursor: pointer;
      }

      .son-active {
        background-color: #fff;
        color: #000;
      }
    }
  }

  .son-charts,
  .son-table {
    width: 100%;
    height: calc(50% - 30px);

    /deep/.el-table .cell {
      text-align: center;
    }

    .green {
      color: #3DD4A7;
    }

    .red {
      color: #FA746B;
    }
  }
}
</style>
