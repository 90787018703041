<template>
  <div class="write">
    <div class="title">
      <span>添加质控</span>
    </div>
    <div class="content">
      <div @click="closePopup" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <div class="item">
          <span class="span">质控日期</span>
          <el-date-picker type="date">
          </el-date-picker>
        </div>
        <div class="item">
          <span class="span">设备SN号</span>
          <el-input></el-input>
        </div>
        <div class="item">
          <span class="span">试纸批次</span>
          <el-select v-model="value">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">质控液类型</span>
          <el-select v-model="value">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <span class="span">质控结果</span>
          <el-input></el-input>
        </div>
      </div>
      <div class="btns">
        <el-button @click="closePopup">取消</el-button>
        <el-button class="button">确认</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      options: [{
        value: '',
        label: ''
      }],
      value: ''
    }
  },
  methods: {
    closePopup () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.write {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 888;
  width: 100%;
  height: 100%;
  padding: 0 180px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, .9);

  .title {
    width: 267px;
    height: 53px;
    padding-top: 5px;
    box-sizing: border-box;
    line-height: 53px;
    text-align: center;
    background-image: url('../../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: 20px;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 10rem;
    height: 500px;
    margin-top: 10px;
    padding: 50px;
    background-image: url('../../../img/ssjk/background4.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .close {
      position: absolute;
      top: 17.6px;
      right: 25px;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    .item {
      display: flex;
      align-items: center;
      width: 24%;
      margin-top: 10px;
      color: #01EEFD;
      font-size: 16px;

      .span {
        text-align-last: justify;
        margin-right: 10px;
        white-space: nowrap;
      }

      .el-input,
      .el-select,
      .el-date-picker {
        width: 200px;
      }
    }

    .btns {
      position: absolute;
      bottom: 70px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 240px;
      height: 26px;
      margin: 100px auto 0;

      .el-button {
        width: 70px;
        height: 26px;
        line-height: 26px;
        padding: 0;
        text-align: center;
        color: #fff;
        font-size: 14px;
        background-color: #33465C;
        border-radius: 0;
        border: none;
      }

      .button {
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
      }
    }
  }
}
</style>
