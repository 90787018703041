var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed"},[_vm._m(0),_c('el-button',{staticClass:"detailed-close",on:{"click":_vm.close}},[_vm._v("返回")]),_c('div',{staticClass:"detailed-box"},[_c('div',{staticClass:"detailed-left"},[_vm._m(1),_c('div',{staticClass:"detailed-info"},[_c('div',{staticClass:"detailed-info-line"},[_c('el-image',{attrs:{"src":"https://files.axshare.com/gsc/RBUY6U/0d/db/3c/0ddb3c286c8144039541bd12133cfd43/images/%E5%B7%A5%E4%BD%9C%E5%8F%B0/u380.png"}}),_vm._m(2)],1),_vm._m(3),_vm._m(4)]),_vm._m(5),_vm._m(6)]),_c('div',{staticClass:"detailed-right"},[_c('div',{staticClass:"detailed-tab"},_vm._l((_vm.tabs),function(item,index){return _c('div',{key:index,staticClass:"detailed-tab-item",class:{ 'detailed-tab-active': index === _vm.tabIndex },on:{"click":function($event){return _vm.tabClick(index)}}},[_c('span',[_vm._v(_vm._s(item))])])}),0),_c('div',{staticClass:"detailed-content"},[(_vm.tabIndex === 0)?_c('Tab1'):(_vm.tabIndex === 1)?_c('Tab2'):(_vm.tabIndex === 2)?_c('Tab3'):_vm._e()],1)])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed-title"},[_c('span',[_vm._v("详细信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed-position"},[_c('span',[_vm._v("I级")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed-news"},[_c('div',{staticClass:"detailed-news-top"},[_c('span',{staticClass:"name"},[_vm._v("张三")]),_c('span',{staticClass:"age"},[_vm._v("56岁")]),_c('span',{staticClass:"sex"},[_vm._v("男")])]),_c('div',{staticClass:"detailed-news-label"},[_c('span',[_vm._v("高血压")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed-info-line"},[_c('span',[_vm._v("身份证号：5130******0900")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed-info-line"},[_c('span',[_vm._v("电话：13011**111")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed-health"},[_c('div',{staticClass:"detailed-health-title"},[_c('span',[_vm._v("健康信息")])]),_c('div',{staticClass:"detailed-health-line"},[_c('div',{staticClass:"detailed-health-item"},[_c('span',{staticClass:"gray"},[_vm._v("身高")]),_c('span',[_vm._v("-cm")])]),_c('div',{staticClass:"detailed-health-item"},[_c('span',{staticClass:"gray"},[_vm._v("体重")]),_c('span',[_vm._v("-kg")])])]),_c('div',{staticClass:"detailed-health-line"},[_c('div',{staticClass:"detailed-health-item"},[_c('span',{staticClass:"gray"},[_vm._v("血型")]),_c('span',[_vm._v("-型")])]),_c('div',{staticClass:"detailed-health-item"},[_c('span',{staticClass:"gray"},[_vm._v("RH阴性")]),_c('span',[_vm._v("否")])])]),_c('div',{staticClass:"detailed-health-line"},[_c('div',{staticClass:"detailed-health-item"},[_c('span',{staticClass:"gray"},[_vm._v("过敏史")]),_c('span',[_vm._v("无")])]),_c('div',{staticClass:"detailed-health-item"},[_c('span',{staticClass:"gray"},[_vm._v("既往史")]),_c('span',[_vm._v("无")])])]),_c('div',{staticClass:"detailed-health-line"},[_c('div',{staticClass:"detailed-health-item"},[_c('span',{staticClass:"gray"},[_vm._v("就诊史")]),_c('span',[_vm._v("无")])]),_c('div',{staticClass:"detailed-health-item"},[_c('span',{staticClass:"gray"},[_vm._v("家族病史")]),_c('span',[_vm._v("无")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"detailed-sign"},[_c('div',{staticClass:"detailed-sign-title"},[_c('span',[_vm._v("签约情况")])]),_c('div',{staticClass:"detailed-sign-item"},[_c('span',{staticClass:"gray"},[_vm._v("服务包")]),_c('span',[_vm._v("慢性病护理包")])]),_c('div',{staticClass:"detailed-sign-item"},[_c('span',{staticClass:"gray"},[_vm._v("生效日期")]),_c('span',[_vm._v("2024/10/09")])]),_c('div',{staticClass:"detailed-sign-item"},[_c('span',{staticClass:"gray"},[_vm._v("到期日期")]),_c('span',[_vm._v("2024/10/09")])])])
}]

export { render, staticRenderFns }