var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun"},[_c('div',{staticClass:"sun-box"},[_c('div',{staticClass:"sun-content"},[_vm._m(0),_vm._m(1),_vm._m(2),_c('div',{staticClass:"sun-item"},[_vm._m(3),_c('div',{staticClass:"sun-value"},[_c('el-image',{attrs:{"src":"https://files.axshare.com/gsc/RBUY6U/0d/db/3c/0ddb3c286c8144039541bd12133cfd43/images/%E6%A1%A3%E6%A1%88%E8%AF%A6%E6%83%85/u963.png"}})],1)]),_vm._m(4),_vm._m(5),_vm._m(6),_c('div',{staticClass:"sun-item"}),_vm._m(7),_c('div',{staticClass:"sun-item"},[_vm._m(8),_c('div',{staticClass:"sun-value"},[_c('el-tag',{attrs:{"type":"danger"}},[_vm._v("高血压")])],1)]),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23),_vm._m(24),_vm._m(25),_vm._m(26),_vm._m(27),_vm._m(28),_vm._m(29),_vm._m(30)])]),_c('div',{staticClass:"sun-bottom"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.update}},[_vm._v("编辑档案信息")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-title"},[_c('span',[_vm._v("基本信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("居民编号")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("20240908900")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("姓名")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("张三")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("头像")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("身份证号")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("1304561989***90543")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("手机号码")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("13064308***")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("现居地")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("朝阳区XXX路XX号12楼3单元392")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("行政区划")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("东风乡")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("居民标签")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("性别")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("女")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("出生日期")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("1970-09-08")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("籍贯")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("北京")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("民族")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("汉族")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("文化程度")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("本科")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("婚姻状况")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("已婚")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("职业")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("公务员")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("工作单位")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("无")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("紧急联系人")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("无")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("联系人电话")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("无")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("户籍类型")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("城镇")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("费用类型")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("自费")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("定点医疗单位")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("六里屯社区医院")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-title"},[_c('span',[_vm._v("健康信息")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("身高")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("169cm")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("体重")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("49kg")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("血型")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("A型")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("否")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("A型")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("过敏史")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("无")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("既往史")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("无")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("就诊史")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("无")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sun-item"},[_c('div',{staticClass:"sun-key"},[_c('span',[_vm._v("家族病史")])]),_c('div',{staticClass:"sun-value"},[_c('span',[_vm._v("无")])])])
}]

export { render, staticRenderFns }