import { render, staticRenderFns } from "./archiveDetails.vue?vue&type=template&id=60da5668&scoped=true&"
import script from "./archiveDetails.vue?vue&type=script&lang=js&"
export * from "./archiveDetails.vue?vue&type=script&lang=js&"
import style0 from "./archiveDetails.vue?vue&type=style&index=0&id=60da5668&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60da5668",
  null
  
)

export default component.exports