<template>
  <div class="son">
    <div class="son-head">
      <div class="son-title">
        <span>新建居民档案</span>
      </div>
      <el-button @click="go">返回</el-button>
    </div>
    <div class="son-label">
      <span>基本信息</span>
    </div>
    <div class="son-box">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="姓名" prop="name">
          <el-input clearable v-model="ruleForm.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="身份证号" prop="sfzh">
          <el-input maxlength="18" clearable v-model="ruleForm.sfzh" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="性别" prop="sex">
          <el-select clearable v-model="ruleForm.sex" placeholder="请选择">
            <el-option label="男" value="1"></el-option>
            <el-option label="女" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="出生日期" prop="csrq">
          <el-date-picker v-model="ruleForm.csrq" type="date" placeholder="选择日期">
        </el-date-picker>
        </el-form-item>
        <el-form-item label="手机号码" prop="sjhm">
          <el-input maxlength="11" clearable v-model="ruleForm.sjhm" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="现居地" prop="xzd">
          <el-input clearable v-model="ruleForm.xzd" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="居民标签" prop="jmbq">
          <el-select v-model="ruleForm.jmbq" multiple collapse-tags placeholder="请选择">
            <el-option
              v-for="item in jmbqs"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item class="son-item">
          <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
          <el-button type="primary" @click="improve">完善居民档案</el-button>
          <el-button type="success" @click="sign">立即签约</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data () {
    return {
      jmbqs: Object.freeze([
        { label: '慢病护理', value: '1' },
        { label: '高血压', value: '2' },
        { label: '高血糖', value: '3' }
      ]),
      ruleForm: {
        name: '',
        sfzh: '',
        sex: '',
        csrq: '',
        sjhm: '',
        xzd: '',
        jmbq: ''
      },
      rules: {
        name: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[\u4E00-\u9FA5]{2,5}$/.test(value))) {
                callback(new Error('请输入汉字'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        sfzh: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value))) {
                callback(new Error('请输入正确身份证号'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ],
        sex: [
          { required: true, message: '请选择性别', trigger: 'change' }
        ],
        csrq: [
          { required: true, message: '请选择出生日期', trigger: 'change' }
        ],
        sjhm: [
          {
            validator: (rule, value, callback) => {
              if (!(/^[1][3-9]\d{9}$|^([6|9])\d{7}$|^[0][9]\d{8}$|^[6]([8|6])\d{5}$/.test(value))) {
                callback(new Error('请输入正确手机号'))
              } else {
                callback()
              }
            },
            required: true,
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    go () {
      this.$router.go(-1)
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          alert('submit!')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    improve () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/improveresidentfiles'
      })
    },
    sign () {
      this.$router.push({
        path: '/intelligentmonitoring/highriskpopulationscreening/addsign'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f4f8fb;
    box-sizing: border-box;

    .son-title {
      display: flex;
      align-items: center;
      font-size: 18px;

      &::before {
        display: block;
        content: '';
        width: 6px;
        height: 20px;
        margin-right: 10px;
        background-color: rgba(0, 0, 255, 1);
        border-radius: 8px;
      }
    }
  }

  .son-label {
    width: 100%;
    height: 40px;
    line-height: 40px;
    font-size: 16px;
  }

  .son-box {
    overflow: hidden scroll;
    width: 100%;
    height: calc(100% - 100px);
    margin-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
    }

    .el-form {
      display: flex;
      flex-wrap: wrap;
    }

    .el-form-item {
      width: calc(30% - 50px);
      margin-right: 50px;
    }

    .son-item {
      width: 100%;

      /deep/.el-form-item__content {
        margin-left: 0 !important;
      }
    }

    /deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
      display: none;
    }
    /deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label::after {
      content: '*';
      color: #F56C6C;
    }
  }
}
</style>
