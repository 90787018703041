<template>
  <div class="tab">
    <div class="son-search">
      <div class="son-search-item">
        <div class="son-search-key">
          <span>就诊日期</span>
        </div>
        <el-date-picker v-model="params.date" type="daterange" align="right" unlink-panels range-separator="至"
          start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions">
        </el-date-picker>
      </div>
      <div class="son-search-item">
        <el-input v-model="params.ssnr" placeholder="请输入姓名/身份证号/就诊号" suffix-icon="el-icon-search"></el-input>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>管理状态</span>
        </div>
        <el-select v-model="params.glzt" placeholder="请选择">
          <el-option v-for="item in glzts" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>主治医生</span>
        </div>
        <el-input v-model="params.zzys" placeholder="请输入"></el-input>
      </div>
      <div class="son-search-item">
        <div class="son-search-key">
          <span>就诊科室</span>
        </div>
        <el-select v-model="params.jzks" placeholder="请选择">
          <el-option v-for="item in jzks" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="son-search-item">
        <el-button type="primary" plain>查询</el-button>
        <el-button type="primary">更新</el-button>
      </div>
    </div>
    <div class="son-switch">
      <div v-for="(item, index) in tabs" :key="index" :class="{ 'son-switch-acitve': tabIndex === index }"
        @click="tabClick(index)" class="son-switch-item">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="son-box">
      <el-table :data="tableData" border stripe height="100%">
        <el-table-column prop="name" label="住院号">
        </el-table-column>
        <el-table-column prop="name" label="姓名">
        </el-table-column>
        <el-table-column prop="name" label="性别">
          <template slot-scope="scope">
            <span>{{ scope.row.xb === '1' ? '男' : '女' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="address" label="年龄">
        </el-table-column>
        <el-table-column prop="address" label="身份证号">
        </el-table-column>
        <el-table-column prop="address" label="电话">
        </el-table-column>
        <el-table-column prop="address" label="住院科室">
        </el-table-column>
        <el-table-column prop="address" label="住院时间">
        </el-table-column>
        <el-table-column prop="address" label="床位号">
        </el-table-column>
        <el-table-column prop="address" label="主治医">
        </el-table-column>
        <el-table-column prop="address" label="主诊断">
        </el-table-column>
        <el-table-column prop="address" label="最新管理路径">
        </el-table-column>
        <el-table-column prop="address" label="管理分级">
        </el-table-column>
        <el-table-column fixed="right" label="操作">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="see(scope)">查看档案</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="son-pagination">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="currentPage"
        :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, sizes, prev, pager, next, jumper"
        :total="400" hide-on-single-page>
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近一个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: '最近三个月',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      params: {
        date: '',
        ssnr: '',
        glzt: '',
        zzys: '',
        jzks: ''
      },
      glzts: Object.freeze([{
        value: '1',
        label: '在管'
      }, {
        value: '2',
        label: '未管'
      }, {
        value: '3',
        label: '失管'
      }]),
      jzks: [],
      tabIndex: 0,
      tabs: Object.freeze(['高危人群', '一般人群']),
      tableData: [],
      currentPage: 1
    }
  },
  methods: {
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
    },
    see () { },
    del () { },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  height: 100%;

  .son-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    .son-switch-item {
      width: 100px;
      height: 50px;
      line-height: 50px;
      margin-right: 20px;
      text-align: center;
      border-bottom: 3px solid #fff;
      box-sizing: border-box;
      font-size: 18px;
      cursor: pointer;
    }

    .son-switch-acitve {
      border-color: blue;
      color: blue;
    }
  }

  .son-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    height: 110px;

    .son-search-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 25%;
      padding-right: 10px;
      box-sizing: border-box;

      .son-search-key {
        width: 80px;
        white-space: nowrap;
        color: #999999;
        font-size: 16px;
      }

      .el-button {
        margin-left: 10px;
      }
    }
  }

  .son-box {
    width: 100%;
    height: calc(100% - 250px);

    /deep/.el-table .cell {
      text-align: center;
      color: #000;
    }

    .del {
      color: red;
    }

    .green {
      color: #28D094;
    }

    .gray {
      color: #999999;
    }

    .blue {
      color: #0000FF;
    }
  }

  .son-pagination {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80px;

    /deep/.el-pager li {
      margin-left: 5px;
      margin-right: 5px;
      background-color: rgba(242, 247, 251, 1);
    }

    /deep/.el-pager li.active {
      background-color: rgba(0, 15, 255, 1);
      color: #fff;
    }
  }
}
</style>
