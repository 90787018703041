<template>
  <div class="children-page">
    <div class="head">
      <!-- <el-button size="small">服务记录</el-button> -->
      <div class="tab">
        <div class="item" :class="{ active: index === tabIndex }" v-for="(item, index) in tabs" :key="index"
          @click="tabClick(index)">
          <span>{{ item }}</span>
        </div>
      </div>
      <el-input class="postion" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
      </el-input>
    </div>
    <div class="content">
      <router-view></router-view>
    </div>
    <!-- 新增 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 编辑 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 绑定 -->
    <Bind v-if="showBind" @close="closeBind"></Bind>
  </div>
</template>

<script>
import Add from './add.vue'
import Update from './update.vue'
import Bind from './bindPopup.vue'
export default {
  components: {
    Add,
    Update,
    Bind
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '1'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '2'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '3'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '被驳回'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '普通会诊',
        type1: '在线会诊',
        type2: '北京朝阳医院',
        status: '待审核'
      }],
      tabIndex: 0,
      tabIndex1: 0,
      tabs: Object.freeze(['服务记录', '随访预警', '7天提醒', '14天提醒', '本季度待随访', '应防未访']),
      tabs1: Object.freeze(['高血压', '糖尿病', '高血脂', '精神病', '肺结核']),
      options: [{
        value: '1',
        label: '1'
      }],
      value: '',
      showAdd: false,
      showUpdate: false,
      showBind: false
    }
  },
  created () {
    setTimeout(() => {
      // debugger
    }, 3000)
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
      this.$router.push('/intelligentmonitoring/servicerecord/tab' + (index + 1))
    },
    tabClick1 (index) {
      this.tabIndex1 = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openBind () {
      console.log(11111)
      this.showBind = true
    },
    closeBind () {
      this.showBind = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.children-page {
  width: calc(100% - 10px);
  height: 100%;
  padding: 20px;
  background-color: #081C38;
  box-sizing: border-box;

  .swich {
    display: flex;
    flex-direction: column;
  }

  .head {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    height: 78px;
    border-bottom: 1px solid #01EEFD;
    box-sizing: border-box;

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      font-size: 22px;
      border: 0;
      background-color: #01EEFD;
    }

    .el-input {
      width: 246px;
      height: 38px;
      border-radius: 6px;

      /deep/.el-input__inner {
        height: 38px;
        line-height: 38px;
      }
    }

    .postion {
      position: absolute;
      right: 0;
      background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
      border: .0125rem solid #1F72D3;

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: .225rem;
        border: none;

      }
    }

    /deep/ .el-input__suffix {
      color: #fff;
      font-size: .225rem;
    }

    /deep/ .el-icon-search {
      display: flex;
      align-items: center;
    }
  }

  .tab {
    display: flex;
    align-items: center;
    height: 40px;

    .item {
      height: 40px;
      line-height: 40px;
      margin-right: 30px;
      padding: 0 20px;
      background-color: rgba(24, 205, 253, .28);
      color: #fff;
      font-size: 20px;
      cursor: pointer;
    }

    .active {
      background-color: #18CDFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - 80px);
  }
}
</style>
