<template>
  <div class="info">
    <div class="info-left">
      <div class="info-head">
        <img class="user" src="../../img/emr/user.png" alt="头像">
        <span class="span">李明明</span>
        <span class="span">男</span>
        <span class="span">44岁</span>
        <el-button size="small" class="btn2">居民医保</el-button>
        <!-- <el-button size="small" class="btn2 btn3">诊断:慢性鼻炎/慢性咽炎</el-button> -->
        <el-button size="small" class="btn2 btn7">预约</el-button>
        <el-button size="small" class="btn2 btn7">就诊记录（1）</el-button>
        <el-button size="small" class="btn2 btn4">慢:高血压/糖尿病</el-button>
        <el-button size="small" class="btn2 btn5">敏</el-button>
        <el-button size="small" class="btn2 btn6">术后</el-button>
        <div class="news">
          <div class="news-item">
            <img class="news-img news-img1" src="../../../../assets/img/idcard.png">
            <span>身份证：202111060232</span>
          </div>
          <div class="news-item">
            <img class="news-img" src="../../../../assets/img/phone.png">
            <span>电话：159****2236</span>
          </div>
          <div class="news-item">
            <span>代缴费：<span class="money">652.23</span></span>
          </div>
          <div class="news-item">
            <span class="blue">查看明细</span>
          </div>
        </div>
        <div class="position">
          <el-button size="small" class="green">完成诊疗</el-button>
        </div>
      </div>
      <div class="info-scroll">
        <div class="tab-content">
          <div class="switch-title">
            <span>就诊记录</span>
            <div class="btns">
              <el-button size="small" @click="switchClick(0)" :class="{ active: switchIndex === 0 }">当月</el-button>
              <el-button size="small" @click="switchClick(1)" :class="{ active: switchIndex === 1 }">近3月</el-button>
              <el-button size="small" @click="switchClick(2)" :class="{ active: switchIndex === 2 }">近半年</el-button>
              <el-button size="small" @click="switchClick(3)" :class="{ active: switchIndex === 3 }">近1年</el-button>
            </div>
          </div>
          <el-timeline>
            <el-timeline-item timestamp="2021-05-22   08:55" placement="top" v-for="(item, index) in 4" :key="index">
              <div class="city">
                <span>健康无忧人民医院      牙科门诊      张小光医师</span>
              </div>
              <div class="title">
                <span class="left"></span>
                <span>住院诊断</span>
              </div>
              <el-row :gutter="3">
                <el-col :span="3">
                  <div class="col">
                    <span>牙齿敏感证</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="col">深龋伴牙髓充血</div>
                </el-col>
                <el-col :span="3">
                  <div class="col">航空性牙痛</div>
                </el-col>
              </el-row>
              <div class="title">
                <span class="left"></span>
                <span>受邀科室及医生</span>
              </div>
              <el-row :gutter="3">
                <el-col :span="3">
                  <div class="col">
                    <span>健康无忧中医院</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="col">五官科</div>
                </el-col>
                <el-col :span="4">
                  <div class="col">张倩玉主任医师</div>
                </el-col>
                <el-col :span="3">
                  <div class="col">
                    <div class="blue">
                      查看会诊报告
                    </div>
                  </div>
                </el-col>
              </el-row>
              <div class="title">
                <span class="left"></span>
                <span>门诊处方</span>
              </div>
              <!-- <el-row :gutter="3">
                <el-col :span="7">
                  <div class="col col1">
                    <span class="span">苯磺酸氨氯地平(进口)</span>
                    <span class="span span1">[001201000030000-ACAC0001] 2021-05-22 08:55</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="col">5mg*7片/盒</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">5mg</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">口服</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">qd </div>
                </el-col>
                <el-col :span="2">
                  <div class="col">7 日</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">1 盒</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">
                    <div class="blue">
                      引用
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row :gutter="3">
                <el-col :span="7">
                  <div class="col col1">
                    <span class="span">苯磺酸氨氯地平(进口)</span>
                    <span class="span span1">[001201000030000-ACAC0001] 2021-05-22 08:55</span>
                  </div>
                </el-col>
                <el-col :span="4">
                  <div class="col">5mg*7片/盒</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">5mg</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">口服</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">qd </div>
                </el-col>
                <el-col :span="2">
                  <div class="col">7 日</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">1 盒</div>
                </el-col>
                <el-col :span="2">
                  <div class="col">
                    <div class="blue">
                      引用
                    </div>
                  </div>
                </el-col>
              </el-row> -->
            </el-timeline-item>
          </el-timeline>
          <div class="absolute">
            <el-timeline>
              <el-timeline-item timestamp="2021-05-22 08:55" placement="top" color='#1269B7'>
              </el-timeline-item>
              <el-timeline-item timestamp="2021-05-22 08:55" placement="top">
              </el-timeline-item>
              <el-timeline-item timestamp="2021-05-22 08:55" placement="top">
              </el-timeline-item>
            </el-timeline>
          </div>
        </div>
      </div>
      <Popup v-if="showPopup" @close="closePopup"></Popup>
    </div>
    <!-- <div class="info-right">
      <div class="item">
        <img src="../../img/doctorAdvice/icon1.png" class="img">
        <span>录音中</span>
      </div>
      <div class="item">
        <img src="../../img/doctorAdvice/icon1.png" class="img">
        <span>医嘱单</span>
      </div>
      <div class="item">
        <img src="../../img/doctorAdvice/icon2.png" class="img">
        <span>会诊</span>
      </div>
      <div class="item">
        <img src="../../img/doctorAdvice/icon3.png" class="img">
        <span>手术预约</span>
      </div>
      <div class="item">
        <img src="../../img/doctorAdvice/icon4.png" class="img">
        <span>输血</span>
      </div>
      <div class="item">
        <img src="../../img/doctorAdvice/icon5.png" class="img">
        <span>病历</span>
      </div>
      <div class="item">
        <img src="../../img/doctorAdvice/icon6.png" class="img">
        <span>临床路径</span>
      </div>
      <div class="item" @click="openPopup">
        <img src="../../img/doctorAdvice/icon7.png" class="img">
        <span>疾病上报</span>
      </div>
    </div> -->

    <!-- <div class="info-position" @click="openPopup">
      <el-input placeholder="开医嘱/检验/检查申请单" disabled></el-input>
      <img src="../../img/doctorAdvice/max.png" alt="">
    </div> -->
  </div>
</template>

<script>
import Popup from './popup.vue'
export default {
  components: {
    Popup
  },
  data () {
    return {
      lists: Object.freeze([
        { text: '录音中', url: '../../img/doctorAdvice/icon0.png' },
        { text: '医嘱单', url: '../../img/doctorAdvice/icon0.png' },
        { text: '会诊', url: '../../img/doctorAdvice/icon0.png' },
        { text: '手术预约', url: '../../img/doctorAdvice/icon0.png' },
        { text: '输血', url: '../../img/doctorAdvice/icon0.png' },
        { text: '病历', url: '../../img/doctorAdvice/icon0.png' },
        { text: '临床路径', url: '../../img/doctorAdvice/icon0.png' },
        { text: '疾病上报', url: '../../img/doctorAdvice/icon0.png' }
      ]),
      showPopup: false,
      switchIndex: 0
    }
  },
  methods: {
    switchClick (index) {
      this.switchIndex = index
    },
    openPopup () {
      this.showPopup = true
    },
    closePopup () {
      this.showPopup = false
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 10px;
  box-sizing: border-box;

  .info-position {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #F7F7F7;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.1608);

    .el-input {
      width: calc(100% - 40px);
      cursor: pointer;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  .info-left {
    position: relative;
    // width: calc(100% - 109px);
    width: 100%;
    height: 100%;

    .info-head {
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      height: 45px;
      padding: 0 7px;
      border-bottom: 1px solid #A1A6BB;
      box-sizing: border-box;

      .user {
        width: 35px;
        height: 35px;
      }

      .span {
        margin-left: 15px;
        color: #333333;
        font-size: 12px;
      }

      .el-button {
        box-sizing: border-box;
      }

      .btn2 {
        margin-left: 10px;
        background-color: #fff;
        border: 1px solid #1269B7;
        border-radius: 0;
        color: #1269B7;
        font-size: 12px;
      }

      .btn3 {
        background-color: #1269B7;
        color: #fff;
      }

      .btn4 {
        background-color: #B74412;
        border: 1px solid #B74412;
        color: #fff;
      }

      .btn5 {
        background-color: #333333;
        border: 1px solid #333333;
        color: #fff;
      }

      .btn6 {
        background-color: #C26B09;
        border: 1px solid #C26B09;
        color: #fff;
      }

      .btn7 {
        background-color: #fff;
        border: 1px solid #10BE61;
        color: #10BE61;
      }

      .news {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        width: 400px;
        height: 100%;
        margin-left: 10px;

        .news-item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          color: #333333;
          font-size: 12px;

          .news-img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }

          .news-img1 {
            width: 26px;
          }

          .money {
            color: #FF9200;
          }

          .blue {
            color: #1269B7;
          }
        }
      }

      .position {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        height: 100%;
        padding-left: 100px;
        padding-right: 10px;
        background-color: #fff;

        .el-button {
          margin: 0;
          border: none;
          border-radius: 0;
          color: #fff;
          font-size: 16px;
        }

        .green {
          background-color: #10BE61;
        }

        .yellow {
          background-color: #FF9200;
        }
      }
    }

    .info-scroll {
      overflow-y: scroll;
      width: 100%;
      margin-top: 10px;
      height: calc(100% - 60px);
      padding: 0 10px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
      }

      .tab-content {
        position: relative;
        width: 100%;
        height: 100%;

        .switch-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 45px;
          // border-bottom: 1px solid #A1A6BB;
          color: #3E3E3E;
          font-size: 18px;

          .btns {
            display: flex;
            align-items: center;

            .el-button {
              margin-right: 20px;
              background-color: #D9D9D9;
              border-radius: 0;
              border: none;
            }

            .active {
              background-color: #1269B7;
              color: #fff;
            }
          }
        }

        .el-timeline {
          overflow-y: scroll;
          width: 100%;
          height: calc(100% - 50px);
          padding: 10px;
          box-sizing: border-box;

          &::-webkit-scrollbar {
            width: 0;
          }

          ::v-deep .el-timeline-item__node--normal {
            width: 20px;
            height: 20px;
            background-color: #1269B7;
          }

          ::v-deep .el-timeline-item__tail {
            top: 8px;
            left: 7px;
            border-left: 3px dotted #A1A6BB;
          }

          ::v-deep .el-timeline-item__timestamp {
            color: #3E3E3E;
            font-size: 16px;
          }

          .city {
            color: #A1A6BB;
            font-size: 12px;
          }

          .title {
            display: flex;
            align-items: center;
            margin-top: 25px;
            margin-bottom: 6px;
            color: #3E3E3E;
            font-size: 14px;

            .left {
              width: 24px;
              height: 10px;
              margin-right: 10px;
              border-radius: 0 4px 4px 0;
              background-color: #D9D9D9;
            }
          }

          .el-row {
            padding-left: 34px;
            margin-bottom: 4px;
          }

          .col {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 54px;
            text-align: center;
            background-color: #D9D9D9;
            color: #666666;
            font-size: 14px;
          }

          .col1 {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .span {
              width: 100%;
              padding: 0 10px;
              text-align: left;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              box-sizing: border-box;
            }

            .span1 {
              color: #A1A6BB;
              font-size: 12px;
            }
          }

          .blue {
            color: #1269B7;

            // border-bottom: 1px solid #1269B7;
            box-sizing: border-box;
            cursor: pointer;
            text-decoration: underline;
          }

          .see {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding-left: 36px;
            box-sizing: border-box;

            .see-title {
              color: #3E3E3E;
              font-size: 12px;
            }

            .see-box {
              position: relative;
              display: flex;
              flex-direction: column-reverse;
              align-items: center;
              width: 116px;
              height: 130px;
              background-color: #CACACA;

              .image {
                width: 108px;
                height: 102px;
                margin-bottom: 10px;
                background-color: #000;
              }

              .tip {
                position: absolute;
                bottom: 0;
                left: 0;
                z-index: 88;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                padding: 4px;
                color: #FFFFFF;
                font-size: 12px;
                background-color: rgba(0, 0, 0, .69);
              }
            }
          }
        }

        .absolute {
          position: absolute;
          top: 80px;
          right: 0;
          z-index: 88;
          padding-top: 10px;
          background-color: #fff;
          box-shadow: 0px 2px 4px #666666;

          ::v-deep .el-timeline-item__node--normal {
            width: 20px;
            height: 20px;
            background-color: #AAAAAA;
          }

          .el-timeline-item__node--normal:nth-child(0) {
            background-color: #1269B7;
          }

          ::v-deep .el-timeline-item__tail {
            left: 8px;
            border-left: 2px solid #A1A6BB;
          }
        }
      }
    }
  }

  .info-right {
    width: 109px;
    height: 100%;
    border-left: 1px solid #A1A6BB;
    box-sizing: border-box;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      color: #333333;
      font-size: 14px;

      .img {
        width: 30px;
        height: 30px;
      }
    }
  }
}
</style>
