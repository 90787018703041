<template>
  <div class="son">
    <div class="son-title">
      <span>上转记录</span>
    </div>
    <div class="son-switch">
      <div v-for="(item, index) in tabs" :key="index" :class="{ 'son-switch-acitve': tabIndex === index }"
        @click="tabClick(index)" class="son-switch-item">
        <span>{{ item }}</span>
      </div>
    </div>
    <div class="son-content">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['我上转的', '上转给我的'])
    }
  },
  watch: {
    tabIndex (newsVal) {
      this.$router.push({
        path: '/intelligentmonitoring/twoWayreferral/upstreamecord/tab' + (newsVal + 1)
      })
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const path = this.$route.path
      if (path.indexOf('upstreamecord/tab2') > -1) {
        this.tabIndex = 1
      } else if (path.indexOf('upstreamecord/tab1') > -1) {
        this.tabIndex = 0
      }
    },
    tabClick (index) {
      if (index === this.tabIndex) return
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.son {
  position: relative;
  width: 100%;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #fff;
  box-sizing: border-box;

  .son-title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    font-size: 18px;

    &::before {
      display: block;
      content: '';
      width: 6px;
      height: 20px;
      margin-right: 10px;
      background-color: rgba(0, 0, 255, 1);
      border-radius: 8px;
    }
  }

  .son-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 60px;

    .son-switch-item {
      width: 100px;
      height: 50px;
      line-height: 50px;
      margin-right: 20px;
      text-align: center;
      border-bottom: 3px solid #fff;
      box-sizing: border-box;
      font-size: 18px;
      cursor: pointer;
    }

    .son-switch-acitve {
      border-color: blue;
      color: blue;
    }
  }

  .son-content {
    width: 100%;
    height: calc(100% - 120px);
  }
}
</style>
