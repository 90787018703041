<template>
  <div class="tab-box">
    <div class="input">
      <div class="tab">
        <div class="item" :class="{ active: index === tabIndex1 }" v-for="(item, index) in tabs1" :key="index"
          @click="tabClick1(index)">
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div class="input">
      <el-select v-model="value" placeholder="李某某" size="small">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-select v-model="value" placeholder="身份证号" size="small">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <el-date-picker size="small" type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" v-model="date">
      </el-date-picker>
      <el-select v-model="value" placeholder="所属机构" size="small">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <span class="text">是否完整</span>
      <el-select v-model="value" placeholder="是" size="small">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <span class="text">二次随访</span>
      <el-select v-model="value" placeholder="是" size="small">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
      <span class="text">是否完成</span>
      <el-select v-model="value" placeholder="是" size="small">
        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
        </el-option>
      </el-select>
    </div>
    <div class="input input1">
      <el-button class="blue" size="mini" @click="openAdd">新增</el-button>
    </div>
    <div class="swich">
      <el-table :data="tableData" style="width: 100%" max-height="450">
        <el-table-column type="selection" width="55" fixed>
        </el-table-column>
        <el-table-column prop="date" label="序号" width="150">
        </el-table-column>
        <el-table-column prop="name" label="服务编号" width="120">
        </el-table-column>
        <el-table-column prop="name" label="姓名" width="120">
        </el-table-column>
        <el-table-column prop="province" label="性别" width="120">
        </el-table-column>
        <el-table-column prop="city" label="年龄" width="120">
        </el-table-column>
        <el-table-column prop="address" label="身份证号" width="300">
        </el-table-column>
        <el-table-column prop="address" label="人群分类" width="300">
        </el-table-column>
        <el-table-column prop="address" label="乡镇/社区" width="300">
        </el-table-column>
        <el-table-column prop="address" label="村/服务站" width="300">
        </el-table-column>
        <el-table-column prop="address" label="最近服务时间" width="300">
        </el-table-column>
        <el-table-column prop="address" label="服务类型" width="300">
        </el-table-column>
        <el-table-column prop="zip" label="服务人员" width="120">
        </el-table-column>
        <el-table-column prop="zip" label="服务状态" width="120">
        </el-table-column>

        <!-- <el-table-column v-show="tabIndex1 === -1" prop="zip" label="随访时间" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 === -1" prop="zip" label="最近随访时间" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 === -1" prop="zip" label="随访类型" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 === -1" prop="zip" label="随访人员" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 === -1" prop="zip" label="随访状态" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 === -1" prop="zip" label="随访分类" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 === -1" prop="zip" label="剩余时间" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 === -1" prop="zip" label="绑定设备" width="120">
        </el-table-column> -->

        <el-table-column v-show="tabIndex1 === 0" prop="zip" label="血压舒张" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 === 0" prop="zip" label="血压收缩" width="120">
        </el-table-column>

        <el-table-column v-show="tabIndex1 !== 0" prop="zip" label="监测时段" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 !== 0" prop="zip" label="餐前/餐后" width="120">
        </el-table-column>
        <el-table-column v-show="tabIndex1 !== 1" prop="zip" label="血糖" width="120">
        </el-table-column>

        <el-table-column prop="zip" label="脉搏" width="120">
        </el-table-column>
        <el-table-column prop="zip" label="腰围" width="120">
        </el-table-column>
        <el-table-column prop="zip" label="体重" width="120">
        </el-table-column>
        <el-table-column prop="zip" label="服务评价" width="120">
        </el-table-column>
        <el-table-column prop="zip" label="分析结果" width="120">
        </el-table-column>
        <el-table-column prop="zip" label="并发症" width="120">
        </el-table-column>
        <el-table-column prop="zip" label="服务分类" width="120">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="160">
          <template>
            <div class="operate">
              <span @click="openUpdate">编辑</span>
              <span>删除</span>
              <span @click="openBind">绑定</span>
              <span>处置</span>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination">
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="4" background
          :page-sizes="[100, 200, 300, 400]" :page-size="100" layout="total, prev, pager, next, jumper" :total="400">
        </el-pagination>
      </div>
    </div>
    <!-- 新增 -->
    <Add v-if="showAdd" @close="closeAdd"></Add>
    <!-- 编辑 -->
    <Update v-if="showUpdate" @close="closeUpdate"></Update>
    <!-- 绑定 -->
    <Bind v-if="showBind" @close="closeBind"></Bind>
  </div>
</template>

<script>
import Add from './add.vue'
import Update from './update.vue'
import Bind from './bindPopup.vue'
export default {
  components: {
    Add,
    Update,
    Bind
  },
  data () {
    return {
      tableData: [],
      tabIndex: 0,
      tabIndex1: 0,
      tabs: Object.freeze(['服务记录', '随访预警', '血压预警', '血糖预警', '7天提醒', '14天提醒', '本季度待随访', '应防未访']),
      tabs1: Object.freeze(['高血压', '糖尿病', '高血脂', '精神病', '肺结核']),
      options: [],
      date: '',
      value: '',
      showAdd: false,
      showUpdate: false,
      showBind: false
    }
  },
  created () {
    setTimeout(() => {
      // debugger
    }, 3000)
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    },
    tabClick1 (index) {
      this.tabIndex1 = index
    },
    openAdd () {
      this.showAdd = true
    },
    closeAdd () {
      this.showAdd = false
    },
    openUpdate () {
      this.showUpdate = true
    },
    closeUpdate () {
      this.showUpdate = false
    },
    openBind () {
      this.showBind = true
    },
    closeBind () {
      this.showBind = false
    },
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`)
    },
    handleCurrentChange (val) {
      // console.log(`当前页: ${val}`)
    }
  }
}
</script>

<style lang="less" scoped>
.tab-box {
  width: 100%;
  height: 100%;

  .tab {
    display: flex;
    align-items: center;
    height: 30px;

    .item {
      height: 30px;
      line-height: 30px;
      margin-right: 30px;
      padding: 0 20px;
      background-color: rgba(24, 205, 253, .28);
      color: #fff;
      font-size: 18px;
      cursor: pointer;
    }

    .active {
      background-color: #18CDFD;
    }
  }

  .input {
    display: flex;
    align-items: center;
    height: 50px;
    box-sizing: border-box;

    .tab {
      .item:first-child {
        margin-left: 0;
      }
    }

    .el-button {
      text-align: center;
      border-radius: 0;
      color: #fff;
      border: 0;
    }

    .text {
      margin-right: 10px;
      color: #01EEFD;
      font-size: 18px;
    }

    .el-select {
      width: 120px;
      margin-right: 20px;
    }

    .el-date-editor {
      margin-right: 20px;
    }

    .select {
      width: 206px;
    }

    .blue {
      margin-right: 20px;
      background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
    }

    .search {
      width: 300px;
    }
  }

  .input1 {
    padding-left: 29px;
  }

  .el-table {
    margin-top: 20px;
    background-color: transparent;
    outline: 4px #073F73 solid;
    color: #01EEFD;
  }

  .el-table::before {
    height: 0;
  }

  /deep/.el-checkbox__inner {
    background-color: transparent;
  }

  .operate {
    display: flex;
    justify-content: space-between;

    span {
      cursor: pointer;
    }
  }

  /deep/.el-table th.el-table__cell {
    background-color: #073F73;
  }

  /deep/.el-table thead {
    color: #01EEFD;
  }

  /deep/.el-table tr {
    background-color: #081C38;
  }

  /deep/.el-table th.el-table__cell.is-leaf {
    border: none;
  }

  /deep/.el-table td.el-table__cell {
    border-color: #01EEFD;
  }

  /deep/.el-table__cell {
    text-align: center;
  }

  /deep/.el-table__body-wrapper {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
      background-color: transparent;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, .4);
      border-radius: 10px;
    }

    &::-webkit-scrollbar-corner {
      background-color: transparent;
    }
  }

  /deep/.el-table__body {
    width: 100% !important;
  }

  /deep/.el-table--enable-row-hover .el-table__body tr:hover>td {
    background-color: transparent;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/ .el-table th.gutter {
    display: none;
    width: 0;
  }

  /deep/.el-table__fixed-right-patch {
    background-color: #073F73;
    border: none;
  }

  /deep/.el-table__fixed-right::before,
  /deep/.el-table__fixed::before {
    background-color: transparent;
  }

  /deep/.el-table__body tr.hover-row>td.el-table__cell {
    background-color: transparent;
  }

  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;

    ::v-deep.el-pagination__total,
    ::v-deep.el-pagination__jump {
      color: #01EEFD;

      .el-input__inner {
        color: #fff;
        background-color: transparent;
      }
    }

    ::v-deep.el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #01EEFD;
    }
  }
}
</style>
