<template>
  <div class="info">
    <div class="info-left">
      <div class="info-head">
        <img class="user" src="../../img/emr/user.png" alt="头像">
        <span class="span">李明明</span>
        <span class="span">男</span>
        <span class="span">44岁</span>
        <el-button size="small" class="btn2">居民医保</el-button>
        <!-- <el-button size="small" class="btn2 btn3">诊断:慢性鼻炎/慢性咽炎</el-button> -->
        <el-button size="small" class="btn2 btn7">预约</el-button>
        <el-button size="small" class="btn2 btn7">就诊记录（1）</el-button>
        <el-button size="small" class="btn2 btn4">慢:高血压/糖尿病</el-button>
        <el-button size="small" class="btn2 btn5">敏</el-button>
        <el-button size="small" class="btn2 btn6">术后</el-button>
        <div class="news">
          <div class="news-item">
            <img class="news-img news-img1" src="../../../../assets/img/idcard.png">
            <span>身份证：202111060232</span>
          </div>
          <div class="news-item">
            <img class="news-img" src="../../../../assets/img/phone.png">
            <span>电话：159****2236</span>
          </div>
          <div class="news-item">
            <span>代缴费：<span class="money">652.23</span></span>
          </div>
          <div class="news-item">
            <span class="blue">查看明细</span>
          </div>
        </div>
        <div class="position">
          <el-button size="small" class="green">完成诊疗</el-button>
          <!-- <el-button size="small" class="yellow">出院</el-button> -->
        </div>
      </div>
      <div class="info-scroll">
        <div class="info-box">
          <div class="info-list">
            <div class="list-left">
              <div class="list-img"></div>
            </div>
            <div class="list-right">
              <div class="list-news">
                <span>李艳艳</span>
                <span>女</span>
                <span>44岁 @智能导医</span>
                <span class="date">2021-11-16 21:22</span>
              </div>
              <el-card>
                <div class="card-line card-line1">
                  <span>患者入院情况介绍：</span>
                </div>
                <div class="card-line">
                  <span>李艳艳、女、44岁3月；</span>
                </div>
                <div class="card-line card-line1">
                  <span>主诉：</span>
                </div>
                <div class="card-line">
                  <span>嘴唇干裂，口腔溃疡3个月</span>
                </div>
                <div class="card-line card-line1">
                  <span>过敏史：</span>
                </div>
                <div class="card-line">
                  <span>无药物及食物过敏史；</span>
                </div>
              </el-card>
            </div>
          </div>
          <div class="info-list">
            <div class="list-left">
              <div class="list-img"></div>
            </div>
            <div class="list-right">
              <div class="list-news">
                <span>叶谓之 [五官科主任医师] @智能导医</span>
                <span>08:22</span>
              </div>
              <div class="table">
                <div class="thead">
                  <span>住院医嘱清单</span>
                  <div class="btns">
                    <el-button class="blue">已签名</el-button>
                    <el-button class="red">已复核</el-button>
                    <el-button class="green">已发送</el-button>
                    <el-button class="red">已停止</el-button>
                  </div>
                </div>
                <el-row :gutter="2">
                  <el-col :span="1">
                    <div class="grid-content">
                      <span>类型</span>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content">
                      <span>医嘱名称</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content">
                      <span>规格</span>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="grid-content">
                      <span>剂量</span>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="grid-content">
                      <span>频率</span>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="grid-content">
                      <span>用法</span>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="grid-content">
                      <span>数量</span>
                    </div>
                  </el-col>
                  <el-col :span="2">
                    <div class="grid-content">
                      <span>备注</span>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content">
                      <span>开/停嘱医生</span>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content">
                      <span>执行/停嘱护士</span>
                    </div>
                  </el-col>
                </el-row>
                <div class="row-scroll">
                  <div class="row-time">
                    <span>2021年5月12日</span>
                  </div>
                  <el-row :gutter="2">
                    <el-col :span="1">
                      <div class="grid-content grid-content4">
                        <span class="blue">长期</span>
                        <span class="blue">嘱托</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content4">
                        <span class="blue">多维元素一粒</span>
                        <span class="blue">无 5-22 08:22:33</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <span class="blue">-</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="blue">-</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="blue">qd</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="blue">-</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="blue">-</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="blue">-</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content4">
                        <span class="blue">开嘱 叶谓之</span>
                        <span class="blue">停嘱 -</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content4">
                        <span class="blue">开嘱 叶谓之</span>
                        <span class="blue">停嘱 -</span>
                      </div>
                    </el-col>
                  </el-row>
                  <el-row :gutter="2">
                    <el-col :span="1">
                      <div class="grid-content grid-content1">
                        <span class="red">长期</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item border-bottom">
                          <span>*硫酸镁注射液</span>
                          <span>【00000000】05-22 08:22:22</span>
                        </div>
                        <div class="col-item">
                          <span>*硫酸镁注射液</span>
                          <span>【00000000】05-22 08:22:22</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item border-bottom"></div>
                        <div class="col-item">
                          <span>5*10ml/克</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item border-bottom">
                          <span>40ml</span>
                        </div>
                        <div class="col-item">
                          <span>40ml</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item">
                          <span>qd</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item">
                          <span>静脉滴注40滴/分</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item border-bottom">
                          <span>4支</span>
                        </div>
                        <div class="col-item">
                          <span>4支</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item">
                          <span>-</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item">
                          <span>开嘱 叶谓之</span>
                        </div>
                        <div class="col-item">
                          <span>停嘱 -</span>
                        </div>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content1 flex">
                        <div class="col-item">
                          <span>执行 叶谓之</span>
                        </div>
                        <div class="col-item">
                          <span>停嘱 -</span>
                        </div>
                      </div>
                    </el-col>
                  </el-row>
                  <div class="row-time">
                    <span>2021年5月12日</span>
                  </div>
                  <el-row :gutter="2">
                    <el-col :span="1">
                      <div class="grid-content grid-content4">
                        <span class="green">临时</span>
                        <span class="red">检验</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content4">
                        <span class="green">多维元素一粒</span>
                        <span class="green">5-22 08:22:33</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content">
                        <span class="green">-</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="green">-</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="green">qb</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="green">-</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="green">-</span>
                      </div>
                    </el-col>
                    <el-col :span="2">
                      <div class="grid-content">
                        <span class="green">-</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content4">
                        <span class="green">开嘱 叶谓之</span>
                        <span class="green">停嘱 -</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content4">
                        <span class="green">开嘱 叶谓之</span>
                        <span class="green">停嘱 -</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="list-card">
                <div class="list-card-title">
                  <span>患者情况介绍</span>
                </div>
                <div class="list-card-text">
                  <span>李艳艳、女、44岁3月、居民医保、首次就诊；</span>
                </div>
                <div class="list-card-title">
                  <span>主诉：</span>
                </div>
                <div class="list-card-text">
                  <span>【嘴唇干裂】，【口腔溃疡】3个月</span>
                </div>
                <div class="list-card-title">
                  <span>现病史：</span>
                </div>
                <div class="list-card-text">
                  <span>患者因【嘴唇干裂】、【口腔溃疡】3个月，伴有【鼻塞】、【鼻炎】、【打喷嚏】、【疲劳】</span>
                </div>
                <div class="list-card-title">
                  <span>既往史：</span>
                </div>
                <div class="list-card-text">
                  <span>【无】</span>
                </div>
                <div class="list-card-title">
                  <span>过敏史：</span>
                </div>
                <div class="list-card-text">
                  <span>【无】</span>
                </div>
              </div>
              <div class="list-card">
                <div class="list-card-title">
                  <span>体征检查：</span>
                </div>
                <div class="list-card-text list-card-text1">
                  <div class="list-card-item">
                    <span>T:[</span>
                    <el-input placeholder="请输入"></el-input>
                    <span>]℃</span>
                  </div>
                  <div class="list-card-item">
                    <span>P:[</span>
                    <el-input placeholder="请输入"></el-input>
                    <span>]次/分</span>
                  </div>
                  <div class="list-card-item">
                    <span>R:[</span>
                    <el-input placeholder="请输入"></el-input>
                    <span>]次/分</span>
                  </div>
                  <div class="list-card-item">
                    <span>BP:[</span>
                    <el-input placeholder="请输入"></el-input>
                    <span>]mmHg</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="info-list">
            <div class="list-left">
              <div class="list-img"></div>
            </div>
            <div class="list-right">
              <div class="list-news">
                <span>医技检查报告推送</span>
              </div>
              <div class="tab">
                <el-button class="blue select">检验项目报告（2）</el-button>
                <el-button class="blue">检验检查报告（2）</el-button>
                <el-button class="blue">心电项目报告（2）</el-button>
                <el-button class="blue">影像项目报告（2）</el-button>
              </div>
              <div class="table">
                <div class="thead">
                  <div class="btns">
                    <el-button class="blue select">新型冠状病毒核酸检</el-button>
                    <el-button class="blue">血常规（五级分类）</el-button>
                  </div>
                </div>
                <div class="tbody">
                  <div class="tbody-line">
                    <span>检验项目：新型冠状病毒核酸检测</span>
                    <span>申请科室：五官科门诊</span>
                    <span>医生：叶谓之</span>
                    <span>送检时间：2021-05-22 08:55</span>
                  </div>
                  <div class="tbody-line">
                    <span>样本号：15</span>
                    <span>样本类型：鼻咽拭子</span>
                    <span>检验：宋叶月</span>
                    <span>审核：王爱护</span>
                    <span>报告时间：2021-05-22 08:55</span>
                  </div>
                </div>
                <el-row :gutter="0">
                  <el-col :span="5">
                    <div class="grid-content grid-content2">
                      <span>项目名称</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content grid-content2">
                      <span>英文标识</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content grid-content2">
                      <span>结果</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content grid-content2">
                      <span>报警</span>
                    </div>
                  </el-col>
                  <el-col :span="4">
                    <div class="grid-content grid-content2">
                      <span>参考范围</span>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content grid-content2 border1">
                      <span>单位</span>
                    </div>
                  </el-col>
                </el-row>
                <div class="row-scroll1">
                  <el-row :gutter="0">
                    <el-col :span="5">
                      <div class="grid-content grid-content2 grid-content3">
                        <span>新型冠状病毒2019-nCoV核算检测</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content grid-content2 grid-content3">
                        <span>2019-nCo</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content grid-content2 grid-content3">
                        <span>阴性(-)</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content grid-content2 grid-content3">
                        <span>报警</span>
                      </div>
                    </el-col>
                    <el-col :span="4">
                      <div class="grid-content grid-content2 grid-content3">
                        <span>阴性(-)</span>
                      </div>
                    </el-col>
                    <el-col :span="3">
                      <div class="grid-content grid-content2 border1 grid-content3">
                        <span>单位</span>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="news-position">
        <div class="news-position-left">
          <img class="image">
          <span>诊断</span>
        </div>
        <div class="news-position-center">
          <el-input placeholder="请输入"></el-input>
          <div class="news-position-box">
            <div class="news-position-list">
              <span>常用诊断：</span>
              <el-button size="mini">慢性咽炎</el-button>
              <el-button size="mini">鼻炎</el-button>
              <el-button size="mini">流行性感冒</el-button>
            </div>
            <div class="news-position-btns">
              <div class="button" @click="openPopup">
                <div class="img"></div>
                <span>开/医技检查</span>
              </div>
              <div class="button" @click="openPopup">
                <div class="img"></div>
                <span>开/处方</span>
              </div>
            </div>
          </div>
        </div>
        <div class="news-position-right">
          <span>智能诊断（6）</span>
        </div>
      </div>
      <Popup v-if="showPopup" @close="closePopup"></Popup>
    </div>
    <!-- 录音中 -->
    <SoundRecording v-if="selectIndex === 0" @close="closeSelect"></SoundRecording>
    <!-- 收住院 -->
    <Hospitalh v-if="selectIndex === 1" @close="closeSelect"></Hospitalh>
    <!-- 报告单 -->
    <Report v-if="selectIndex === 2" @close="closeSelect"></Report>
    <!-- 会诊 -->
    <Consultation v-if="selectIndex === 3" @close="closeSelect"></Consultation>
    <!-- 退费 -->
    <Refund v-if="selectIndex === 4" @close="closeSelect"></Refund>
    <!-- 手术预约 -->
    <Reservation v-if="selectIndex === 5" @close="closeSelect"></Reservation>
    <!-- 疾病上报 -->
    <Escalation v-if="selectIndex === 7" @close="closeSelect"></Escalation>
    <div class="info-right">
      <div class="item" v-for="(item, index) in lists" :key="index" @click="select(index)">
        <img :src="selectIndex !== index ? item.url : item.active" class="img">
        <span :class="{ 'color-blue': selectIndex === index }">{{ item.text }}</span>
      </div>
    </div>

    <!-- <div class="info-position" @click="openPopup">
      <el-input placeholder="开医嘱/检验/检查申请单" disabled></el-input>
      <img src="../../img/doctorAdvice/max.png" alt="">
    </div> -->
  </div>
</template>

<script>
import SoundRecording from './soundRecording.vue'
import Hospitalh from './hospital.vue'
import Report from './report.vue'
import Consultation from './consultation.vue'
import Refund from './refund.vue'
import Reservation from './reservation.vue'
import Escalation from './escalation.vue'
import Popup from './popup.vue'
export default {
  components: {
    SoundRecording,
    Hospitalh,
    Report,
    Consultation,
    Refund,
    Reservation,
    Escalation,
    Popup
  },
  data () {
    return {
      lists: Object.freeze([
        { text: '录音中', url: require('../../img/doctorAdvice/icon0.png'), active: require('../../img/doctorAdvice/icon0-active.png') },
        { text: '收住院', url: require('../../img/doctorAdvice/icon1.png'), active: require('../../img/doctorAdvice/icon1-active.png') },
        { text: '报告单', url: require('../../img/doctorAdvice/icon2.png'), active: require('../../img/doctorAdvice/icon2-active.png') },
        { text: '会诊', url: require('../../img/doctorAdvice/icon3.png'), active: require('../../img/doctorAdvice/icon3-active.png') },
        { text: '退费', url: require('../../img/doctorAdvice/icon4.png'), active: require('../../img/doctorAdvice/icon4-active.png') },
        { text: '手术预约', url: require('../../img/doctorAdvice/icon5.png'), active: require('../../img/doctorAdvice/icon5-active.png') },
        { text: '院内转诊', url: require('../../img/doctorAdvice/icon6.png'), active: require('../../img/doctorAdvice/icon0-active.png') },
        { text: '疾病上报', url: require('../../img/doctorAdvice/icon7.png'), active: require('../../img/doctorAdvice/icon7-active.png') }
      ]),
      showPopup: false,
      selectIndex: -1,
      showSoundRecording: false
    }
  },
  methods: {
    select (index) {
      if (index === 6) return this.$message('未开放')
      if (index === this.selectIndex) {
        this.selectIndex = -1
      } else {
        this.selectIndex = index
      }
    },
    openPopup () {
      this.showPopup = true
    },
    closePopup () {
      this.showPopup = false
    },
    openSoundRecording () {
      this.showSoundRecording = true
    },
    closeSelect () {
      this.selectIndex = -1
    }
  }
}
</script>

<style lang="less" scoped>
.info {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;

  .info-position {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #F7F7F7;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.1608);

    .el-input {
      width: calc(100% - 40px);
      cursor: pointer;
    }

    img {
      width: 30px;
      height: 30px;
    }
  }

  .news-position {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 72px;
    bottom: 0;
    width: calc(100% - 72px);
    height: 140px;
    background: #FFF2DF;
    box-shadow: 0px -3px 6px 1px rgba(0, 0, 0, 0.1608);

    .news-position-left {
      display: flex;
      flex-direction: column;
      width: 66px;
      height: 100%;
      padding: 13px;
      color: #F39509;
      font-size: 14px;
      box-sizing: border-box;

      .image {
        width: 25px;
        height: 19px;
        margin-bottom: 10px;
        background-color: #F39509;
      }
    }

    .news-position-center {
      width: calc(100% - 186px);
      height: 100%;

      /deep/.el-input__inner {
        border: none;
        background-color: transparent;
        border-radius: 0;
      }

      .news-position-box {
        width: 100%;
        height: calc(100% - 50px);
        border-top: 1px solid #DADADA;
        box-sizing: border-box;

        .news-position-list {
          display: flex;
          align-items: center;
          margin-top: 4px;
          color: #999999;

          .el-button {
            background-color: #E9EBEE;
            border: 1px solid #BEB7AB;
            color: #999999;

          }
        }

        .news-position-btns {
          display: flex;
          align-items: center;
          margin-top: 4px;

          .button {
            display: flex;
            align-items: center;
            height: 38px;
            margin-right: 15px;
            padding: 0 23px;
            background-color: #C4DFFF;
            border: 1px solid #4299FF;
            border-radius: 8px;
            color: #0075FF;
            cursor: pointer;

            .img {
              width: 25px;
              height: 25px;
              margin-right: 15px;
              background-color: #0075FF;
            }
          }
        }
      }
    }

    .news-position-right {
      display: flex;
      flex-direction: column;
      width: 120px;
      height: 100%;
      padding: 13px;
      color: #1171FF;
      font-size: 12px;
      box-sizing: border-box;
    }
  }

  .info-left {
    position: relative;
    width: calc(100% - 109px);
    height: 100%;

    .info-head {
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      height: 45px;
      padding: 0 7px;
      border-bottom: 1px solid #A1A6BB;
      box-sizing: border-box;

      .user {
        width: 35px;
        height: 35px;
      }

      .span {
        margin-left: 15px;
        color: #333333;
        font-size: 12px;
      }

      .el-button {
        box-sizing: border-box;
      }

      .btn2 {
        margin-left: 10px;
        background-color: #fff;
        border: 1px solid #1269B7;
        border-radius: 0;
        color: #1269B7;
        font-size: 12px;
      }

      .btn3 {
        background-color: #1269B7;
        color: #fff;
      }

      .btn4 {
        background-color: #B74412;
        border: 1px solid #B74412;
        color: #fff;
      }

      .btn5 {
        background-color: #333333;
        border: 1px solid #333333;
        color: #fff;
      }

      .btn6 {
        background-color: #C26B09;
        border: 1px solid #C26B09;
        color: #fff;
      }

      .btn7 {
        background-color: #fff;
        border: 1px solid #10BE61;
        color: #10BE61;
      }

      .news {
        overflow: hidden;
        display: flex;
        flex-wrap: wrap;
        width: 400px;
        height: 100%;
        margin-left: 10px;

        .news-item {
          display: flex;
          align-items: center;
          margin-right: 20px;
          color: #333333;
          font-size: 12px;

          .news-img {
            width: 20px;
            height: 20px;
            margin-right: 4px;
          }

          .news-img1 {
            width: 26px;
          }

          .money {
            color: #FF9200;
          }

          .blue {
            color: #1269B7;
          }
        }
      }

      .position {
        position: absolute;
        display: flex;
        align-items: center;
        right: 0;
        height: 100%;
        padding-left: 100px;
        padding-right: 10px;
        background-color: #fff;

        .el-button {
          margin: 0;
          border: none;
          border-radius: 0;
          color: #fff;
          font-size: 16px;
        }

        .green {
          background-color: #10BE61;
        }

        .yellow {
          background-color: #FF9200;
        }
      }
    }

    .info-scroll {
      overflow-y: scroll;
      width: 100%;
      margin-top: 10px;
      height: calc(100% - 180px);

      &::-webkit-scrollbar {
        width: 0;
      }

      .info-box {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding-left: 18px;
        box-sizing: border-box;

        .info-list {
          display: flex;
          padding: 20px 0;
          border-bottom: 1px solid #A1A6BB;

          .list-left {
            width: 60px;

            .list-img {
              width: 50px;
              height: 50px;
              border-radius: 50%;
              background-color: #1269B7;
            }
          }

          .list-right {
            width: calc(100% - 60px);

            .list-news {
              color: #333333;
              font-size: 16px;

              span {
                margin-right: 20px;
              }

              .date {
                color: #A1A6BB;
              }
            }

            .el-card {
              width: 90%;
              margin-top: 20px;
              background-color: #E3E3E3;
              box-shadow: none;

              .card-line {
                margin-bottom: 10px;
                color: #333333;
                font-size: 12px;
              }

              .card-line1 {
                color: #1269B7;
              }
            }

            .table {
              display: flex;
              flex-direction: column;
              width: calc(100% - 30px);
              margin-top: 20px;
              padding: 18px;
              background-color: #CFE2FF;
              border: 1px solid #1171FF;
              box-sizing: border-box;

              .thead {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 8px;
                color: #333333;
                font-size: 16px;

                .btns {
                  display: flex;
                  align-items: center;

                  .el-button {
                    border-radius: 0;
                  }

                  .blue {
                    background-color: transparent;
                    border: 1px solid #1269B7;
                    color: #1269B7;
                  }

                  .red {
                    background-color: #FFAE91;
                    border: 1px solid #FF4400;
                    color: #FF4400;
                  }

                  .green {
                    background-color: #6EFFB2;
                    border: 1px solid #10BE61;
                    color: #10BE61;
                  }

                  .select {
                    background-color: #1269B7;
                    color: #fff;
                  }
                }
              }

              .el-row {
                margin-bottom: 4px;

                &:last-child {
                  margin-bottom: 0;
                }
              }

              .el-col {
                border-radius: 4px;
              }

              .bg-purple-dark {
                background: #99a9bf;
              }

              .grid-content {
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 60px;
                border-radius: 4px;
                background-color: #fff;

                .col-item {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  width: 100%;
                  height: 50%;
                  color: #FF0000;
                  font-size: 12px;

                  span {
                    width: 100%;
                    overflow: hidden;
                    -webkit-line-clamp: 2;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                  }
                }

                .border-bottom {
                  border-bottom: 1px solid #A1A6BB;
                  box-sizing: border-box;
                }
              }

              .flex {
                display: flex;
                flex-direction: column;
                align-items: center;
              }

              .grid-content1 {
                align-items: flex-start;
                height: 140px;
              }

              .grid-content2 {
                background-color: #D4D4D4;
                border: 1px solid #A1A6BB;
                border-right: none;
                border-bottom: none;
                border-radius: 0;
              }

              .grid-content3 {
                background-color: #fff;
              }

              .grid-content4 {
                flex-direction: column;

                .red {
                  color: #FF0000;
                }
              }

              .border1 {
                border-right: 1px solid #A1A6BB;
              }

              .row-scroll {
                // overflow-y: scroll;
                width: 100%;
                // height: 260px;

                &::-webkit-scrollbar {
                  width: 0;
                }

                .row-time {
                  padding: 5px 0;
                  color: #333333;
                  font-size: 14px;
                }

                .blue {
                  color: #1269B7;
                  font-size: 14px;
                }

                .red {
                  color: #FF0000;
                  font-size: 14px;
                }

                .green {
                  color: #10BE61;
                  font-size: 14px;
                }
              }

              .row-scroll1 {
                overflow-y: scroll;
                width: 100%;
                height: 260px;

                &::-webkit-scrollbar {
                  width: 0;
                }
              }

              .tbody {
                width: 100%;
                padding: 10px;
                background-color: #fff;
                box-sizing: border-box;

                .tbody-line {
                  display: flex;
                  align-items: center;
                  justify-content: space-between;
                  color: #333333;
                  font-size: 12px;
                }
              }
            }

            .list-card {
              display: flex;
              flex-direction: column;
              width: calc(100% - 30px);
              margin-top: 20px;
              padding: 18px;
              background-color: #CFE2FF;
              border: 1px solid #1171FF;
              box-sizing: border-box;

              .list-card-title {
                margin-bottom: 10px;
                color: #1269B7;
                font-size: 12px;
              }

              .list-card-text {
                margin-bottom: 10px;
                color: #333333;
                font-size: 12px;
              }

              .list-card-text1 {
                display: flex;
                align-items: center;
              }

              .list-card-text:last-child {
                margin-bottom: 0;
              }

              .list-card-item {
                display: flex;
                align-items: center;
                margin-right: 28px;
                color: #333333;
                font-size: 12px;

                .el-input {
                  width:80px;
                }

                /deep/.el-input__inner {
                  background-color: transparent;
                  border: none;
                  font-size: 12px;
                }
              }

              .list-card-item:last-child {
                margin-right: 0;
              }
            }

            .tab {
              display: flex;
              align-items: center;
              margin-top: 10px;

              .el-button {
                border-radius: 0;
              }

              .blue {
                background-color: transparent;
                border: 1px solid #1269B7;
                color: #1269B7;
              }

              .select {
                background-color: #1269B7;
                color: #fff;
              }
            }
          }
        }
      }
    }
  }

  .info-right {
    width: 109px;
    height: 100%;
    border-left: 1px solid #A1A6BB;
    box-sizing: border-box;

    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;
      color: #333333;
      font-size: 14px;
      cursor: pointer;

      .img {
        width: 26px;
        height: 30px;
      }

      .color-blue {
        color: #1269B7;
      }
    }
  }
}
</style>
